@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './_animation.scss';

.SimpleCardsLink {
  @apply w-full h-[440px] mx-auto;

  position: relative;
  box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);

  .tabbed-carousel-item-image {
    @apply absolute inset-0 w-full h-full overflow-hidden;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      background: theme('colors.darkblue');
    }

    .img {
      @apply w-full h-full object-cover;

      position: absolute;
      top: 0;
      background: theme('colors.darkblue') linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.69));
    }
  }

  .tabbed-carousel-item-row {
    @apply text-defaultsolidwhite w-full;

    z-index: 2;
    display: inline-flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 24px 24px 24px;
    text-decoration: inherit;

    .tabbed-carousel-item-content-col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start !important;

      & > h4 {
        font-weight: 400;
        font-size: 2rem;
        letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
        line-height: 40px;

        @screen md {
          @apply text-32;

          line-height: 40px;
          margin-block-end: 12px;
        }
      }

      & > p {
        font-size: 14px;
      }

      .tabbed-carousel-item-content-arrow {
        position: relative;
        left: 0;
        margin-left: 20px;
      }
    }
  }

  .decor {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.69));
    }
  }

  &:has(div[class*='VideoPlayer']) {
    cursor: pointer;
  }
}

[dir='rtl'] {
  .tabbed-carousel-item-row {
    direction: rtl;

    .tabbed-carousel-item-content-arrow {
      transform: rotate(180deg);
      margin-left: 0 !important;
      right: 0;
    }
  }
}

:global(.retailersPage) .SimpleCardsLink {
  .decor {
    &:after {
      background: none;
    }
  }
}
