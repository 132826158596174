[data-animation='on'] {
  [data-anim='on'] {
    .block-reveal-animation {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: -1%;
        right: -1;
        width: 101%;
        height: 101%;
        background-color: #fff;
        animation: 1.5s reveal-banner-inview ease-in-out both;
        border: none;
        z-index: 10;
      }
    }
  }
}

[data-animation='on'][dir='rtl'] {
  [data-anim='on'] {
    .block-reveal-animation::after {
      left: unset;
      right: 101%;
      animation: 1.5s reveal-banner-inview ease-in-out reverse both;
    }
  }
}
