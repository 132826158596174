[data-animation='on'] {
  @media (hover: hover) and (pointer: fine) {
    .btn__primary-1:focus-visible span.text,
    .btn__primary-1:focus-visible span.text span,
    .btn__primary-1:hover span.text,
    .btn__primary-1:hover span.text span {
      letter-spacing: 3px !important;
    }
  }

  .btn__primary-1 span.text,
  .btn__primary-1 span.text span {
    transition: letter-spacing 0.75s cubic-bezier(0.16, 1, 0.3, 1);
  }
}
