@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.imageCard {
  display: inline-block;
  margin: 0;
  position: relative;
  max-width: 825px;
  width: 100%;
}

.decor:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4));
}

.imageInnerBlock {
  position: relative;
  display: block;
  width: 100%;
  max-width: 827px;
  height: 440px;
  box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content {
  height: 60px;
  min-width: 84.3%;
  margin-block: 40px 0;
  margin-inline: 105px 0;
  padding-inline-end: 50px;
  position: relative;

  @media (min-width: 768px) {
    margin: 0;
    padding-inline-end: 0;
  }
}

.contentTitle {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 15px;
  word-break: break-word;
  line-height: 28px;
  letter-spacing: 1px;

  @media (min-width: 768px) {
    position: absolute;
    font-size: 2rem;
    text-align: right;
    margin-bottom: 0;
    line-height: 40px;
    max-width: 450px;
    bottom: 100px;
    right: 40px;
    color: #fff;
  }
}

.contentDesc {
  width: 100%;
  font-size: 0.875rem;
  line-height: 20px;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    position: absolute;
    left: calc(100% + 30px);
    max-width: 30%;
    margin-bottom: 0;
    top: -220px;
  }
  @media (min-width: 1025px) {
    top: -140px;
  }
}

.contentLink {
  width: 100%;
  max-width: 215px;

  @media (min-width: 768px) {
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
  }

  a {
    position: relative;
    display: block;
    border-bottom: 2px solid #28334a;
    overflow: visible;

    span {
      display: block;
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.215;
      padding-block: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      opacity: 0;
      height: 2px;
      background: #28334a;
      transition:
        top 0.3s,
        opacity 0.3s,
        transform 0.3s;
    }

    &:hover:after {
      opacity: 1;
      transform: scale(1);
      top: -3px;
    }
  }
}

[dir='rtl'] {
  .imageCard {
    div {
      direction: rtl;
    }

    img,
    p,
    h4,
    span {
      transform: rotateY(180deg);
    }
  }

  .content {
    margin-inline: 0 105px;
    padding-inline-start: 50px;
    padding-inline-end: 0;

    @media (min-width: 768px) {
      padding-inline-start: 0;
    }
  }

  .contentLink {
    margin-right: auto;

    @media (min-width: 1024px) {
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: 23px;
    }

    @media (min-width: 1280px) {
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: 0;
    }
  }
}
