@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animation';

.LazyGallery {
  .kafd-image-gallery {
    @apply pt-14;

    .kafd-gallery-title {
      @apply text-30 font-light;

      line-height: 36px;
      margin-bottom: 15px;
      letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};

      @screen lg {
        font-size: 42px;
        line-height: 50px;
        letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
        margin-bottom: 20px;
      }
    }

    &.image-gallery-events {
      @apply mt-0 mb-0;

      .kafd-gallery-title {
        @apply uppercase;

        @screen lg {
          @apply mb-0;
        }
      }
    }

    .kafd-title-group {
      max-width: 760px;
      width: 100%;
      margin-bottom: 30px;

      @screen md {
        margin-bottom: 25px;
        width: 83.333333%;
      }

      @screen xl {
        max-width: 828px;
        width: 100%;
      }
    }

    .btn-lm-ig {
      @apply mx-auto block uppercase;
    }

    .lazyGalleryLoadMoreButton {
      padding: 11px 80px;
      text-transform: uppercase;

      @media (max-width: 768px) {
        padding: 11px 50px;
      }
    }
  }

  .block-reveal-animation {
    @apply relative overflow-hidden;

    &:after {
      @apply absolute inset-0 w-full h-full bg-defaultsolidwhite;
    }
  }

  .kafd-gallery-wrapper {
    list-style: none;

    .kafd-gallery__item {
      @apply relative w-full px-4 mb-8;

      @screen sm {
        @apply w-1/2;
      }

      @media screen and (min-width: 940px) {
        @apply w-1/3;
      }

      @screen lg {
        @apply w-1/2;
      }

      @media screen and (min-width: 1200px) {
        @apply w-1/3;
      }
    }

    .kafd-gallery__img {
      @apply relative;

      height: 100%;
      min-height: 342px;
      min-width: 342px;
      cursor: pointer;

      img {
        object-fit: cover;
        object-position: center top;
      }
    }
  }

  .gallery__overlay {
    @apply w-full h-full absolute left-0 top-0 text-defaultsolidwhite font-kafd p-6 flex flex-col justify-end;

    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.69) 100%);
    box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);
    padding: 0;

    .gallery__item-title {
      @apply text-24 leading-7 mb-4 font-light;

      letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
      @screen sm {
        @apply text-32;

        line-height: 40px;
        margin-bottom: 10px;
      }

      padding: 0 1.5rem;
    }
  }
}
