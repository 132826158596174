@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate';

.container {
  opacity: 0;

  .hori-img-comp {
    margin-bottom: 3.75rem;
    @screen md {
      margin-bottom: 6.25rem;
    }

    .hori-img-comp__list {
      .hori-img-comp__item {
        display: flex;
        flex-direction: column;
        @screen md {
          flex-direction: row;
        }

        //&:nth-child(odd)
        &.img-right {
          .hori-img-comp__col {
            // description
            &.hori-img-comp__col_main {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-shrink: 0;
              flex-basis: 46%;
            }
          }
        }
        //&:nth-child(even),
        &.img-left {
          @screen md {
            flex-direction: row-reverse;
          }

          .hori-img-comp__col {
            // description
            &.hori-img-comp__col_main {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-shrink: 0;
              flex-basis: 46%;
            }
          }
        }
      }

      .hori-img-comp__col {
        // media
        &.hori-img-comp__col_media {
          box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);
          z-index: 2;
          min-height: 21.56rem;
          height: 21.56rem;
          position: relative;
          order: -1;
          margin-bottom: 1.875rem;
          overflow: hidden;
          width: 100%;
          flex-grow: 0;

          @screen md {
            min-height: 27.5rem;
            height: 27.5rem;
            min-width: 50%;
            order: unset;
            margin-bottom: 0;
          }

          .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left top;
          }
        }

        // descroption
        &.hori-img-comp__col_main {
          .hori-img-comp__content {
            font-size: 1rem;
            line-height: 1.375rem;
            width: 100%;
            letter-spacing: 1.5px#{'/*rtl:' 0 '*/'};
            @apply flex flex-col justify-center font-kafd font-bold;
            @screen lg {
              font-size: 1.125rem;
              line-height: 1.625rem;
            }

            &:not(:last-child) {
              margin-bottom: 1.5rem;
              @screen lg {
                margin-bottom: 2.5rem;
              }
            }
          }

          .hori-img-comp__title {
            font-size: 1.875rem;
            line-height: 2.25rem;
            margin-bottom: 1rem;
            letter-spacing: 1px; // #{'/*rtl:' 0 '*/'};
            text-transform: uppercase;
            @apply font-kafd font-light;
            @screen lg {
              font-size: 42px;
              line-height: 50px;
              margin-bottom: 1.25rem;
              letter-spacing: 4px; // #{'/*rtl:' 0 '*/'};
            }
          }

          .hori-img-comp__action {
            .btn {
              text-transform: uppercase;
              min-width: 12rem;
              font-size: 1rem;
              line-height: 2rem;
              padding: 0.3rem 1.25rem 0.2rem;
            }
          }
        }
      }
    }

    .hori-img-comp__footer {
      @apply flex justify-center;

      .btn {
        text-transform: uppercase;
        min-width: 12rem;
        font-size: 1rem;
        line-height: 2rem;
        padding: 0.3rem 1.25rem 0.2rem;
      }
    }
  }
}

[dir='ltr'] {
  .img-right {
    .hori-img-comp__col_main {
      @screen md {
        padding-right: 1.5rem;
      }
      @screen lg {
        padding-right: 2.5rem;
      }
      @screen xl {
        padding-right: 4rem;
      }
    }
  }
}

[dir='rtl'] {
  .img-right {
    .hori-img-comp__col_main {
      @screen md {
        padding-left: 1.5rem;
      }
      @screen lg {
        padding-left: 2.5rem;
      }
      @screen xl {
        padding-left: 4rem;
      }
    }
  }
}

[dir='ltr'] {
  .img-left {
    .hori-img-comp__col_main {
      @screen md {
        padding-left: 1.5rem;
      }
      @screen lg {
        padding-left: 2.5rem;
      }
      @screen xl {
        padding-left: 4rem;
      }
    }
  }
}

[dir='rtl'] {
  .img-left {
    .hori-img-comp__col_main {
      @screen md {
        padding-right: 1.5rem;
      }
      @screen lg {
        padding-right: 2.5rem;
      }
      @screen xl {
        padding-right: 4rem;
      }
    }
  }
}
