[data-animation='on'] .LazyGallery {
  .block-reveal-animation:after {
    content: '';
    right: -1%;
    top: -1%;
    animation: 1s reveal-block-ltr ease-in-out 0.25s both;
  }

  .kafd-gallery-wrapper .kafd-gallery__item {
    .kafd-gallery__img {
      animation: motif-box-animation 1.5s ease-in-out 0s;
    }
  }
}

[data-animation='on'][dir='rtl'] .LazyGallery .block-reveal-animation::after {
  left: unset;
  right: 101%;
  animation: 1s reveal-block-ltr ease-in-out 0.25s reverse both;
}
