@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate.scss';

.event-listing-wrap {
  margin: -15px -35px -25px;
  padding: 15px 35px 25px;

  @screen sm {
    margin-bottom: -50px;
    padding-bottom: 50px;
  }

  @screen md {
    margin-bottom: -15px;
    padding-bottom: 15px;
  }
}

:global(.mediaCenterPage) .event-listing-wrap {
  @screen md {
    margin-block-start: 8px;
  }
}

.event-listing-card {
  @apply relative flex flex-col bg-defaultsolidwhite;

  min-height: 415px;
  margin-bottom: 65px;
  padding: 25px;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.15);

  @screen sm {
    padding: 50px;
  }

  @screen md {
    @apply flex-row w-10/12;

    margin-bottom: 15px;
    min-height: 440px;
  }

  &:last-child {
    @screen md {
      margin-bottom: 35px;
    }
  }
}

[dir='ltr'] {
  .event-listing-card {
    @media (max-width: 1024px) {
      margin-left: 35px;
      margin-right: 35px;
    }
  }
}

[dir='rtl'] {
  .event-listing-card {
    margin-left: 35px;
    margin-right: 35px;
  }
}

.event-listing-date {
  @apply text-14 leading-4;

  letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};

  span {
    @apply uppercase;
  }

  @screen md {
    @apply text-16;

    line-height: 18px;
  }

  + .event-listing-title {
    @apply mt-10;
  }
}

.event-listing-title {
  @apply text-24 font-light;

  line-height: 28px;
  letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};

  @screen md {
    @apply text-32;

    line-height: 40px;
  }
}

.event-listing-description {
  p {
    margin-left: 0;
  }

  @apply text-14 font-bold;

  margin: 15px 0 20px;
  line-height: 20px;
  letter-spacing: 1px;

  @screen md {
    @apply text-18 leading-none mt-5 mb-3;

    line-height: 26px;
    letter-spacing: 1.5px;
  }

  &:global(.contenteditor) {
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    padding: 0;
  }
}

:global(.eventsPage) {
  .event-listing-description {
    padding-left: 0;
  }
}

.event-listing-picture {
  @apply w-full;

  margin-bottom: -50px;

  @screen sm {
    margin-bottom: -75px;
  }

  @screen md {
    @apply flex-1 mt-0;

    max-width: 460px;
    max-height: 340px;
  }

  picture {
    @apply w-full h-full;
  }

  img {
    @apply w-full h-full;

    object-fit: cover;
    object-position: left top;
    box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);
  }
}

[dir='ltr'] {
  .event-listing-picture {
    @media (min-width: 768px) {
      margin-right: calc(-3% - 100px);
      margin-left: 45px;
    }

    @media (min-width: 1024px) {
      margin-right: calc(-16.67% - 100px);
    }
  }
}

[dir='rtl'] {
  .event-listing-picture {
    @media (min-width: 768px) {
      margin-left: calc(-3% - 100px);
      margin-right: 45px;
    }

    @media (min-width: 1024px) {
      margin-left: calc(-16.67% - 100px);
    }
  }
}

.event-listing-footer-wrap {
  @apply w-full ml-0;

  @screen md {
    @apply relative mt-auto;

    height: 40px;
  }
}

.event-listing-footer {
  margin-bottom: 10px;
  @apply w-full flex-col;

  @screen md {
    @apply absolute top-0 left-0 flex-row;
  }
}

.event-listing-footer-col {
  @screen md {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 960px) {
    margin-right: 10px;
  }

  @media screen and (min-width: 1024px) and (max-width: 1120px) {
    margin-right: 10px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1360px) {
    margin-right: 12px;
  }
}

.event-listing-read-more {
  .btn__primary-1 {
    @apply w-full;

    margin-bottom: 15px;
    font-size: 1rem;

    span {
      width: 100%;
    }

    @screen md {
      @apply w-auto mb-0;
    }

    span.text {
      @apply w-full;

      min-height: 40px;
      background-image: none;

      @screen md {
        @apply w-auto;
      }

      @media screen and (min-width: 767px) and (max-width: 960px) {
        min-height: auto;
      }
    }

    @media screen and (min-width: 767px) and (max-width: 960px) {
      @apply text-10;
    }
  }
}

// Styles applied only on `EventCard` that is being used as article on Media Centre page
.articlePreview {
  .hideThisOnMobile {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .displayFirst {
    @media (max-width: 767px) {
      order: 1;
    }
  }

  .displaySecond {
    @media (max-width: 767px) {
      order: 2;
    }
  }

  .articlePreviewButton {
    min-width: 185px;
    width: auto;
    padding: 8px 0;
    font-size: 12px;

    @media (max-width: 767px) {
      display: inline-block;
      margin-bottom: 1rem;
    }

    @media (max-width: 640px) {
      display: inline-block;
      margin-bottom: 0rem;
    }
  }

  @media (max-width: 767px) {
    .event-listing-card {
      margin-top: 2rem;
    }
  }

  @media (max-width: 767px) {
    .event-listing-card {
      padding: 20px;
    }

    .event-listing-date {
      margin-top: 0.5rem;
      font-size: 12px;
    }

    .event-listing-title {
      font-size: 1.125rem;
      font-weight: 300;
      margin: 1.25rem 0;
      line-height: 22px;
      letter-spacing: 1px;
    }

    .event-listing-picture {
      position: relative;
      top: -55px;
    }
  }
}
