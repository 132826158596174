//
//
//

.mheader {
  @apply fixed;

  background-color: rgba(255, 255, 255, 0.7);
  border-bottom: solid 1px rgba(210, 215, 225, 0.6);
  backdrop-filter: blur(5px);
  height: 90px;
  z-index: 1002;
  width: 100%;
  top: 0;

  @screen lg {
    display: none;
    visibility: hidden;
  }

  &.header--hidden {
    transform: translateY(-100%);
    transition: all ease 0.3s;

    .d-logo-right,
    .d-logo {
      opacity: 0;
    }
  }

  &.header--showing {
    transform: translateY(0);
    transition: all ease 0.3s;

    .d-logo-right,
    .d-logo {
      opacity: 1;
    }
  }

  .m-logo {
    position: absolute;
    bottom: 20px;
    left: 34px;
    transition: all linear 0.3s;

    > .anchor {
      width: 100px;
      height: 24px;
      display: block;

      .logoicon {
        @apply text-bluewood fill-current;
      }
    }
  }

  .d-search-icon {
    position: absolute;
    bottom: 20px;
    right: 84px;
    transition: all linear 0.3s;

    .icon,
    .svgicon {
      @apply text-bluewood fill-current;

      opacity: 1;
    }
  }

  .d-lang {
    position: absolute;
    bottom: 21px;
    right: 140px;
    transition: all linear 0.3s;

    .lang-text {
      @apply bg-bluewood text-defaultsolidwhite flex justify-center py-0 px-2;

      min-width: 50px;
      min-height: 21px;
      line-height: 17px#{'/*rtl:' 23px '*/'};
      transition: 0.1s ease-in;
    }
  }

  .d-user-icon {
    right: 133px;

    &._exit {
      right: 35px;
    }
  }

  .icon-user,
  .icon-exit {
    width: 20px;
    height: 27px;
    @apply text-bluewood fill-current opacity-50;
  }

  .icon-exit {
    width: 24px;
    height: 24px;
  }
}

.burger-transition {
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.burger-menu {
  @apply cursor-pointer absolute;

  width: 24px;
  height: 18px;
  bottom: 22px;
  right: 34px;
  left: auto;

  .burger {
    transform: translate(-50%, -50%);
    @apply absolute rounded-full bg-bluewood;
    @extend .burger-transition;

    width: 100%;
    height: 2px;
    top: 50%;
    left: 50%;

    &:before,
    &:after {
      @apply absolute rounded-full bg-bluewood;

      content: '';
      width: 24px;
      height: 2px;
      @extend .burger-transition;
    }

    &:before {
      top: 8px;
    }

    &:after {
      bottom: 8px;
    }
  }

  &.menu-on {
    .burger {
      background-color: transparent;

      &:before {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        top: 0;
      }

      &:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        bottom: 0;
      }
    }
  }
} // hamburger style ends

.m-menu-wrap {
  @apply bg-desaturatedblue fixed;

  transform: translate3d(100%, 0, 0);
  height: -webkit-calc(100% - 90px);
  height: calc(100% - 90px);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  max-width: auto;
  z-index: 1001;
  top: 90px;
  right: 0px;
  @screen sm {
    max-width: 425px;
  }
  @screen lg {
    @apply hidden;
  }

  &.expanded {
    transform: translate3d(0%, 0, 0);
    box-shadow: -10px 0 44px 0 rgba(0, 0, 0, 0.5);
  }

  .lang-wrapper {
    @apply flex;

    .d-lang {
      @apply inline-flex justify-end font-kafd font-bold text-18 items-center text-defaultsolidwhite;

      margin-right: auto;
      min-height: 40px;

      .lang-text {
        line-height: 1;
        margin-bottom: 5px #{'/*rtl:-4px*/'};
        letter-spacing: $rtl-space #{'/*rtl:1.3px*/'};
      }
    }
  }

  .mwrap-for-scroll {
    height: 100%;
    overflow-y: scroll;
    padding: 34px;
    display: flex;
    flex-direction: column;
  }

  &:after {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    transition:
      opacity 0.2s ease-in-out 0.1s,
      visibility 0.2s ease 0.1s;
    will-change: opacity, visibility;
    visibility: hidden;
    opacity: 0;
    z-index: 0;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &.prevent-scroll {
    overflow: hidden;
    max-height: inherit;

    .mwrap-for-scroll {
      overflow-y: hidden;
    }

    &:after {
      visibility: visible;
      opacity: 1;
      backdrop-filter: blur(5px);
      transition:
        opacity 0.5s ease-in-out 0s,
        visibility 0.5s ease 0s;
    }
  }

  hr,
  .separator {
    border: 1px solid #a5b3ce !important;
    margin: 25px 0 35px;
  }
}

.m-primarylinks {
  @apply mx-2 mt-2;

  > .link {
    margin-bottom: 8px;

    &.active-submenu {
      .m-menu-innercontent {
        transform: translate3d(0%, 0, 0);
      }
    }

    > .macnhor {
      width: 100%;
      height: 140px;
      display: block;
      position: relative;
      overflow: hidden;

      .big-pattern {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        svg,
        img {
          transform: translate(-50%, -50%) scale(1.8);
          position: absolute;
          top: 50%;
          left: 50%;
        }
      }

      .small-pattern {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 64px;
          height: 64px;
        }
      }

      .title {
        @apply font-kafd font-bold text-27 text-defaultsolidwhite absolute;

        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    .primary-cta-wrap {
      margin: 24px 35px auto;

      .btn__primary {
        height: 40px;
        min-width: 160px;
      }
    }

    &.live .btn__pillar {
      background-image: linear-gradient(80deg, theme('colors.live') 4%, theme('colors.mango') 99%);
    }

    &.thrive .btn__pillar {
      background-image: linear-gradient(80deg, theme('colors.midnightblue') 3%, theme('colors.greenblue') 98%);
    }

    &.discover .btn__pillar {
      background-image: linear-gradient(80deg, theme('colors.grape') 3%, theme('colors.cerise') 97%);
    }
  }

  &.style--2 {
    @apply mx-0 mt-4;

    &:before {
      @apply text-16 text-defaultsolidwhite opacity-50;

      content: attr(data-links-label) '';
      letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
    }

    > .link {
      margin-bottom: 15px;
      background-image: none;

      &:first-of-type {
        margin-top: 5px;
      }

      &.active-submenu {
        .m-menu-innercontent {
          transform: translate3d(0%, 0, 0);
        }
      }

      > .macnhor {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-inline-end: 20px;
        width: 100%;
        height: auto;
        display: block;
        position: relative;
        overflow: hidden;

        .chevron-right {
          @apply absolute;

          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          width: 8px;
          height: 12px;
          transition: all ease 0.3s;

          path {
            @apply fill-current text-defaultsolidwhite;

            transition: all ease 0.3s;
          }

          [dir='rtl'] & {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .title {
          @apply font-kafd font-bold text-16 text-defaultsolidwhite static;

          transform: translate(0);
          line-height: 22px;
          letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
          transition: all ease 0.3s;
        }

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          bottom: 0;
          right: 0;
          left: 0;
          background-image: linear-gradient(
            86deg,
            theme('colors.lightgray') 4%,
            theme('colors.lightgray') 50%,
            theme('colors.defaultsolidwhite') 97%
          );
          transition: ease-in 0.2s;
        }

        &:focus,
        &:hover {
          .chevron-right {
            right: 0;
          }
        }
      }

      &.live {
        background-image: none;

        > .macnhor {
          &:after {
            background-image: linear-gradient(87deg, theme('colors.live') 4%, theme('colors.mango') 99%);
          }

          &:focus,
          &:hover {
            .title {
              @apply text-orange;
            }
          }
        }

        .m-secondarylinks-wrap {
          .sub-links {
            li {
              a {
                &:hover {
                  @apply text-orange;
                }
              }
            }
          }
        }
      }

      &.thrive {
        background-image: none;

        > .macnhor {
          &:after {
            background-image: linear-gradient(86deg, theme('colors.midnightblue') 3%, theme('colors.greenblue') 97%);
          }

          &:focus,
          &:hover {
            .title {
              @apply text-green;
            }
          }
        }

        .m-secondarylinks-wrap {
          .sub-links {
            li {
              a {
                &:hover {
                  @apply text-green;
                }
              }
            }
          }
        }
      }

      &.discover {
        background-image: none;

        > .macnhor {
          &:after {
            background-image: linear-gradient(86deg, theme('colors.grape') 4%, theme('colors.cerise') 97%);
          }

          &:focus,
          &:hover {
            .title {
              @apply text-pink;
            }
          }
        }

        .m-secondarylinks-wrap {
          .sub-links {
            li {
              a {
                &:hover {
                  @apply text-pink;
                }
              }
            }
          }
        }
      }

      &.lifestyle {
        background-image: none;

        > .macnhor {
          &:after {
            background-image: linear-gradient(
              86deg,
              theme('colors.vividviolet') 4%,
              theme('colors.red') 50%,
              theme('colors.yellow') 97%
            );
          }

          &:focus,
          &:hover {
            .title {
              @apply text-red;
            }
          }
        }

        .m-secondarylinks-wrap {
          .sub-links {
            li {
              a {
                &:hover {
                  @apply text-red;
                }
              }
            }
          }
        }
      }
    }

    + .m-secondarylinks-wrap {
      @apply mt-5;
    }
  }
}

.m-mainlinks-wrap {
  @apply mx-2;

  .sub-links {
    li {
      margin-bottom: 8px;

      a {
        @apply font-kafd font-bold text-16 text-defaultsolidwhite bg-bluewood flex items-center justify-center px-4;

        letter-spacing: 0.8px#{'/*rtl:' $rtl-space '*/'};
        line-height: normal;
        height: 60px;
      }
    }
  }
}

.m-secondarylinks-wrap {
  //@apply mt-3;
  .sub-links {
    li {
      margin-bottom: 10px;

      a {
        @apply inline-block font-kafd font-bold text-16 text-defaultsolidwhite py-2;

        letter-spacing: 0.8px#{'/*rtl:' $rtl-space '*/'};
        line-height: 22px;
        transition: all ease 0.3s;
      }
    }
  }

  ~ .m-primarylinks {
    @apply mt-5;
  }
}

.m-cta-link {
  margin-top: auto;

  > .m-anchor {
    @apply flex justify-center items-end w-full h-full leading-none p-4 bg-defaultsolidwhite text-bluewood text-14 font-bold font-kafd;

    min-height: 50px;
  }
}

.m-footerlinks-wrap {
  margin: 24px 0 31px 24px;

  .footerlinks {
    li {
      a {
        @apply text-10 font-bold;

        letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
        line-height: 3.2;
      }
    }
  }
}

.m-menu-innercontent {
  @apply bg-desaturatedblue;

  transform: translate3d(100%, 0, 0);
  transition: all 0.3s ease;
  position: absolute;
  width: 100%;
  max-width: 84vw;
  height: 100%;
  top: 0;
  left: calc(100% - 84vw);
  z-index: 1;
  padding: 0;
  @screen sm {
    max-width: 425px;
    left: calc(100% - 425px);
  }

  .description {
    @apply text-14 font-kafd font-bold px-8 text-defaultsolidwhite mt-6 mb-5;

    line-height: 20px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    transition: all ease 0.3s;

    ~ .m-secondarylinks-wrap {
      margin-top: 35px;
    }
  }

  .m-secondarylinks-wrap {
    @apply px-8 mt-6;

    .sub-links {
      li {
        margin-bottom: 10px;
      }
    }
  }

  .backbutton {
    position: relative;
    display: block;
    height: 92px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 28px;

    .big-pattern {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

      svg,
      img {
        transform: translate(-50%, -50%) scale(1.8);
        position: absolute;
        top: 50%;
        left: 50%;
        @screen sm {
          transform: translate(-50%, -50%) scale(2.5);
        }
      }
    }

    .title {
      @apply font-kafd font-bold text-18 text-defaultsolidwhite absolute;

      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      margin-top: 2px;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 32px;
      /* rtl:remove */
      transform: translateY(-50%);

      /* rtl:raw:transform: translateY(-50%) scaleX(-1); */

      svg,
      .svgicon {
        path {
          @apply fill-current text-defaultsolidwhite;
        }
      }
    }
  }
}

.m-submenumiddle-wrap {
  @apply flex flex-col justify-between;

  height: calc(100% - 68px);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  .m-submenumiddle {
    .s-lvl-menu {
      margin-bottom: 8px;

      > .heading {
        @apply bg-defaultsolidwhite text-14 text-bluewood relative flex items-center;
        // box-shadow: -10px 0 44px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.05);
        font-weight: 500;
        line-height: 1.21;
        height: 60px;
        padding-left: 27px;

        &:before {
          background-image: linear-gradient(2deg, theme('colors.bluewood') -8%, theme('colors.greylines') 108%);
          position: absolute;
          height: 100%;
          width: 3px;
          top: 0;
          left: 0;
          content: '';
        }

        .icon {
          transform: translateY(-50%);
          position: absolute;
          right: 22px;
          top: 50%;

          svg,
          .svgicon {
            transition: ease-in 0.3s;

            path {
              @apply fill-current text-bluewood;
            }
          }
        }

        &.no_tab {
          .icon {
            @apply hidden;
          }
        }

        &.active {
          .icon {
            svg,
            .svgicon {
              transform: scaleY(-1);
            }
          }
        }
      }

      .s-lvl-content {
        position: relative;
        overflow: hidden;

        .ul {
          padding-top: 27px;
          margin: 0 27px;

          li {
            margin-bottom: 36px;

            a {
              @apply text-14;

              word-break: break-word;
              line-height: 1.21;
              font-weight: 500;
            }
          }

          &:not(._arrow-btn) {
            li {
              &:last-child {
                margin-bottom: 34px;
              }
            }
          }

          &._arrow-btn {
            @apply pt-0;

            .button-style-2 {
              min-width: 170px;
              @apply inline-block w-auto text-12;
            }
          }
        }
      }
    }
  }

  .nav-cta-list {
    width: calc(100% - 46px);
    margin: 29px auto;
  }

  &.only-button {
    @apply justify-start;

    .nav-cta-list {
      text-align: center;
      margin: 78px auto 29px;
    }
  }
}

.js-mmenu-acc-content {
  display: none;
}

.mnav-mandatory-btn {
  background-color: #fff;
  font-size: 0.875rem;
  color: #28334a;
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.05);
  font-weight: 500;
  line-height: 1.21;
  height: 60px;
  padding-left: 27px;
  margin-bottom: 8px;

  &:before {
    background-image: linear-gradient(2deg, #28334a -8%, rgba(127, 141, 171, 0.35) 108%);
    position: absolute;
    height: 100%;
    width: 3px;
    top: 0px;
    left: 0px;
    content: '';
  }
}

// brand theme
.m-primarylinks {
  > .link {
    &.live {
      background-image: linear-gradient(60deg, theme('colors.live') 2%, theme('colors.mango') 101%);

      .big-pattern {
        svg {
          path {
            @apply fill-current text-live;
          }
        }
      }

      .small-pattern {
        svg {
          path {
            @apply fill-current text-yelloworange;
          }
        }
      }

      .m-menu-innercontent {
        .backbutton {
          background-image: linear-gradient(76deg, theme('colors.live') 4%, theme('colors.mango') 99%);
        }
      }

      .m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu {
        .s-lvl-content .ul:not(._arrow-btn) li a {
          &.active,
          &:active {
            @apply text-yelloworange;
          }
        }

        > .heading {
          &:before {
            background-image: linear-gradient(2deg, theme('colors.live') -8%, theme('colors.mango') 108%);
          }

          .icon {
            svg,
            .svgicon {
              path {
                @apply fill-current text-live;
              }
            }
          }
        }
      }

      .mnav-mandatory-btn {
        &:before {
          background-image: linear-gradient(2deg, theme('colors.live') -8%, theme('colors.mango') 108%);
        }
      }
    }

    div.m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu.live {
      > .heading {
        &:before {
          background-image: linear-gradient(2deg, theme('colors.live') -8%, theme('colors.mango') 108%);
        }

        .icon {
          svg,
          .svgicon {
            path {
              @apply fill-current text-live;
            }
          }
        }
      }

      .s-lvl-content .ul:not(._arrow-btn) li a {
        &.active,
        &:active {
          @apply text-yelloworange;
        }
      }
    }

    &.thrive {
      background-image: linear-gradient(61deg, theme('colors.midnightblue') 1%, theme('colors.greenblue') 99%);

      .big-pattern {
        svg {
          path {
            fill: #2a868f;
          }
        }
      }

      .small-pattern {
        svg {
          path {
            @apply fill-current text-blue;
          }
        }
      }

      .m-menu-innercontent {
        .backbutton {
          background-image: linear-gradient(76deg, theme('colors.midnightblue') 4%, theme('colors.greenblue') 99%);
        }
      }

      .m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu {
        .s-lvl-content .ul:not(._arrow-btn) li a {
          &.active,
          &:active {
            @apply text-green;
          }
        }

        > .heading {
          &:before {
            background-image: linear-gradient(2deg, theme('colors.midnightblue') -8%, theme('colors.greenblue') 108%);
          }

          .icon {
            svg,
            .svgicon {
              path {
                @apply fill-current text-greenblue;
              }
            }
          }
        }
      }

      .mnav-mandatory-btn {
        &:before {
          background-image: linear-gradient(2deg, theme('colors.midnightblue') -8%, theme('colors.greenblue') 108%);
        }
      }
    }

    div.m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu.thrive {
      > .heading {
        &:before {
          background-image: linear-gradient(2deg, theme('colors.midnightblue') -8%, theme('colors.greenblue') 108%);
        }

        .icon {
          svg,
          .svgicon {
            path {
              @apply fill-current text-greenblue;
            }
          }
        }
      }

      .s-lvl-content .ul:not(._arrow-btn) li a {
        &.active,
        &:active {
          @apply text-green;
        }
      }
    }

    &.discover {
      background-image: linear-gradient(76deg, theme('colors.grape') 3%, theme('colors.cerise') 97%);

      .big-pattern {
        svg {
          path {
            fill: #a9307d;
          }
        }
      }

      .small-pattern {
        svg {
          path {
            @apply fill-current text-pink;
          }
        }
      }

      .m-menu-innercontent {
        .backbutton {
          background-image: linear-gradient(76deg, theme('colors.grape') 4%, theme('colors.cerise') 99%);
        }
      }

      .m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu {
        .s-lvl-content .ul:not(._arrow-btn) li a {
          &.active,
          &:active {
            @apply text-pink;
          }
        }

        > .heading {
          &:before {
            background-image: linear-gradient(2deg, theme('colors.grape') -7%, theme('colors.cerise') 108%);
          }

          .icon {
            svg,
            .svgicon {
              path {
                @apply fill-current text-cerise;
              }
            }
          }
        }
      }

      .mnav-mandatory-btn {
        &:before {
          background-image: linear-gradient(2deg, theme('colors.grape') -7%, theme('colors.cerise') 108%);
        }
      }
    }

    div.m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu.discover {
      > .heading {
        &:before {
          background-image: linear-gradient(2deg, theme('colors.grape') -7%, theme('colors.cerise') 108%);
        }

        .icon {
          svg,
          .svgicon {
            path {
              @apply fill-current text-cerise;
            }
          }
        }
      }

      .s-lvl-content .ul:not(._arrow-btn) li a {
        &.active,
        &:active {
          @apply text-pink;
        }
      }
    }

    &.lifestyle {
      background-image: linear-gradient(
        76deg,
        theme('colors.vividviolet') 3%,
        theme('colors.red') 50%,
        theme('colors.yellow') 97%
      );

      .big-pattern {
        svg {
          path {
            fill: theme('colors.red');
          }
        }
      }

      .small-pattern {
        svg {
          path {
            @apply fill-current text-vividviolet;
          }
        }
      }

      .m-menu-innercontent {
        .backbutton {
          background-image: linear-gradient(
            76deg,
            theme('colors.vividviolet') 4%,
            theme('colors.red') 50%,
            theme('colors.yellow') 99%
          );
        }
      }

      .m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu {
        .s-lvl-content .ul:not(._arrow-btn) li a {
          &.active,
          &:active {
            @apply text-red;
          }
        }

        > .heading {
          &:before {
            background-image: linear-gradient(
              2deg,
              theme('colors.vividviolet') -7%,
              theme('colors.red') 50%,
              theme('colors.yellow') 108%
            );
          }

          .icon {
            svg,
            .svgicon {
              path {
                @apply fill-current text-red;
              }
            }
          }
        }
      }

      .mnav-mandatory-btn {
        &:before {
          background-image: linear-gradient(
            2deg,
            theme('colors.vividviolet') -7%,
            theme('colors.red') 50%,
            theme('colors.yellow') 108%
          );
        }
      }
    }

    div.m-submenumiddle-wrap .m-submenumiddle .s-lvl-menu.lifestyle {
      > .heading {
        &:before {
          background-image: linear-gradient(
            2deg,
            theme('colors.vividviolet') -7%,
            theme('colors.red') 50%,
            theme('colors.yellow') 108%
          );
        }

        .icon {
          svg,
          .svgicon {
            path {
              @apply fill-current text-red;
            }
          }
        }
      }

      .s-lvl-content .ul:not(._arrow-btn) li a {
        &.active,
        &:active {
          @apply text-red;
        }
      }
    }
  }
}

[dir='rtl'] {
  .mheader {
    .m-logo {
      left: unset;
      right: 34px;
    }

    .d-search-icon {
      left: 84px;
      right: unset;
    }

    .d-lang {
      left: 140px;
      right: unset;
    }
  }

  .burger-menu {
    left: 34px;
    right: auto;
  }

  .m-menu-innercontent .backbutton .icon {
    left: unset;
    right: 32px;
  }

  .m-primarylinks > .link.thrive .m-menu-innercontent .backbutton {
    background-image: linear-gradient(-76deg, #00306b 4%, #00a99d 99%);
  }

  .m-primarylinks.style--2 > .link {
    & > .macnhor .chevron-right {
      left: 6px;
      right: unset;
    }

    &.discover > .macnhor:after {
      background-image: linear-gradient(-86deg, #562e60 4%, #e21368 97%);
    }

    &.thrive > .macnhor:after {
      background-image: linear-gradient(-86deg, #00306b 3%, #00a99d 97%);
    }

    &.live > .macnhor:after {
      background-image: linear-gradient(-87deg, #d60b5b 4%, #f79525 99%);
    }
  }

  .m-menu-wrap {
    left: 0;
    right: unset;
    transform: translate3d(-100%, 0, 0);

    .lang-wrapper {
      margin-right: unset;
      margin-left: auto;
    }

    &.m-menu-wrap.expanded {
      transform: translateZ(0);
    }
  }

  .m-menu-innercontent {
    transform: translate3d(-100%, 0, 0);

    .backbutton {
      .icon {
        top: 50%;
        right: 32px;
        transform: translateY(-50%) scaleX(-1);
      }

      .title {
        transform: translate(50%, -50%);
        right: 50%;
      }

      .big-pattern {
        right: 0;
        top: 0;

        img,
        svg {
          top: 50%;
          right: 50%;
        }
      }
    }
  }

  // Media queries for .m-menu-innercontent
  @media (max-width: 641px) {
    .m-menu-innercontent {
      right: calc(100% - 84vw);
      left: unset;
    }
  }

  @media (min-width: 640px) {
    .m-menu-innercontent {
      .backbutton {
        .big-pattern {
          img,
          svg {
            transform: translate(50%, -50%) scale(2.5);
          }
        }
      }
    }
  }
}
