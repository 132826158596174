.header-stick {
  &:not(.bg-white) {
    .sub-nav .sub-links li.nav__dark a,
    .nav__dark .txt__right_nav {
      @apply text-grey;

      opacity: 0.8;
      transition: 0.2s ease-in;
    }

    .upper-right .d-search-icon.nav__dark .svgicon,
    .icon-links li.nav__dark .icon .svgicon,
    .chat-icon .d-chat-icon.nav__dark .svgicon {
      @apply text-grey fill-current;

      opacity: 0.8;
    }

    .d-main-nav .main-link.nav__dark > .anchor {
      @apply text-defaultsolidwhite;

      transition: 0.1s ease-in;
    }

    .d-logo .nav__dark .logoicon {
      @apply text-defaultsolidwhite fill-current;

      opacity: 0.8;
      transition: 0.1s ease-in;
    }
  }

  &.bg-white {
    .main-link:not(.open-subnav) {
      & > .anchor {
        .d-label {
          @apply opacity-0;
        }

        &:after {
          width: 24px;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            .d-label {
              @apply opacity-100;

              transition: all 0.25s ease-in-out;
            }

            &:after {
              @apply w-full;
            }
          }
        }
      }
    }
  }

  .d-main-nav .main-link {
    & > .anchor {
      &:after {
        transition: width 0.5s cubic-bezier(0, 0.55, 0.45, 1);
      }
    }

    .d-label {
      @apply cursor-pointer opacity-100;
    }
  }
}

:global(.kafd-body:not(.mobilemenu-open)) {
  .mheader {
    &.nav__light-center,
    &.nav__dark {
      background-color: transparent;
      backdrop-filter: blur(0);
      border-bottom: transparent;
      transition: 0.3s ease-in-out;

      .m-logo .logoicon {
        @apply text-defaultsolidwhite fill-current;

        transition: 0.3s ease-in-out;
      }

      .icon-exit,
      .d-search-icon .svgicon {
        @apply text-defaultsolidwhite fill-current;

        opacity: 1;
        transition: 0.3s ease-in-out;
      }

      .burger-menu {
        .burger {
          @apply bg-defaultsolidwhite;

          &:before,
          &:after {
            @apply bg-defaultsolidwhite;
          }
        }
      }

      .burger-menu.menu-on {
        .burger {
          background-image: linear-gradient(
            90deg,
            rgba(0, 48, 107, 1) 0%,
            rgba(0, 169, 157, 1) 25%,
            rgba(255, 255, 255, 0) 25%,
            rgba(255, 255, 255, 0) 75%,
            rgba(0, 169, 157, 1) 75%,
            rgba(0, 48, 107, 1) 100%
          );
        }
      }

      .d-lang {
        .lang-text {
          @apply text-bluewood bg-defaultsolidwhite;

          transition: 0.3s ease-in-out;
        }
      }
    }
  }

  .m-menu-wrap {
    &.nav__dark {
      background-color: transparent;

      .m-footerlinks-wrap {
        @apply text-defaultsolidwhite;
      }
    }

    .lang-wrapper {
      display: flex;

      .d-lang {
        display: inline-flex;
        justify-content: flex-end;
        font-family: KAFD, Arial, sans-serif;
        font-weight: 700;
        font-size: 1.125rem;
        align-items: center;
        color: #fff;
        margin-right: auto;
        min-height: 40px;

        .lang-text {
          line-height: 1;
          margin-bottom: 5px;
          letter-spacing: 0;
        }
      }
    }
  }
}

:global(.kafd-body.mobilemenu-open) {
  .mheader {
    @apply bg-bluewood;

    backdrop-filter: blur(0);
    border-bottom: transparent;
    transition: 0.3s ease-in-out;

    .m-logo .logoicon {
      @apply text-defaultsolidwhite fill-current;

      transition: 0.3s ease-in-out;
    }

    .icon-exit,
    .d-search-icon .svgicon {
      @apply text-defaultsolidwhite fill-current;

      opacity: 1;
      transition: 0.3s ease-in-out;
    }

    .burger-menu {
      .burger {
        background-color: transparent;

        &:before,
        &:after {
          @apply bg-defaultsolidwhite;
        }
      }
    }

    .d-lang {
      .lang-text {
        @apply text-bluewood bg-defaultsolidwhite;

        transition: 0.3s ease-in-out;
      }
    }

    .m-logo,
    .burger-menu,
    .d-lang,
    .d-search-icon {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
      transition: all linear 0.3s;
    }
  }
}
