@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.FormWrapper {
  display: flex;
  flex-direction: column;
  max-width: 1225px;

  .leftContent {
    h2 span {
      font-family: KAFD, Arial, sans-serif;
      font-weight: 300;
      font-size: 2.25rem;
      color: #28334a;
      position: relative;
      overflow: hidden;
      line-height: 38px;
      letter-spacing: 1px;
      word-break: break-word;
      width: 80%;
      @media (min-width: 1024px) {
        font-size: 56px;
        line-height: 64px;
        letter-spacing: 4px;
        width: auto;
      }
    }

    span:not(a span) {
      font-size: 0.8125rem;
      color: #28334a;
      position: relative;
      overflow: hidden;
      width: 80%;
      line-height: 1.77;
      margin-top: 16px;
      @media (min-width: 1024px) {
        margin-top: 20px;
        max-width: 337px;
        width: auto;
      }
    }

    a span {
      color: inherit;
    }

    max-width: 200px;

    @media (min-width: 446px) {
      max-width: 302px;
    }

    @media (min-width: 748px) {
      max-width: 543px;
    }

    @media (min-width: 1024px) {
      max-width: 100%;
    }
  }

  @screen lg {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 100px;
    margin-left: 60px;
  }

  .container {
    position: relative;
    flex-basis: 100%;

    @screen lg {
      flex-basis: 50%;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 2.25rem;
    line-height: 38px;
    letter-spacing: 1px;
    word-break: break-word;
    width: calc(100% - 97px);

    @screen lg {
      font-size: 56px;
      line-height: 64px;
      letter-spacing: 4px;
    }
  }

  p {
    font-size: 0.8125rem;
    line-height: 1.77;
    margin-top: 16px;
    width: calc(100% - 97px);

    @screen lg {
      margin-top: 20px;
      max-width: 337px;
      width: auto;
    }
  }

  .decoration {
    width: 62px;
    height: 62px;
    position: absolute;

    @screen lg {
      width: 92px;
      height: 92px;
      position: relative;
    }
  }

  .icon {
    width: 62px;
    height: 62px;
    position: absolute;
    background-size: cover;

    @screen lg {
      position: relative;
      width: 92px;
      height: 92px;
    }
  }

  .formWrapper {
    display: block;
    width: 100%;
    margin: 0 auto;

    @screen lg {
      max-width: 491px;
    }
  }
}

[dir='ltr'] {
  .FormWrapper {
    .decoration {
      right: -35px;
      top: 25px;
      @screen lg {
        left: 63px;
        top: 90px;
      }
    }

    .icon {
      top: 0;
      right: 0;

      @screen lg {
        top: -47px;
        left: 0;
      }
    }
  }
}

[dir='rtl'] {
  .FormWrapper {
    .decoration {
      left: -35px;
      top: 25px;
      @screen lg {
        right: 63px;
        top: 90px;
      }
    }

    .icon {
      top: 0;
      left: 0;

      @screen lg {
        top: -47px;
        right: 0;
      }
    }
  }
}

:global(.globalPage-default) .decoration {
  background: linear-gradient(39deg, #26334c, #627493);
}

:global(.globalPage-default) .icon {
  background-color: #26334c;
  -webkit-mask-image: url('~@images/patterns/patt-1.svg');
  mask-image: url('~@images/patterns/patt-1.svg');
}

:global(.globalPage-accelerate) .decoration {
  background: linear-gradient(39deg, #00306b -2%, #41bdb6 104%);
}

:global(.globalPage-accelerate) .icon {
  background-color: #00a99d;
  -webkit-mask-image: url('~@images/patterns/patt-1.svg');
  mask-image: url('~@images/patterns/patt-1.svg');
}

:global(.globalPage-live) .decoration {
  background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
}

:global(.globalPage-live) .icon {
  background-color: #ea9a42;
  -webkit-mask-image: url('~@images/patterns/patt-3.svg');
  mask-image: url('~@images/patterns/patt-3.svg');
}

:global(.retailLeasingInquiryPage) .icon {
  background-color: #ea9a42;
  -webkit-mask-image: url('~@images/patterns/patt-6.svg');
  mask-image: url('~@images/patterns/patt-6.svg');
}

:global(.bookResidUnitPage) .icon {
  background-color: #ea9a42;
  -webkit-mask-image: url('~@images/patterns/patt-2.svg');
  mask-image: url('~@images/patterns/patt-2.svg');
}

:global(.bookResidUnitPage) {
  .FormWrapper {
    margin-bottom: 160px;
    @media (min-width: 1024px) {
      margin-bottom: 80px;
    }
  }
}

:global(.globalPage-live-space) .decoration {
  background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
}

:global(.globalPage-live-space) .icon {
  background-color: #ea9a42;
  -webkit-mask-image: url('~@images/patterns/patt-1.svg');
  mask-image: url('~@images/patterns/patt-1.svg');
}

:global(.globalPage-live-unit) .decoration {
  background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
}

:global(.globalPage-live-unit) .icon {
  background-color: #ea9a42;
  -webkit-mask-image: url('~@images/patterns/patt-2.svg');
  mask-image: url('~@images/patterns/patt-2.svg');
}

:global(.whistleBlowerFormPage) .FormWrapper {
  .leftContent {
    max-width: 80%;
    @media (min-width: 748px) {
      max-width: 80%;
    }

    //link in Optimazely on WhistleBlow Page on the leftContent
    h6 {
      @media (min-width: 640px) {
        width: 50%;
      }
      @media (min-width: 1024px) {
        width: 80%;
      }

      a {
        width: 100%;

        span {
          color: #fff;
          font-weight: 700;
          max-width: none;
          width: 100%;
          white-space: nowrap;
          font-size: 10px;
          height: 30px;

          @media (min-width: 1024px) {
            padding-left: 96px;
            padding-right: 96px;
            height: 40px;
            font-size: 1rem;
          }
          @media (min-width: 1280px) {
            padding-left: 120px;
            padding-right: 120px;
          }
        }
      }
    }

    //text about description of WhistleBlower on the leftContent
    h5 {
      margin-bottom: 0;
      @media (min-width: 1024px) {
        max-width: 350px;
      }
    }
  }

  .formWrapper {
    margin-top: 40px;

    @media (min-width: 1024px) {
      margin-top: 54px;
      margin-right: 60px;
    }
  }
}
