@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.ArticleList {
  @apply w-full lg:w-11/12;

  .bwcta__btn {
    margin-top: 1.5rem;

    @screen lg {
      height: 40px;
      margin-top: 13.35rem;
      margin-left: 0;
      position: relative;
    }

    @screen xl {
      margin-left: 10%;
    }

    @media screen and (min-width: 1440px) {
      margin-left: 27%;
    }
  }
}

.news__load_more_btn {
  margin-top: 2.5rem;

  @screen lg {
    margin-left: 9.35%;
  }
}
