//
// KAFD fonts
//

@font-face {
  font-family: 'KAFD';
  src:
    url('~@fonts/kafd/KAFDlight-Light.ttf') format('truetype'),
    url('~@fonts/kafd/KAFDlight-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KAFD';
  src: url('~@fonts/kafd/KAFDbold-Bold.eot');
  src:
    url('~@fonts/kafd/KAFDbold-Bold.eot?#iefix') format('embedded-opentype'),
    url('~@fonts/kafd/KAFDbold-Bold.ttf') format('truetype'),
    url('~@fonts/kafd/KAFDbold-Bold.woff') format('woff'),
    url('~@fonts/kafd/KAFDbold-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KAFD';
  src: url('~@fonts/kafd/KAFDregular-Regular.eot');
  src:
    url('~@fonts/kafd/KAFDregular-Regular.eot?#iefix') format('embedded-opentype'),
    url('~@fonts/kafd/KAFDregular-Regular.woff2') format('woff2'),
    url('~@fonts/kafd/KAFDregular-Regular.woff') format('woff'),
    url('~@fonts/kafd/KAFDregular-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'KAFD-Special';
  src: url('~@fonts/kafd/KAFDspecialfont-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//
// KAFD mixins
//

@mixin font-kafd-light() {
  font-family: 'KAFD', Arial, sans-serif;
  font-weight: 300;
  text-transform: none;
}

@mixin font-kafd-regular() {
  font-family: 'KAFD', Arial, sans-serif;
  font-weight: normal;
  text-transform: none;
}

@mixin font-kafd-bold() {
  font-family: 'KAFD', Arial, sans-serif;
  font-weight: bold;
  text-transform: none;
}

@mixin font-kafd-special() {
  font-family: 'KAFD-Special', Arial, sans-serif;
  font-weight: normal;
  text-transform: none;
}
