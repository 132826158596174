// Mixins
// reference https://github.com/engageinteractive/core/blob/master/src/scss/utility/_mixins.scss

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: (($sizeValue/100) * 62.5) + rem;
}

@mixin font-size($sizeValue: 1.6, $lineHeight: 1) {
  font-size: ($sizeValue * 10) + px;
  font-size: (($sizeValue/100) * 62.5) + rem;
  line-height: ($lineHeight / $sizeValue);
}

@mixin visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@mixin visually-hidden-reset {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin unselectable {
  -webkit-touch-callout: none;
  user-select: none;
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin on-hover() {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin on-touch() {
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin textoverflow-clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  display: block;
}

@mixin carousel-dots-theme($themeColor, $defaultColor) {
  .kafd-carousel-dots {
    &.slide-initialized,
    &.slick-initialized {
      .slick-dots {
        li {
          &.slide-complete {
            &:after {
              background: $themeColor;
            }
          }

          &.slide-complete,
          &.slick-active {
            button {
              background: $themeColor;
            }
          }
        }
      }
    }

    &.autoplay {
      li {
        &:after {
          background: linear-gradient(to right, $defaultColor 50%, $defaultColor 50%);
        }

        &.slide-complete {
          border-color: $themeColor;

          &:after {
            border-color: $themeColor;
            background: linear-gradient(to right, $themeColor 100%, $defaultColor 0%);
          }
        }

        &.slick-active {
          border-color: $themeColor;

          &:after {
            background: linear-gradient(to right, $themeColor 50%, $defaultColor 50%);
          }
        }

        &.slide-complete,
        &.slick-active {
          button:before {
            background-color: $themeColor;
          }
        }
      }
    }

    &.slide-initialized,
    &.slick-initialized {
      .slick-dots {
        li {
          &.slide-complete,
          &.slick-active {
            border-color: $themeColor;
          }
        }
      }
    }
  }
}

@mixin backface-hidden {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

@mixin medium-devices {
  // Medium devices (tablets, less than 1023px)
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin mobile-devices {
  // small devices (mobiles, less than 767px)
  @media (max-width: 767px) {
    @content;
  }
}

@mixin medium-devices-1024-1439 {
  // Medium devices (tablets, less than 1023px)
  @media (min-width: 1024px) and (max-width: 1439px) {
    @content;
  }
}

@mixin fullStretchOnMobile {
  @include mobile-devices {
    padding-left: 35px;
    padding-right: 35px;
    margin-left: -35px;
    margin-right: -35px;
  }
}

@mixin non-retina-screens {
  @media not screen and (-webkit-min-device-pixel-ratio: 2),
    not screen and (min--moz-device-pixel-ratio: 2),
    not screen and (-o-min-device-pixel-ratio: 2/1),
    not screen and (min-device-pixel-ratio: 2),
    not screen and (min-resolution: 192dpi),
    not screen and (min-resolution: 2dppx) {
    // non-retina-specific styles here
    @content;
  }
}
