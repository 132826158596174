@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.625rem;
  color: #28334a;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem;
  cursor: pointer;
  background: transparent;
  border-bottom: 1px solid #e8ebf0;
  border-right: 1px solid #e8ebf0;
  border-top: none;
  border-left: none;
  min-height: 4rem;

  &:focus {
    outline: none;
  }

  .textWrapper {
    font-family: 'KAFD', Arial, sans-serif;
    color: #28334a;
    text-align: left;
    max-width: calc(100% - 24px);
    font-size: 1.125rem;
    line-height: 1.625rem;
  }

  .svgWrapper {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    display: block;
    transition: 0.2s ease-out;
  }
}

[dir='rtl'] {
  :global(.faq) {
    .header {
      border-left: 1px solid #e8ebf0;
      border-right: none;
    }
  }

  .textWrapper {
    text-align: right;
  }
}

.headerActive {
  svg {
    transform: scaleY(-1);
  }
}

.content {
  transition: height 0.2s ease-out;
  overflow: hidden;

  > div {
    padding-top: 2rem;
    margin-bottom: 1.875rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
}

.contentVisible {
  display: block;
}

.contentHidden {
  display: none;
}
