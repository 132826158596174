[data-animation='on'] {
  [data-anim='on'] {
    .animate-section {
      .block-reveal-animation {
        position: relative;
        overflow: hidden;

        &:after {
          content: '';
          position: absolute;
          top: -1%;
          right: -1%;
          width: 102%;
          height: 102%;
          background-color: #fff;
          animation: 1s reveal-block-ltr ease-in-out 0.87s both;
          border: none;
          z-index: 10;
        }
      }

      .hori-img-comp__col_media {
        animation: 1s showDropShadow ease-in-out 2.8s both;

        &:after {
          content: '';
          position: absolute;
          // left: 0;
          right: 0;
          top: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          background: theme('colors.darkblue');
          transform: translate3d(-101%, 0, 0);
          animation: 1.5s reveal-banner ease-in-out 0.87s both;
          z-index: 10;
        }

        .img {
          animation: 1s reveal-banner-img ease-in-out 1.3s both;
        }
      }
    }

    .lozad,
    .gallery__overlay {
      transform: scale(0.6);
      opacity: 0;
      transition:
        transform 0.65s ease-in-out 0.5s,
        opacity 0.65s ease-in-out 0.5s;

      &[data-loaded='true'] {
        transform: scale(1);
        animation: motif-box-animation 0.75s ease-in-out 1s both;
        opacity: 1;
      }
    }
  }
}

[data-animation='on'][dir='rtl'] {
  [data-anim='on'] {
    .animate-section {
      .block-reveal-animation {
        &:after {
          left: unset;
          right: 102%;
          animation: 1s reveal-block-ltr ease-in-out 0.87s reverse both;
        }
      }

      .hori-img-comp__col_media {
        &:after {
          transform: translate3d(-101%, 0, 0);
          animation: 1.5s reveal-banner ease-in-out 0.87s reverse both;
        }

        .img {
          animation: 1s reveal-banner-img-bw ease-in-out 1.3s both;
        }
      }
    }
  }
}
