//
// Reset CSS and global styles
//
@import '@styles/core/_mixins.scss';
@import '@styles/core/_fontFamily.scss';
@import '@styles/core/_variables.scss';
@import '@styles/core/_animations.scss';
@import '@styles/core/_keyframes-rule.scss';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

/* Text elements */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

h1 {
  font-size: 2em;
}

a {
  color: inherit;
  text-decoration: inherit;
  background-color: transparent;
}

b,
strong {
  font-weight: bolder;
}

ul {
  list-style: none;
}

.button-reset-styles {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Forms & inputs */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  overflow: visible;
  color: inherit;
}

button,
select {
  text-transform: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
  appearance: button;
}

input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

[role='button'],
button {
  cursor: pointer;
  background-color: transparent;
  background-image: none;
}

// Remove inner padding and border in Firefox 4+
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
}

// Restore the focus styles unset by the previous rule
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

// Remove spinner from number inputs in WebKit and Chrome
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* Images & media */
img,
video {
  max-width: 100%;
  height: auto;
  border-style: none;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
}

div[class*='VideoPlayer'] {
  video {
    object-fit: cover;
    object-position: left top;
    height: inherit;
  }
}

/* Tables */
table {
  border-collapse: collapse;
}

/* Other special elements */
details,
template {
  display: none;
}

[hidden] {
  display: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px;
}

small {
  font-size: 80%;
}

body {
  @include font-kafd-regular();

  color: theme('colors.bluewood');
  letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
  animation: 1s fade-in ease 1s both;

  &.kafd-white-fade {
    @apply fixed inset-0 opacity-0;
  }
}

.prevent-scroll {
  max-height: 100vh;
  overflow: hidden;
}

.kafd-html {
  width: 100%;
  height: 100%;
}

.kafd-body {
  &.kafd-body-admin {
    max-width: 1440px;
  }

  height: auto;
  overflow-x: hidden;
  position: relative;

  // this is for menu overlay
  &:after {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    transition:
      opacity 0.2s ease-in-out 0.1s,
      visibility 0.2s ease 0.1s;
    will-change: opacity, visibility;
    visibility: hidden;
    opacity: 0;
    z-index: 92;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }

  &.mobilemenu-open {
    &:after {
      visibility: visible;
      opacity: 1;
      backdrop-filter: blur(5px);
      transition:
        opacity 0.5s ease-in-out 0s,
        visibility 0.5s ease 0s;
    }
  }
}

.kafd-main-sec {
  // max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}

.kafd-section {
  @apply mb-10;

  @screen xl {
    width: 91.666667%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.kafd-section:has(.allnassr) {
  margin-inline-start: 0 !important;
  margin-bottom: 0;

  p > span {
    font-size: 1rem;
  }
  @screen xl {
    width: 100%;
  }
}

.kafd-container:has(.allnassr) {
  @screen lg {
    padding: 0 93px 6rem 122px !important;
    min-height: 75vh;
    max-width: 1440px;
  }

  div[class*='VideoBlock_Wrapper'] {
    width: 83.333333%;
    max-width: 950px;
    height: 455px;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-end: 40px;

    img {
      object-fit: cover;
      object-position: left top;
    }
  }
}

.envisionPage,
.officePage {
  .kafd-section {
    margin-bottom: 0;
    padding-block-start: 2.5rem;

    &:has(h2):has(:not(p)) {
      margin-block-end: -2.5rem;
    }

    .contenteditor {
      margin: 0;
      padding: 0;

      p {
        padding-block-end: 30px;
      }
    }

    div[class*='Carousel_carouselSection'] {
      margin-bottom: 0;
    }
  }
}

.envisionPage {
  .kafd-section {
    &:nth-child(2n) {
      padding-block-end: 2.5rem;
    }
  }
}

.officePage {
  .kafd-section {
    &:nth-child(2n + 1) {
      padding-block-end: 2.5rem;
    }
  }
}

[dir='ltr'] {
  .kafd-section {
    margin-left: auto;
  }
}

[dir='rtl'] {
  .kafd-section {
    margin-right: auto;
  }
}

.negative-margin {
  @apply -mt-10;

  @screen lg {
    @apply -mt-24;
  }
}

.component-grid {
  @apply pb-24;
}

.kafd-container {
  min-height: auto;
  margin-top: calc(88px + 4rem);
  margin-bottom: 4rem;

  @screen lg {
    @apply mt-24 mx-auto;
    @apply pb-24;

    min-height: 75vh;
    max-width: $max-screen-width-lg;
  }

  /* .full-width:first-of-type {
		margin-top: calc((88px + 4rem) * -1);
		@screen lg {
			@apply -mt-24;
		}
	} */
}

[dir='ltr'] {
  .kafd-container {
    padding-right: $pageMargin-md;
    padding-left: $pageMargin-md;

    @screen lg {
      padding-left: $left-nav-offset-lg;
      padding-right: $right-nav-offset-lg;
    }

    @screen xl {
      padding-right: 120px;
    }
  }
}

[dir='rtl'] {
  .kafd-container {
    padding-left: $pageMargin-md;
    padding-right: $pageMargin-md;

    @screen lg {
      padding-right: $left-nav-offset-lg;
      padding-left: $right-nav-offset-lg;
    }

    @screen xl {
      padding-right: 120px;
    }
  }
}

.kafd-hero-video {
  ~ .kafd-container {
    @apply mt-16;
    @screen lg {
      @apply mt-24;
    }
  }
}

//
// Styles for globally reusable components
//

// Button with line - Starts here
.button-style-2 {
  @include backface-hidden;
  @apply mx-auto relative block w-full overflow-visible;

  border-bottom: 2px solid theme('colors.bluewood');

  &:focus,
  &:active {
    outline: none;
  }

  .cta-text {
    @apply text-14 font-medium;

    line-height: 1.215;
    padding: 8px 0;
    @include textoverflow-clip;
  }

  .cta-icon {
    @include vertical-center;

    width: 16px;
    height: 16px;
    @apply text-bluewood fill-current;
  }

  &:after {
    content: '';
    @apply absolute left-0 w-full opacity-0;

    top: 100%;
    height: 2px;
    background: theme('colors.bluewood');
  }

  .cta-icon,
  &:after {
    transition:
      top 0.3s,
      opacity 0.3s,
      transform 0.3s;
  }

  &.dark-theme {
    border-bottom: 2px solid theme('colors.defaultsolidwhite');

    .cta-text,
    .cta-icon.icon {
      @apply text-defaultsolidwhite fill-current;
    }

    &:after {
      background: theme('colors.defaultsolidwhite');
    }
  }
}

[dir='ltr'] {
  .button-style-2 {
    padding-right: 26px;

    .cta-text {
      @apply text-left;
    }

    .cta-icon {
      right: 0;
    }
  }
}

//Arabic version
[dir='rtl'] {
  * {
    letter-spacing: 0 !important;
  }

  .button-style-2 {
    padding-right: 0;

    .cta-text {
      @apply text-right;
    }

    .cta-icon {
      left: 0;
    }
  }
}

// Button with line - Hover effect
@include on-hover {
  .button-style-2 {
    &:hover,
    &:focus {
      &:after {
        @apply opacity-100;

        transform: scale(1);
        top: calc(0% - 3px);
      }
    }
  }

  .wrap-foot {
    .button-style-2 {
      &:hover,
      &:focus {
        &:after {
          top: 0;
        }
      }
    }
  }
}

// Button with line - Animation
[data-animation='on'] {
  .button-style-2.expand-animate {
    float: left;
    transition:
      width 2s cubic-bezier(0.33, 1, 0.68, 1) 2s,
      opacity 0.5s ease 2s;
    width: 25%;
    opacity: 0;
  }

  .animate-section {
    .button-style-2.expand-animate {
      opacity: 1;
      width: 100%;
    }
  }
}

// Button with line - RTL CSS
[dir='rtl'] {
  .button-style-2 {
    .cta-icon {
      transform: translateY(-50%) scale(-1);
    }
  }
}
// Button with line - Ends here

// Button reveal, push out - Starts here
.button-style-reveal {
  outline: none;
  display: inline-block;
  text-decoration: none;
  letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
  @apply text-10 font-bold text-bluewood relative;
}

.btn-reveal-effect {
  @include backface-hidden;

  position: relative;
  z-index: 1;

  .button-style-reveal {
    overflow: hidden;

    span {
      display: block;
      padding: 12px 18px;
      background: theme('colors.defaultsolidwhite');
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
      border: 1px solid rgba(40, 51, 74, 0.3);
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      padding: 12px 18px;
      width: 100%;
      height: 100%;
      background: theme('colors.bluewood');
      color: theme('colors.defaultsolidwhite');
      content: attr(data-hover);
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s;
      -webkit-transform: translateX(-25%);
      transform: translateX(-25%);
      border: 1px solid rgba(40, 51, 74, 0.3);
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:focus {
        span {
          -webkit-transform: translateX(100%);
          -moz-transform: translateX(100%);
          transform: translateX(100%);
        }

        &:before {
          -webkit-transform: translateX(0%);
          -moz-transform: translateX(0%);
          transform: translateX(0%);
        }
      }
    }
  }
}
// Button reveal, push out - Ends here

[dir='rtl'] {
  .leading-none {
    line-height: 1.2;
  }
}
// KAFD SPECIAL FONT
em.font-kafdsp {
  font-style: normal;
}

.globalPage-envision {
  h4 {
    text-transform: uppercase;
  }
}

.articlePage {
  .kafd-section {
    width: 100%;
  }

  &.kafd-container {
    margin-bottom: 0px;
  }

  .contenteditor {
    p {
      font-size: 16px;
    }

    h1 {
      font-size: 1.5rem;
      line-height: 32px;
      letter-spacing: 2px;
      font-weight: bolder;

      @media (max-width: 768px) {
        font-size: 1.125rem;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
}

.aboutPage {
  &.kafd-main-sec {
    @media (min-width: 1440px) {
      overflow-x: visible;
    }
  }

  & .contenteditor {
    p:has(span > span) {
      text-align: justify;
    }
  }
}

.formPage {
  padding-bottom: 160px;
}

.legalPage {
  h1 {
    font-size: 2.25rem !important;
    @apply py-3;
  }

  h3 {
    font-size: 1.5rem !important;
  }

  @screen lg {
    margin-left: 20px;
    width: calc(64%); // approximating
    max-width: 715px;

    h1 {
      @apply py-7;

      font-size: 56px !important;
    }

    h3 {
      font-size: 2rem !important;
      letter-spacing: 0.6px !important;
    }
  }
}

[dir='ltr'],
[dir='rtl'] {
  .boardDirectorsPage,
  .executiveManagePage {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bwcta__btn {
  @apply flex justify-center;

  &__btn {
    opacity: 0;
  }

  button {
    @apply uppercase;

    font-size: 1rem;
  }
}

[data-anim='on'] {
  .bwcta__btn__btn {
    animation: motif-box-animation 0.75s ease-in-out 0.75s both;
  }
}

.explore-kafd {
  padding-bottom: 0 !important;
  margin-bottom: -1rem !important;
}
//highlighted word
.highlightedWord {
  background: #e8ebf0;
}

.loader-spinner {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid theme('colors.slate');
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 15% auto;
  margin-left: calc(58% - 60px);
  @screen lg {
    margin-left: calc(56% - 60px);
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin-left: calc(59% - 60px);
  }
  @media (min-width: 376px) and (max-width: 768px) {
    margin-left: calc(54% - 60px);
  }
}

.retailersPage {
  .contenteditor {
    margin: 0;
    padding: 0;
  }
}

.privacyPage {
  margin-bottom: 0;
  margin-top: calc(88px + 6rem);

  @media (min-width: 1024px) {
    margin-top: calc(88px + 2rem);
  }
}

.accelerateSuccessFormPage {
  @media (max-width: 1023px) {
    margin-top: 0;
  }

  margin-bottom: 0;
}

.contactUsSuccessFormPage {
  @media (max-width: 1023px) {
    margin-top: 0;
  }

  margin-bottom: 0;
}

.bookEventSuccessFormPage {
  @media (max-width: 1023px) {
    margin-top: 0;
  }

  margin-bottom: 0;
}

.bookResUnitSuccessPage {
  @media (max-width: 1023px) {
    margin-top: 0;
  }

  margin-bottom: 0;
}

.bookLeasInquirySuccessFormPage {
  @media (max-width: 1023px) {
    margin-top: 0;
  }

  margin-bottom: 0;
}

.slick-disabled {
  cursor: default;
}

.kafd-body.kafd-body-admin [data-anim='on'] .reveal-container .image-container:after,
.kafd-body.kafd-body-admin [data-anim='on'] .reveal-container .reveal-container-text-wrapper:after {
  content: none;
}

.load_more_btn {
  display: flex;
  justify-content: center;

  & > * > span {
    text-transform: uppercase;
  }
}
