@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animation';

.btn__primary-1 {
  font-family: KAFD, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.625rem;
  color: #fff;
  display: inline-block;
  cursor: pointer;
  line-height: 22px;
  letter-spacing: 1.5px;
  border: none;

  &:focus {
    outline: none;
  }

  p > & {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 40px;
  }
}

@media (min-width: 1024px) {
  .btn__primary-1 {
    font-size: 1rem;
  }
}

.btn__primary-1 span.text {
  /* transform-origin:left center; */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #28334a;
  padding: 12px 24px 10px;
  min-height: 32px;
  // white-space: nowrap;
  line-height: 1;
}

@media (min-width: 1280px) {
  .btn__primary-1 span.text {
    padding-left: 40px;
    padding-right: 40px;
  }
}

:global(.dialogue-button) .btn__primary-1 {
  justify-content: start;
}

:global(.contact-us-success) .btn__primary-1 {
  height: 0;
  @media (min-width: 1024px) {
    transform: translate(0px, -70px);
  }
}

:global(.leasing-inquiry-success) .btn__primary-1 {
  height: 0;
  @media (min-width: 1024px) {
    transform: translate(0px, -70px);
  }
}

:global(.office-leasing-inquiry-success) .btn__primary-1 {
  height: 0;
  @media (min-width: 1024px) {
    transform: translate(0px, -70px);
  }
}

:global(.residential-unit-success) .btn__primary-1 {
  height: 0;
  @media (min-width: 1024px) {
    transform: translate(0px, -70px);
  }
}

:global(.event-space-success) .btn__primary-1 {
  height: 0;
  @media (min-width: 1024px) {
    transform: translate(0px, -70px);
  }
}

:global(.unknown-page) .btn__primary-1 {
  height: 0;
}

:global(.unknown-page) p:has(.btn__primary-1) {
  text-align: start;
}

p:has(.btn__primary-1) {
  text-align: end;
}
