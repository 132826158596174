@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.unorderedList {
  line-height: 1.77;
  font-size: 0.8125rem;
  list-style-type: disc;
  list-style-position: inside;
}

:global(.aboutPage) {
  .unorderedList {
    margin-left: 0;
  }
}
