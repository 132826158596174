@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.heading3 {
  font-size: 24px;
  line-height: 28px;

  @screen lg {
    font-size: 40px;
    line-height: 40px;
  }
}

:global(.legalPage) .heading3 {
  font-weight: 500;
}

[dir='rtl'] {
  :global(.legalPage) .heading3 {
    letter-spacing: 0;
  }
}
