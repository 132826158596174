//
//
//

.d-top-nav {
  @apply max-w-full w-full float-none mx-auto;

  min-height: 80px !important;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
  border-bottom: solid 1px rgba(210, 215, 225, 0.6);
  margin-top: 0 !important;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }

  &.header--hidden {
    z-index: 1000;
    transform: translateY(-100%);

    .d-logo-right,
    .d-logo {
      opacity: 0;
    }

    .nav-links > li.has-submenu.active > .submenu,
    .nav-links > li.has-submenu:not(.no-hover):hover > .submenu {
      @apply opacity-0 pointer-events-none invisible;
    }
  }

  &.header--showing {
    z-index: 1000;
    transform: translateY(0);

    .d-logo-right,
    .d-logo {
      opacity: 1;
    }
  }

  .nav-inner-wrapper {
    @apply flex;
  }

  .links-wrapper {
    @apply flex relative;

    margin: 20px auto 0;

    .d-magic-line {
      @apply bg-bluewood absolute;

      height: 1px;
      width: 0;
      left: -150px;
      top: 40px;
      transition: all ease 0.4s;
      pointer-events: none;
    }

    > a {
      @apply inline-block text-defaultsolidwhite;

      padding: 12px 15px 14px;
      opacity: 0.9;

      &.d-search-icon,
      &.d-lang {
        cursor: pointer;
        letter-spacing: 0;
        line-height: 1;
        padding: 12px 10px 14px;
        @screen xl {
          @apply ml-5;
        }
      }

      &.d-search-icon {
        padding-top: 10px;
      }

      &.d-lang {
        @apply ml-8;

        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: -11px;
          top: 10px;
          height: 24px;
          width: 1px;
          background-color: #d2d7e1;
          pointer-events: none;
        }

        .lang-text {
          @apply text-bluewood flex font-kafd font-bold justify-center py-0 px-2;

          min-width: 50px;
          min-height: 21px;
          letter-spacing: $rtl-space #{'/*rtl:1.3px*/'};
          line-height: 17px#{'/*rtl:' 23px '*/'};
          transition: all ease 0.3s;
          font-size: 18px#{'/*rtl:' 18px '*/'};
        }
      }

      .icon {
        .svgicon {
          path {
            @apply fill-current text-bluewood;

            transition: all ease 0.3s;
          }
        }
      }

      &:hover {
        text-shadow: 0.5px 0 0 currentColor;
        opacity: 1;
      }
    }
  }

  .d-logo {
    @apply absolute;

    top: 30px;
    left: 30px;
    clip-path: inset(122px 0 13px 0);
    transform: translateY(-120px);
    transition: all ease 0.3s;

    .logoicon {
      width: 80px;
      height: 155px;
    }

    a {
      display: inline-block;
    }

    path {
      @apply fill-current text-bluewood;

      transition: all ease 0.3s;
    }

    &-right {
      @apply absolute;

      top: 16px;
      right: 30px;
      transition: top ease 0.3s;
      will-change: top;

      .pif-logo {
        width: 48px;
        transition: width ease 0.3s;
        will-change: width;

        path {
          @apply fill-current text-bluewood;

          transition: all ease 0.3s;
        }
      }
    }
  }

  .nav-links {
    @apply text-bluewood flex relative self-start;

    transition: all ease 0.3s;
    height: 100%;
    cursor: pointer;

    > li {
      @apply font-bold relative text-16;

      letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
      line-height: 22px;

      > a {
        @apply inline-block;

        padding: 12px 15px 14px;
        opacity: 0.9;
      }

      &.has-submenu {
        .submenu-link {
          @apply relative;

          padding-right: 44px;
          transition: padding ease 0.3s;

          .chevron-bottom {
            @apply absolute;

            top: 18px;
            right: 18px;
            width: 12px;
            height: 8px;
            transition: all ease 0.3s;

            path {
              @apply fill-current text-bluewood;

              transition: all ease 0.3s;
            }
          }
        }

        &.active,
        &:not(.no-hover):hover {
          @apply text-defaultsolidwhite;

          background-color: rgba(40, 51, 74, 0.9);
          padding-bottom: 11px;
          transition: padding ease 0.3s;

          > .submenu {
            @apply opacity-100 pointer-events-auto visible;

            > .secondary-links {
              opacity: 1;
              transition: opacity ease-in-out 0.3s 0.3s;
            }
          }

          .submenu-link {
            .chevron-bottom {
              transform: scaleY(-1);

              path {
                @apply fill-current text-defaultsolidwhite;
              }
            }
          }

          .icon {
            .svgicon {
              path {
                @apply fill-current text-defaultsolidwhite;

                transition: all ease 0.3s;
              }
            }
          }
        }
      }

      &.active,
      &:hover {
        > a {
          text-shadow: 0.5px 0 0 currentColor;
          opacity: 1;
        }
      }

      .icon {
        .svgicon {
          path {
            @apply fill-current text-bluewood;

            transition: all ease 0.3s;
          }
        }
      }
    }
  }

  .submenu {
    @apply absolute text-defaultsolidwhite opacity-0 pointer-events-none invisible px-5;

    background-color: rgba(40, 51, 74, 0.9);
    top: 100%;
    left: 0;
    width: 100%;
    height: auto;
    min-width: 300px;

    > .secondary-links {
      @apply flex flex-col -mx-5 pb-5 opacity-0;

      transition: opacity ease-in-out 0.1s 0s;

      > li {
        @apply px-5;

        flex: 0 1 33.3%;
        max-height: 64px;

        &.has-submenu {
          .submenu-link {
            @apply relative;

            padding-right: 44px;

            .chevron-right {
              @apply absolute;

              top: 50%;
              transform: translateY(-50%);
              right: 6px;
              width: 8px;
              height: 12px;
              transition: all ease 0.3s;

              path {
                @apply fill-current text-defaultsolidwhite;

                transition: all ease 0.3s;
              }
            }
          }

          &.active,
          &:not(.no-hover):hover {
            .submenu {
              &.inner-right {
                @apply opacity-100 pointer-events-auto visible;

                .secondary-links {
                  @apply opacity-100;

                  transition: opacity ease-in-out 0.3s 0.3s;
                }
              }
            }

            .submenu-link {
              .chevron-right {
                transform: translate(6px, -50%);
              }
            }
          }
        }

        .secondary-info {
          @apply relative text-14 font-kafd font-bold text-defaultsolidwhite py-4;

          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 2px;
            bottom: 0;
            right: 0;
            left: 0;
            background-image: linear-gradient(
              86deg,
              theme('colors.lightgray') 4%,
              theme('colors.lightgray') 50%,
              theme('colors.defaultsolidwhite') 97%
            );
            transition: ease-in 0.2s;
          }

          .secondary-title {
            @apply my-2 text-14;

            line-height: 16px;
            letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
            transition: all ease 0.3s;
          }

          .secondary-description {
            @apply text-12;

            line-height: 16px;
            letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
            transition: all ease 0.3s;
          }
        }

        &.live {
          .secondary-info {
            &:after {
              background-image: linear-gradient(87deg, theme('colors.live') 4%, theme('colors.mango') 99%);
            }
          }

          .secondary-links {
            > li {
              &.active,
              &:hover {
                .secondary-info {
                  .secondary-title {
                    @apply text-orange;
                  }
                }
              }
            }
          }

          &.active,
          &:hover {
            > a {
              .secondary-info {
                .secondary-title {
                  @apply text-orange;
                }
              }
            }
          }
        }

        &.thrive {
          .secondary-info {
            &:after {
              background-image: linear-gradient(86deg, theme('colors.midnightblue') 3%, theme('colors.greenblue') 97%);
            }
          }

          .secondary-links {
            > li {
              &.active,
              &:hover {
                .secondary-info {
                  .secondary-title {
                    @apply text-green;
                  }
                }
              }
            }
          }

          &.active,
          &:hover {
            > a {
              .secondary-info {
                .secondary-title {
                  @apply text-green;
                }
              }
            }
          }
        }

        &.discover {
          .secondary-info {
            &:after {
              background-image: linear-gradient(86deg, theme('colors.grape') 4%, theme('colors.cerise') 97%);
            }
          }

          .secondary-links {
            > li {
              &.active,
              &:hover {
                .secondary-info {
                  .secondary-title {
                    @apply text-pink;
                  }
                }
              }
            }
          }

          &.active,
          &:hover {
            > a {
              .secondary-info {
                .secondary-title {
                  @apply text-pink;
                }
              }
            }
          }
        }

        &.lifestyle {
          .secondary-info {
            &:after {
              background-image: linear-gradient(
                86deg,
                theme('colors.vividviolet') 4%,
                theme('colors.red') 50%,
                theme('colors.yellow') 97%
              );
            }
          }

          .secondary-links {
            > li {
              &.active,
              &:hover {
                .secondary-info {
                  .secondary-title {
                    @apply text-red;
                  }
                }
              }
            }
          }

          &.active,
          &:hover {
            > a {
              .secondary-info {
                .secondary-title {
                  @apply text-red;
                }
              }
            }
          }
        }
      }
    }

    &.show {
      @apply opacity-100 pointer-events-auto visible;

      .secondary-links {
        opacity: 1;
      }
    }

    &.inner-right {
      top: 0;
      left: 100%;
      bottom: 0;
      background-color: rgba(34, 44, 64, 0.9);

      .secondary-links {
        @apply my-0 mb-3 opacity-0;

        > li {
          @apply mb-3;
        }

        .secondary-info {
          @apply py-1;

          &:after {
            display: none;
          }

          .secondary-title {
            @apply mb-0 mt-0;

            transition: all ease 0.3s;
          }
        }
      }

      &.show {
        @apply opacity-100 pointer-events-auto visible;
      }
    }
  }

  &.nav__dark {
    background-color: transparent;
    backdrop-filter: blur(0);
    border-bottom: transparent;

    .links-wrapper {
      .d-magic-line {
        background-color: #a5b3c3;
      }

      > a {
        @apply text-bluewood;

        &.d-lang {
          .lang-text {
            @apply text-defaultsolidwhite;
          }

          &::before {
            background-color: #657490;
          }
        }

        .icon {
          .svgicon {
            path {
              @apply fill-current text-defaultsolidwhite;
            }
          }
        }
      }
    }

    .d-logo {
      clip-path: none;
      transform: translateY(0);

      .logoicon {
        path {
          @apply fill-current text-defaultsolidwhite;
        }
      }

      &-right {
        top: 30px;
        right: 30px;
        display: flex;
        height: 155px;
        align-items: center;

        .pif-logo {
          width: 91px;

          path {
            @apply fill-current text-defaultsolidwhite;
          }
        }
      }
    }

    .nav-links {
      @apply text-defaultsolidwhite;

      height: auto;

      > li {
        > a {
          transition: padding ease 0.3s;
          padding-bottom: 4px;
        }

        &.has-submenu {
          .chevron-bottom {
            path {
              @apply fill-current text-defaultsolidwhite;
            }
          }
        }

        .icon {
          .svgicon {
            path {
              @apply fill-current text-defaultsolidwhite;
            }
          }
        }
      }
    }
  }

  &.nav__light {
    background-color: transparent;
    backdrop-filter: blur(0);
    border-bottom: transparent;

    .links-wrapper {
      > a {
        @apply text-bluewood;

        &.d-lang {
          .lang-text {
            @apply text-bluewood;
          }

          &::before {
            background-color: #657490;
          }
        }

        .icon {
          .svgicon {
            path {
              @apply fill-current text-bluewood;
            }
          }
        }
      }
    }

    .d-logo {
      clip-path: none;
      transform: translateY(0);

      .logoicon {
        path {
          @apply fill-current text-bluewood;
        }
      }

      &-right {
        top: 30px;
        right: 30px;
        display: flex;
        height: 155px;
        align-items: center;

        .pif-logo {
          width: 91px;

          path {
            @apply fill-current text-bluewood;
          }
        }
      }
    }

    .nav-links {
      @apply text-bluewood;

      height: auto;

      > li {
        > a {
          transition: padding ease 0.3s;
          padding-bottom: 4px;
        }

        &.has-submenu {
          .chevron-bottom {
            path {
              @apply fill-current text-bluewood;
            }
          }
        }

        .icon {
          .svgicon {
            path {
              @apply fill-current text-bluewood;
            }
          }
        }
      }
    }
  }
}

.hidden-sticky-element {
  @apply hidden;

  height: 80px;
}

/*ANIMATION*/
[data-animation='on'] {
  .d-top-nav {
    .nav-links {
      > li {
        &.has-submenu {
          &.active,
          &:not(.no-hover):hover {
            > .submenu {
              animation: growDown 0.5s ease-in-out forwards;
              transform-origin: top center;
            }
          }
        }
      }
    }

    .submenu {
      .secondary-links {
        > li {
          &.has-submenu {
            &.active,
            &:not(.no-hover):hover {
              .submenu {
                &.inner-right {
                  animation: growRight 0.6s ease-in-out forwards;
                  transform-origin: top left;
                }
              }
            }
          }
        }
      }

      &.show {
        @apply opacity-100 pointer-events-auto visible;

        animation: growDown 0.5s ease-in-out forwards;
        transform-origin: top center;
      }

      &.inner-right {
        @apply py-6;

        &.show {
          animation: growRight 0.5s ease-in-out forwards;
          transform-origin: top left;
        }
      }
    }
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes growRight {
  0% {
    transform: scaleX(0);
  }

  80% {
    transform: scaleX(1.05);
  }

  100% {
    transform: scaleX(1);
  }
}

[dir='rtl'] {
  .d-top-nav {
    .nav-links > li.has-submenu.active > .submenu,
    .nav-links > li.has-submenu:not(.no-hover):hover > .submenu {
      -webkit-animation: growDown 0.5s ease-in-out forwards;
      animation: growDown 0.5s ease-in-out forwards;
      transform-origin: top center;
    }

    .submenu .secondary-links > li.has-submenu.active .submenu.inner-right,
    .submenu .secondary-links > li.has-submenu:not(.no-hover):hover .submenu.inner-right {
      -webkit-animation: growRight 0.6s ease-in-out forwards;
      animation: growRight 0.6s ease-in-out forwards;
      transform-origin: top right;
    }

    .submenu.show {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      -webkit-animation: growDown 0.5s ease-in-out forwards;
      animation: growDown 0.5s ease-in-out forwards;
      transform-origin: top center;
    }

    .submenu.inner-right.show {
      -webkit-animation: growRight 0.5s ease-in-out forwards;
      animation: growRight 0.5s ease-in-out forwards;
      transform-origin: top right;
    }

    .submenu .secondary-links li.has-submenu .chevron-right {
      right: unset;
      left: 6px;
    }

    .d-logo {
      right: 30px;
    }

    .d-logo-right {
      left: 30px;
      right: auto;
    }

    .links-wrapper {
      a {
        &.d-lang {
          margin-right: 2rem;
          margin-left: 0;
          transform: translateY(3px);

          &::before {
            transform: translateX(120px);
          }
        }
      }
    }

    .has-submenu .submenu-link.submenu-link--rtl {
      padding-left: 44px;
      padding-right: 15px;

      .chevron-bottom {
        left: 15px;
        right: auto;
      }
    }

    .submenu {
      padding-top: 0px;
      padding-bottom: 10px;
      left: unset;
      right: 0;

      .secondary-links > li .secondary-info {
        padding-top: 0;
      }

      &.inner-right {
        left: unset;
        right: 100%;
        padding: 1.5rem 1.25rem;
      }
    }
  }
}
