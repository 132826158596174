@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.heading4 {
  font-size: 18px;
  line-height: 22px;

  @screen lg {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
  }
}
