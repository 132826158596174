@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.heading6 {
  @apply text-14;

  line-height: 16px;
  letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};

  @screen lg {
    line-height: 20px;
    @apply text-16;
  }
}

[dir='ltr'] {
  :global(.legalPage) .heading6 {
    padding-left: 40px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    letter-spacing: 1.5px;
  }
}

[dir='rtl'] {
  :global(.legalPage) .heading6 {
    padding-right: 40px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    letter-spacing: 0px;
  }
}
