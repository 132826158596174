.recruitmentform {
  .EPiServerForms .FormChoice {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0;
    min-height: unset;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .FormChoice div {
    padding-left: 0px;
    margin-left: 0px;
    margin-top: 15px;
    width: auto;
  }

  .FormChoice__Input--Radio {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #28334a;
    background-color: #0000;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-left: 0px;
    margin-left: 11px;
    cursor: pointer;
  }

  .FormChoice__Input--Radio:focus {
    outline: none;
  }

  .FormChoice__Input--Radio:checked {
    background-color: #28334a;
    color: #FFFF;
  }

  .FormChoice__Input--Radio:checked ~ label.FormChoice__Label {
    color: white;
  }

  label.FormChoice__Label {
    position: relative;
    left: -18px;
    top: -12px;
    color: #28334a;
    padding: 0px;
  }

  .EPiServerForms .FormChoice .FormChoice__Label {
    padding-inline-start: 0px;
    cursor: pointer;
  }

  .FormChoice .Form__Element__Caption {
    grid-column-start: 1;
    grid-column-end: 6;
    position: unset !important;
  }
}
