@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.heading1 {
  font-size: 36px;
  line-height: 38px#{'/*rtl:' 40px '*/'};

  @screen lg {
    font-size: 56px;
    line-height: 64px#{'/*rtl:' 66px '*/'};
    letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
  }
}
