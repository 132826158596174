@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.paragraph {
  font-size: 18px;
  line-height: 22px;

  @screen lg {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
  }
}

:global(.legalPage) .paragraph {
  font-size: 16px;
}

:global(.articlePage) {
  .paragraph {
    text-align: justify;
    color: #000;
    line-height: 22px;
    letter-spacing: 0;
  }
}

[dir='rtl'] {
  :global(.homePageSubTitle) {
    .paragraph {
      font-size: 16px;
      letter-spacing: 0;
    }
  }
}

[dir='rtl'] {
  :global(.legalPage) .paragraph {
    font-weight: 700;
    font-size: 1rem;
    line-height: 22px;
    letter-spacing: 0;
  }
}
