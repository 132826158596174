@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.map-modal {
  padding-bottom: 10px;
  top: 6px;
  @apply relative;

  .map-modal-content {
    line-height: 33px;
    padding: 11px 24px;
    transition: 0.5s ease-in-out 0.1s;
    @apply text-defaultsolidwhite text-14 font-kafd font-bold text-center relative bg-bluewood;
    @screen md {
      padding: 24px 39px 20px;
      @apply text-18;
    }

    &:hover,
    &:focus {
      @include on-hover {
        background-color: #d2d7e1;

        &:after {
          background-color: #d2d7e1;
        }
      }
    }
    // point arrow
    &:after {
      background: theme('colors.bluewood');
      content: '';
      height: 12px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%, -50%) rotate(-45deg);
      width: 12px;
      transition: 0.5s ease-in-out 0.1s;
    }
    // cover pin area
    &:before {
      content: '';
      height: 48px;
      width: 32px;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%, 0%);
    }
  }
}
