// eg. HP - text blocks
.animate-block {
  > * {
    display: block;
  }

  > :is(h1, h2, h3, h4, h5, h6, p),
  [class*='VideoBlock_VideoPlayer'] {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 32;
    }
  }

  > :is(h1, h2, h3, h4, h5, h6, p):after {
    transition: 1s ease-in-out 0.25s;
  }

  [class*='VideoBlock_VideoPlayer']:after {
    transition: 1.25s ease-in-out 0.25s;
  }

  &.reveal-content {
    > :is(h1, h2, h3, h4, h5, h6, p):after {
      transform: translate3d(105%, 0, 0);
    }

    [class*='VideoBlock_VideoPlayer']:after {
      transform: translate3d(0, 101%, 0);
    }
  }
}

[dir='rtl'] {
  .animate-block {
    &.reveal-content {
      > :is(h1, h2, h3, h4, h5, h6, p):after {
        transform: translate3d(-105%, 0, 0);
      }
    }
  }
}

// eg. /en/live/residences/bliss-west - title
.move-left-to-right {
  > :is(h1, h2, h3, h4, h5, h6, p, span) {
    display: block;
    opacity: 0;
  }

  &.reveal-content-to-right {
    opacity: 1;

    > :is(h1, h2, h3, h4, h5, h6, p, span) {
      animation: motif-ascend-right-short 1.5s cubic-bezier(0, 0.45, 0.45, 1) 1.25s forwards;
    }
  }
}

// eg. /en/live/residences/bliss-west - download file section
.reveal-section-with-title {
  opacity: 1;

  .move-left-to-right {
    > :is(h1, h2, h3, h4, h5, h6, p, span) {
      animation: motif-ascend-right-short 1.5s cubic-bezier(0, 0.45, 0.45, 1) 1.25s forwards;
    }
  }

  // eg. /en/live/residences/bliss-west - file item
  .move-bottom-to-top {
    @for $i from 1 through 8 {
      li:nth-child(#{$i}) {
        animation: text-ascend #{$i * 0.25}s ease forwards;
      }
    }
  }
}

// eg. content-hero
.reveal-image-animation {
  position: relative;
  overflow: hidden;
  opacity: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 32;
    animation: 1.25s reveal-image ease-in-out 0.25s both;
  }
}

// eg. /en/live/residences/bliss-west
[data-anim='on'] {
  .reveal-container {
    .image-container {
      overflow: hidden;
      position: relative;
      transition-duration: 1s;
      transition-delay: 1s;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #303952;
        z-index: 999;
      }

      img {
        animation: scale-banner-img 2.5s ease-in-out 0.5s both;
      }
    }

    // For desc in carousel with controls
    // eg. /en/live/residences/bliss-west
    .reveal-container-text-wrapper {
      overflow: hidden;
      transition-duration: 1s;
      transition-delay: 1s;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: #303952;
      }
    }

    /* -- */
  }
}

[dir='ltr'] {
  [data-anim='on'] {
    .reveal-container {
      .image-container {
        animation: reveal-banner-img 1.5s cubic-bezier(0.65, 0, 0.35, 1) 0.125s both;

        &:after {
          animation: reveal-banner-inview 2s cubic-bezier(0.65, 0, 0.35, 1) 0.25s both;
        }
      }

      .reveal-container-text-wrapper {
        &:after {
          animation: reveal-banner 1.2s ease-in-out 0.57s both;
        }
      }

      .reveal-container-text {
        animation: reveal-banner-img 0.7s ease-in-out 1s both;
      }
    }
  }
}

[dir='rtl'] {
  [data-anim='on'] {
    .reveal-container {
      .image-container {
        animation: reveal-banner-img-bw 1.5s cubic-bezier(0.65, 0, 0.35, 1) 0.125s both;

        &:after {
          animation: reveal-banner-inview-rotate 2s cubic-bezier(0.65, 0, 0.35, 1) 0.25s both;
        }
      }

      .reveal-container-text-wrapper {
        &:after {
          animation: reveal-banner-reverse 1.2s ease-in-out 0.57s both;
        }
      }

      .reveal-container-text {
        animation: reveal-banner-img-bw 0.7s ease-in-out 1s both;
      }
    }
  }
}

.transform-carousel {
  opacity: 1 !important;

  .slick-slide:first-of-type .transform-slide,
  [class*='NoCarousel_items']:first-of-type {
    animation: transformHorizontally 0.75s ease-in-out 0.25s both;
  }

  .slick-slide:nth-of-type(2) .transform-slide,
  [class*='NoCarousel_items']:nth-of-type(2) {
    animation: transformHorizontally 0.75s ease-in-out 0.4s both;
  }

  .slick-slide:nth-of-type(3) .transform-slide,
  [class*='NoCarousel_items']:nth-of-type(3) {
    animation: transformHorizontally 0.75s ease-in-out 0.55s both;
  }
}

.visible-section {
  opacity: 1 !important;
}

// Live Carousel animations
[data-anim='on'] {
  div[class*='CarouselLive'] {
    .slick-current div[class*='imageInnerBlock'],
    .slick-current + .slick-slide div[class*='imageInnerBlock'] {
      &:after {
        width: 0;
      }
    }

    .slick-current div[class*='contentTitle'],
    .slick-current div[class*='contentDesc'],
    .slick-current div[class*='contentLink'] {
      animation: motif-box-animation 0.75s ease 0.6s both;
    }
  }
}

// eg. Article Hero
[data-anim='on'] {
  .text-reveal-animation {
    position: relative;
    overflow: hidden;

    &:after {
      transform: translate3d(101%, 0, 0);
    }
  }

  .animate-scale {
    animation: scale-banner-img 2.5s ease-in-out 0.1s both;
  }
}

[dir='rtl'] [data-anim='on'] {
  .text-reveal-animation:after {
    transform: translate3d(-101%, 0, 0);
  }
}

// eg. Search noresults
.no-results-animated {
  .animated-icon {
    animation: infoScaleUp 0.5s ease-in-out 0.1s both;
  }

  .animated-1 {
    animation: motif-cardbox-animation 0.55s ease-in-out 0.75s both;
  }

  .animated-2 {
    animation: motif-cardbox-animation 0.75s ease-in-out 1s both;
  }

  .animated-3 {
    animation: motif-cardbox-animation 0.75s ease-in-out 1.3s both;
  }

  .animated-4 {
    animation: motif-cardbox-animation 0.75s ease-in-out 1.7s both;
  }

  .text-block {
    overflow: hidden;

    > *:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 32;
    }
  }

  &[class*='bgWhite'] .text-block > *:after {
    background-color: #fff;
  }

  &[class*='bgDark'] .text-block > *:after {
    background-color: #28334a;
  }
}

[dir='ltr'] {
  .no-results-animated {
    .text-block > *:after {
      animation: 1s reveal-text ease-in 0.25s both;
    }
  }
}

[dir='rtl'] {
  .no-results-animated {
    .text-block > *:after {
      animation: 1s reveal-text-rtl ease-in 0.25s both;
    }
  }
}
