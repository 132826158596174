@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.FixedButton {
  position: fixed;
  bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.625rem;
  text-align: center;
  text-transform: uppercase;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  background: #fff;
  letter-spacing: 0.83px;
  line-height: 12px;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  z-index: 99;
  transition: all 0.4s ease;

  @screen md {
    bottom: 51px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.4s ease;
    background: linear-gradient(39.06deg, #26334c, #627493);
  }

  &:hover {
    color: #fff;
    background-color: #26334c;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
  }

  &:hover:before {
    opacity: 1;
  }
}

:global(.globalPage-envision) .FixedButton {
  color: #fff;
  background: linear-gradient(39deg, #562e60 -2%, #e21368 104%);
}

:global(.globalPage-accelerate) .FixedButton {
  color: #fff;
  background: linear-gradient(39deg, #00306b -2%, #00a99d 104%);
}

:global(.globalPage-live) .FixedButton {
  color: #fff;
  background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
}

[dir='ltr'] {
  .FixedButton {
    right: 35px;

    @screen md {
      right: 56px;
    }
  }
}

[dir='rtl'] {
  .FixedButton {
    left: 35px;

    @screen md {
      left: 56px;
    }
  }
}
