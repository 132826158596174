@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.attachBlock {
  &__title {
    font-size: 1.125rem;
    margin-top: 60px;
    margin-bottom: 16px;
    @media (min-width: 1024px) {
      font-size: 1.5rem;
      margin-top: 70px;
    }
  }

  .attachItem {
    padding-top: 32px;
    box-shadow: 1px 1px 0 0 #e8ebf0;
    padding: 20px 10px 10px 10px;
    cursor: pointer;

    [dir="rtl"] & {
      box-shadow: -1px 1px 0 0 #e8ebf0;
    }

    &:not(:last-child) {
      margin-bottom: 25px;
    }

    .attachItemWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;

      .fileErrorMessage {
        position: absolute;
        bottom: -30px;
        left: 0;
        font-weight: 700;
        font-size: 0.625rem;
        color: #ba1818;
        line-height: 1.5;
        letter-spacing: 2px;

        @media (max-width: 440px) {
          bottom: -45px;
        }
      }

      .attachItemPlaceholder {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #e8ebf0;
        font-size: 0.625rem;
        font-weight: 700;
        color: #28334a;
        line-height: 1.5;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-top: 8px;
        color: #a0aec0;

        &__max {
          padding-right: 20px;
        }

        &__filename {
          color: #28334a;
          font-size: 12px;
          padding-right: 15px;
        }

        &__size {
          color: #28334a;
        }
      }

      .uploadIcon {
        display: flex;
        margin-left: 20px;
        transform: translate(0px, -10px);
        @media (min-width: 1024px) {
          margin-left: 50px;
        }

        [dir="rtl"] & {
          margin-left: 0;
          margin-right: 20px;
          @media (min-width: 1024px) {
            margin-left: 0;
            margin-right: 50px;
          }
        }
      }

      .deleteButton {
        border: none;
        background: none;

        &:focus {
          outline: none;
        }

        .deleteIcon {
          width: 24px;
          height: 24px;
          margin-left: 20px;
          transform: translate(0px, -10px);
          @media (min-width: 1024px) {
            margin-left: 50px;
          }
        }
      }
    }
  }

  .validationItemError {
    box-shadow: 1px 1px 0 0 #ba1818;
    
    [dir="rtl"] & {
      box-shadow: -1px 1px 0 0 #ba1818;
    }
  }

  .attachButtonWrapper {
    margin-top: 20px;
    display: flex;

    button {
      border: none;
    }

    @media (max-width: 440px) {
      margin-top: 40px;
    }
  }
}
