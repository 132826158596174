// animation
.animate-section {
  opacity: 1 !important;

  .residence-listing__media,
  .residence-listing__picture {
    animation: 1s showDropShadow ease-in-out 2.8s both;

    &:after {
      animation: 1.5s reveal-banner ease-in-out 0.87s both;
    }

    .img {
      animation: 1s reveal-banner-img ease-in-out 1.3s both;
    }
  }
}

.lozad,
.gallery__overlay {
  transform: scale(0.6);
  opacity: 0;
  transition:
    transform 0.65s ease-in-out 0.5s,
    opacity 0.65s ease-in-out 0.5s;

  &[data-loaded='true'] {
    transform: scale(1);
    animation: motif-box-animation 0.75s ease-in-out 1s both;
    opacity: 1;
  }
}

.cta-icon,
.button-style-2:after {
  transition:
    top 0.3s,
    opacity 0.3s,
    transform 0.3s;
}

// Button with line - Hover effect
@include on-hover {
  .button-style-2 {
    &:hover,
    &:focus {
      &:after {
        @apply opacity-100;

        transform: scale(1);
        top: calc(0% - 3px);
      }
    }
  }

  .wrap-foot {
    .button-style-2 {
      &:hover,
      &:focus {
        &:after {
          top: 0;
        }
      }
    }
  }
}
