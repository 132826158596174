@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate';

.footer__contacts {
  @apply flex lg:hidden;
}

.footerTopContactUs {
  @apply w-full lg:w-4/12 xl:w-3/12;
}

.footerTopMenus {
  @apply lg:w-8/12 xl:w-9/12;
}

.footer-top-menu {
  @apply flex justify-between;

  @screen lg {
    padding-inline-end: 1.25rem;
  }
  @screen xl {
    @apply pr-2;
  }
}

.footerStoreBtns {
  @apply flex gap-2 my-4;
}

// SEPARATOR
.footer-separator {
  @apply hidden;

  height: 1px;
  background-color: theme('colors.greylines');
  @screen lg {
    @apply block;
  }
}

// FOOTER
.wrap-foot {
  @apply text-14;

  width: 100%;
  position: relative;
  overflow: hidden;

  .flist.active {
    button.chevron {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  .kafd-main-sec {
    max-width: $max-screen-width-lg;
    margin: 0 auto;

    .besocial {
      @screen lg {
        @apply hidden;
      }
    }
  }

  .kafd-container {
    margin-top: 0;
    min-height: auto;
    padding-inline-start: 35px;
    padding-inline-end: 35px;
    @apply pb-0;
    @screen lg {
      padding-inline-start: 64px;
      padding-inline-end: 64px;
    }
    @screen xl {
      padding-inline-start: 124px;
      padding-inline-end: 124px;
    }
  }

  .w-full.text-reveal-animation:last-of-type {
    overflow: visible;
  }

  .upper-foot {
    padding: 2.5rem 0;
    @apply bg-bluewood;
    @screen lg {
      padding: 3.5rem 0 2.2rem;
    }
  }

  .eventslinks {
    @screen lg {
      @apply w-9/12;

      margin-inline-end: 3%;
    }
    @screen xl {
      margin-inline-end: 0;
    }

    li {
      @screen xl {
        @apply w-3/12;
      }

      a {
        @extend .footeranchor;

        opacity: 1;
      }
    }
  }

  .footerlinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    order: 9;
    margin-top: 1.5rem;
    @screen lg {
      order: unset;
      width: auto;
      margin-top: 0;
    }

    li {
      margin-inline-end: 1rem;
      margin-top: 1rem;
      @screen lg {
        margin-top: 0;
        @apply whitespace-nowrap;

        &:last-child {
          margin-inline-end: 2.5rem;
        }
      }
      @screen xl {
        margin-inline-end: 24px;
      }

      a {
        color: #657490;
        line-height: 1.25;
        display: block;
        transition: all ease-in 0.2s;
        @screen xl {
          color: #d2d7e1;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  background-color: rgba(40, 51, 74, 0.95) !important;

  .besocial {
    li {
      a {
        .icon {
          background-color: #363f55;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .btn__white_container {
      margin-bottom: 20px;
    }
  }
}

.copyrights {
  font-weight: 500;
  @apply text-14 flex items-center;

  overflow: hidden !important;
  @screen lg {
    margin-top: 12px;
    @apply whitespace-nowrap order-2;
  }
  @screen lg {
    @apply flex leading-none mt-0 mb-0 mr-0;
  }

  .title {
    @apply hidden;
  }

  .copyright-txt {
    @apply inline-block leading-4;

    margin-inline-end: 10px;
    padding: 2px 0;
    @screen lg {
      @apply order-1;

      margin-inline-start: 1rem;
      margin-inline-end: 10px;
      padding: 2px 0 1px;
    }
  }

  .m-logo {
    width: 68px;
    @apply h-4 inline-block fill-current text-defaultsolidwhite;
    @screen lg {
      @apply order-2;
    }
  }

  .anchor {
    @apply flex items-center; /* Ensure alignment and spacing */
    @screen lg {
      @apply flex-row-reverse;
    }
  }
}

.anchor {
  @apply flex;
  @screen lg {
    @apply flex-row-reverse;
  }
}

// FOOTRE LINKS
.foot-links {
  margin-inline-start: 1rem;
  @screen lg {
    @apply order-1;
  }
}

// FIRST BOTTOM
.first-bottom {
  @screen lg {
    padding: 1.5rem 0;
  }

  .besocial,
  .f-contact {
    padding: 0.5rem 0.5rem;
  }

  .f-contact {
    @apply hidden;
    @screen lg {
      @apply flex justify-between;
      @apply ml-auto;
    }

    li {
      margin-inline-start: 2.5rem;

      &:first-child {
        @apply ml-0;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          .icon {
            @apply fill-current text-defaultsolidwhite;
          }

          .label {
            @apply text-defaultsolidwhite;
          }
        }
      }
    }
  }
}

// LAST BOTTOM
.last-bottom {
  padding: 24px 0 35px;
  @screen lg {
    padding: 2rem 0;
  }
}

.list-content,
.list-content-inner {
  @apply flex-col;

  margin-bottom: 25px;
  @screen lg {
    @apply flex-row mb-0;
  }

  .chevron {
    @apply block;
    @screen lg {
      @apply hidden;
    }
  }
}

// HEADING
.f-heading {
  @apply text-16 leading-none relative;

  font-weight: bold;
  word-break: break-word;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0.675rem 0 0.813rem;
  margin-bottom: 0.45rem;

  &.lifestyle {
    &:after {
      background: linear-gradient(39.25deg, #d60b5b -2.37%, #f79525 103.75%);
    }
  }

  &.thrive {
    &:after {
      background: linear-gradient(40.66deg, #00306b -0.97%, #00a99d 101.68%);
    }
  }

  &.discover {
    &:after {
      background: linear-gradient(39.06deg, #562e60 -2.02%, #e21368 103.1%);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
  }

  @screen lg {
    padding-inline-end: 8px;
    margin-bottom: 1rem;

    &:after {
      content: unset;
    }
  }

  @media screen and (max-width: 1023px) {
    color: #fff !important;
  }

  button {
    @apply absolute right-0 outline-none;

    top: 50%;
    transform: translateY(-50%);
  }
}

// For animation
.flist,
.footerTopContactUs,
.first-bottom > *,
.last-bottom > * {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 1s ease-in-out 0.25s;
    background-color: #293449;
  }
}

// NAVIGATION COL
.flist {
  @screen lg {
    @apply xl:w-3/12;
  }

  &:last-child {
    ul {
      @apply mb-0;
    }
  }

  ul {
    @media screen and (max-width: 1024px) {
      visibility: hidden;
      max-height: 0;
      transition: all 0.4s ease-in;
    }

    li {
      margin-bottom: 0.45rem;

      &:last-child {
        margin-bottom: 25px;
      }

      a {
        font-size: 0.875rem;
        color: #fff;
        font-weight: 500;
        line-height: 2;
        position: relative;
        transition: all ease-in 0.2s;

        &:hover {
          opacity: 0.5 !important;
        }
      }
    }

    @screen lg {
      @apply mb-0;
    }
  }

  &.active {
    ul {
      visibility: visible;
      max-height: 500px;
      transition: all ease-in 0.6s;
    }
  }

  /* default */
  @apply text-defaultsolidwhite;

  .live {
    @apply text-yelloworange;
  }

  .thrive {
    @apply text-green;
  }

  .discover {
    @apply text-pink;
  }

  .lifestyle {
    @apply text-orange;
  }
}

// FOOTER CONTACT
.f-contact {
  ul {
    @apply flex justify-between;
  }

  li {
    a {
      @extend .footeranchor;

      line-height: 2;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  &.email {
    margin-bottom: 31px;
    @screen lg {
      margin-bottom: 38px;
    }
  }

  .footer-tel,
  .footer-email {
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 24px;
    min-height: 1rem;
    position: relative;
    @apply flex items-center;

    direction: ltr;

    .title {
      @apply hidden;
    }

    .icon {
      height: 16px;
      max-width: 16px;
      margin-bottom: 3px#{'/*rtl:2px*/'};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      @apply fill-current text-grey;

      @include non-retina-screens {
        margin-bottom: 0;
      }

      path {
        @apply fill-current;

        color: #657490;
        transition: all ease-in 0.2s;
      }
    }

    .label {
      @apply text-13 leading-3 ml-2;

      padding-top: 3px#{'/*rtl:0*/'};
      font-weight: 500;
      margin: 0;
      letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};

      @include non-retina-screens {
        padding-top: 0;
      }
    }

    @include on-hover {
      &:hover,
      &:focus {
        .label,
        svg,
        path {
          @apply text-defaultsolidwhite;
        }
      }
    }
  }
}

// LEVEL 2
.level-2 {
  padding-top: 2rem;
  @screen lg {
    padding-top: 0;
  }
  // LEVEL 2 SOCIALS
  .besocial {
    @apply hidden;
    @screen lg {
      display: flex !important;
      @apply relative mt-auto;

      bottom: unset;
      left: unset;
    }

    &.besocial_mobile {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 0.75rem;
      @screen lg {
        display: none !important;
      }
    }

    li {
      @apply mb-0;

      &:not(:last-child) {
        @media (max-width: 360px) {
          margin-inline-end: 0.5rem;
        }

        margin-inline-end: 2.5rem;
        @screen sm {
          margin-inline-end: 4rem;
        }
        @screen lg {
          margin-inline-end: 1.55rem;
        }
      }

      a,
      .icon {
        @media (max-width: 360px) {
          padding: 1.2rem;
        }

        @apply w-6 h-6;

        &:hover {
          .svgicon {
            path {
              color: #fff;
            }
          }
        }

        .svgicon {
          @apply fill-current;

          width: 1.75rem;
          height: 1.75rem;
          @screen lg {
            width: 1.125rem;
            height: 1.125rem;
          }

          path {
            @apply fill-current;

            color: #657490;
            transition: all ease-in 0.2s;
          }
        }
      }
    }
  }
}

// SOCIALS
.besocial {
  @apply flex justify-between;
  @screen lg {
    justify-content: unset;
    position: absolute;
    display: block;
    bottom: 22px;
    left: 39px;
    padding: 0;
  }

  li {
    @apply mb-2;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      @apply text-defaultsolidwhite text-13 relative block text-center;

      width: 24px;
      height: 24px;
      @screen lg {
        width: 16px;
        height: 16px;
      }

      .title,
      .icon {
        @apply absolute;

        transition: all ease-in 0.2s;
        line-height: 18px;
        left: 0;
        top: 0;
        @screen lg {
          height: 16px;
          width: 16px;
        }
        @include medium-devices {
          width: 24px;
          height: 24px;
        }
      }

      .title {
        transform: scale(0);
      }

      .icon {
        background: theme('colors.desaturatedblue');
        transform: scale(1);

        .svgicon {
          transform: translate(-50%, -50%);
          position: absolute;
          margin: 0 auto;
          left: 50%;
          top: 50%;
          width: 1.5rem;
          color: #657490;
          @apply fill-current;
        }
      }

      @include on-hover {
        &:hover,
        &:focus {
          @screen lg {
            .title {
              transform: scale(0);
            }

            .icon {
              transform: scale(1);
            }
          }

          .svgicon {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

// LOGO
.pif-wrap {
  @screen lg {
    @apply w-1/12 absolute;

    left: 0;
    bottom: 28px;
  }

  .pif__logo2 {
    @screen lg {
      @apply block;

      right: 1.9%;
    }
    @screen xl {
      right: 1.8125rem;
      bottom: 1.875rem;
    }

    img,
    svg {
      width: 145px;
    }
  }
}

// BIG BUTTON
.btn__white_container {
  &._b2 {
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    margin-bottom: 1rem;
    @apply flex;
    @screen lg {
      margin-bottom: 1.5rem;
    }

    a {
      @apply w-full flex items-center justify-center;

      height: 2.5rem;
      font-size: 1rem;
      padding: 12px 24px 10px;
      line-height: 19px;
      background-color: #fff;
      align-items: normal;
      white-space: nowrap;
      text-transform: uppercase;
      letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
      transition: all 0.75s cubic-bezier(0.16, 1, 0.3, 1);

      &:hover {
        letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
      }
    }
  }

  &._b3 {
    @apply flex justify-start;

    margin-bottom: 26px;
    @screen lg {
      @apply mt-6 mb-0;
    }

    a {
      @apply w-full ml-0;

      min-width: 170px;
      @screen lg {
        min-width: 215px;
      }
    }
  }

  .btn__white {
    @apply font-kafd text-14 h-8 leading-4;

    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    padding: 11px 18px 7px;
    transition: all 0.75s cubic-bezier(0.16, 1, 0.3, 1);
    @screen lg {
      @apply h-10;

      padding: 14px 40px 10px;
    }
    @include on-hover {
      &:hover {
        letter-spacing: 3px;
      }
    }
  }
}

// NOT FOUND
.footeranchor {
  @apply text-defaultsolidwhite text-13;
  @media (min-width: 1024px) and (max-width: 1100px) {
    @apply text-12;
  }

  &:hover,
  &:focus {
    @apply opacity-100;
  }
}

.email-place {
  background-color: theme('colors.greylines');
  height: 50px;
}

.pif__logo {
  right: 2.1875rem;
  top: -1.9375rem;
  width: 17.5%;
  max-width: 56px;
  @apply absolute;
  @screen lg {
    left: 1.3%;
    top: -1.625rem;
    width: 5.1%;
    max-width: 73px;
  }
  @screen xl {
    left: 2.1%;
  }

  + .kafd-container {
    @include medium-devices {
      padding-top: 2.25rem;
    }
  }
}

.subscription-box {
  @apply w-full relative py-1 border-none;

  border-bottom: 1px solid theme('colors.greylines');

  .form-input {
    @apply w-full text-greyfont text-13 leading-8 pr-10;

    height: 27px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    background-color: transparent;

    &::placeholder {
      @apply text-greyfont;
    }
  }

  .form-submit {
    position: absolute;
    padding: 6px;
    right: 0;
    bottom: 2px;
  }

  .form-input,
  .form-submit {
    &:focus {
      outline: none;
    }
  }
}

.footer-enq-btn {
  position: relative;
  height: 140px;
}

.btn__white,
.wrap-foot .eventslinks li a.btn__white {
  @apply text-10 font-bold bg-grey block text-bluewood text-center flex items-center justify-center py-2 px-3;

  height: 40px;
}

.footer-calendar {
  @apply text-defaultsolidwhite text-16 leading-none;

  position: relative;
  padding-inline-start: 40px;
  font-weight: bold;
  display: inline-block;

  .icon {
    transform: translateY(-50%);
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 0;

    path {
      @apply fill-current text-defaultsolidwhite;

      opacity: 1;
    }
  }
}

.footer-bottom-wrapper {
  @apply flex flex-row-reverse flex-wrap relative justify-between;
  @screen lg {
    @apply flex-row justify-end;
  }
}

[dir='rtl'] {
  .f-contact {
    margin-right: auto;
    margin-left: unset;

    .icon {
      right: 0;
      left: auto;
    }

    .footer-tel,
    .footer-email {
      padding-left: unset;
      padding-right: 24px;
    }
  }

  .pif-wrap {
    left: auto;
    right: 0;
  }

  .wrap-foot {
    .btn__white_container {
      margin-bottom: 26px;
    }

    @media (min-width: 1024px) {
      .btn__white_container {
        margin-top: 1.5rem;
        margin-bottom: 0;
      }
    }
  }

  .f-heading button {
    right: unset;
    left: 0;
  }
}
