// ==================================================
// Variables
// ==================================================

$min-width: 320px;
$max-width: 1140px;
$max-screen-width-lg: 1440px;
$max-hero-height: 645px;
$max-hero-height-lg: 860px;

// Breakpoints
$tablet-break-point: 768px;
$tablet-max-break-point: 991px;
$tabletlandscape-break-point: 992px;
$tabletlandscape-max-break-point: 1199px;
$desktop-break-point: 1200px;
$desktop-large-break-point: 1400px;

// Offset
$pageOffset-lg: 214px; // 120px + 94px
$pageOffset-md: 182px;
$pageMargin-md: 35px;

$left-nav-offset-lg: 122px;
$right-nav-offset-lg: 93px;
$outline: 1px auto -webkit-focus-ring-color;
$rtl-space: 0; // AR Letter spacing

// Primary btn
$primary-cta-time-fn: cubic-bezier(0.16, 1, 0.3, 1);
