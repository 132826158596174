@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.googleMapComp {
  opacity: 0;
  margin-top: 2.5rem;
  @screen md {
    margin-top: 6rem;
  }

  // address-col
  .address-col {
    padding: 2rem;
    width: 21.5rem;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    min-height: 13.125rem;
    z-index: 2;
    margin-bottom: -4.75rem;
    @apply bg-bluewood text-defaultsolidwhite flex flex-col justify-center;
    @screen md {
      font-size: 0.875rem;
      line-height: 1.25rem;
      min-height: 16.25rem;
      width: 140%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      margin-bottom: 0;
      padding: 3.125rem 2rem;
    }
    @screen lg {
      width: 130%;
    }

    &.invisible {
      @include mobile-devices {
        display: none;
      }
    }

    .share-popup {
      .popup-content {
        @screen md {
          height: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    h5 {
      font-size: 1.5rem;
      line-height: 1.75rem;
      font-weight: 300;
      text-transform: uppercase;
      @screen md {
        font-size: 32px;
        line-height: 40px;
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
        @screen md {
          margin-bottom: 1.25rem;
        }
      }
    }

    p {
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
    }
  }
  // map-col
  .map-col {
    margin: 0 -35px;
    @screen md {
      margin: 0;
    }
  }
  // google-map
  .google-map {
    min-height: 21.875rem;
    @screen md {
      min-height: 27.5rem;
    }
    // info window
    :global .gm-style {
      :global .gm-style-iw-c {
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        background: transparent;
      }

      :global .gm-style-iw-d,
      :global .gm-style-iw-c,
      :global .gm-style-iw-d {
        overflow: visible !important;
        max-width: 100% !important;
      }

      :global .gm-ui-hover-effect,
      :global .gm-style-iw-t::after {
        display: none !important;
      }

      :global iframe + div {
        opacity: 0 !important;
      }
    }

    .map-modal {
      padding-bottom: 10px;
      top: 6px;
      @apply relative;

      .map-modal-content {
        line-height: 33px;
        padding: 11px 24px;
        transition: 0.5s ease-in-out 0.1s;
        @apply text-defaultsolidwhite text-14 font-kafd font-bold text-center relative bg-bluewood;
        @screen md {
          padding: 24px 39px 20px;
          @apply text-18;
        }

        &:hover,
        &:focus {
          @include on-hover {
            background-color: #d2d7e1;

            &:after {
              background-color: #d2d7e1;
            }
          }
        }
        // point arrow
        &:after {
          background: theme('colors.bluewood');
          content: '';
          height: 12px;
          left: 50%;
          position: absolute;
          top: 100%;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 12px;
          transition: 0.5s ease-in-out 0.1s;
        }
        // cover pin area
        &:before {
          content: '';
          height: 48px;
          width: 32px;
          left: 50%;
          position: absolute;
          top: 100%;
          transform: translate(-50%, 0%);
        }
      }
    }
  }
  // Share button feature
  &.has-social-share {
    .share-btn {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 1.8rem;
      right: 2rem;
    }
  }
}

[data-animation='on'] {
  [data-anim='on'] {
    &.googleMapComp {
      opacity: 1;
    }

    .block-reveal-animation {
      position: relative;
      overflow: hidden;
    }

    .block-reveal-animation::after {
      content: '';
      position: absolute;
      top: -1%;
      right: -1%;
      width: 102%;
      height: 102%;
      background-color: #fff;
      animation: 1s reveal-block-ltr ease-in-out 0.87s both;
      border: none;
      z-index: 10;
    }
  }
}

[data-animation='on'][dir='rtl'] {
  [data-anim='on'] {
    .block-reveal-animation::after {
      left: unset;
      right: 102%;
      animation: 1s reveal-block-ltr ease-in-out 0.87s reverse both;
    }
  }
}
