@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.kafd-press-kit-section__header {
  margin-bottom: 1.875rem;
  @screen lg {
    margin-bottom: 2.5rem;
  }

  .title-text {
    font-size: 1.875rem;
    line-height: 2.25rem;
    text-transform: uppercase;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    @apply font-kafd font-light;
    @screen lg {
      font-size: 42px;
      line-height: 50px;
      letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
    }
  }
}

.press-kit_boxes {
  width: 100%;
  @apply mx-auto;
}
