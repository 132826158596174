@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.PersonCardsList {
  ul {
    list-style: none;
  }

  .kafd-card-column {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;

    @media (max-width: 640px) {
      justify-content: center;

      li {
        flex: 1 1 100%;
      }
    }

    .item {
      @screen sm {
        flex-basis: calc(100% / 2 - 30px);
      }

      @screen md {
        flex-basis: calc(100% / 3 - 30px);
      }
    }
  }
}
