/** This file list all CSS classes used by Episerver Forms.

    Class names are structured and named in BEM methodology (Block, Element, Modifier) so you can easily see the overview.
        - CSS classes and their structure defined here are used by Form Core JavaScript heavily.
        - Please keep the structure (parent-child block-element relation) and default class names as is.

    This file is intended to be re-styled by 3rd developers. or in their site template (E.g.: AlloyMVC).
        - Default styling contain only minimal styling-rule for Episerver Forms and its build-in elements.
        - Default styling is applied for both Forms rendering in ViewMode and authoring in EditView.
        - Styling rules can be modified freely.
*/
.EPiServerForms {
  $active-color: rgba(41, 128, 189, 0.5); /*predefined color for selected item, elapsed progress*/
  $invisibleelement-color: rgba(184, 192, 197, 0.6); /*to indicate FormStep, FormHidden in EditView*/
  $border-radius: 4px;
  $warning-foreground-color: red;
  $warning-background-color: #fff8aa;

  // http://ux.episerver.com/#colors
  $primary1: #84c8e4;
  $text-color: #333;

  .Form__Title {
    //new
    display: none;
  }

  .Form__Description {
    margin-bottom: 1rem;
  }

  .FormImageSubmitButton {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    background: none;
    box-shadow: none;
  }

  .Form__Status {
    .Form__Status__Message {
      display: block;
      padding: 1rem;
      margin: 1rem 0;
    }

    .Form__Success__Message {
      background-color: $primary1;
      color: $text-color;
    }

    .Form__Warning__Message {
      background-color: $warning-background-color;
      color: $warning-foreground-color;
    }

    .Form__Readonly__Message {
      display: block;
      padding: 1rem;
      margin: 1rem 0;
      background-color: $warning-background-color;
      color: $warning-foreground-color;
    }

    .hide {
      display: none;
    }
  }

  .Form__Success {
    .Form__SubmissionSuccess__Message {
      display: block;
      padding: 1rem;
      margin: 1rem 0;
    }

    .Form__Success__Message {
      background-color: $primary1;
      color: $text-color;
    }

    .hide {
      display: none;
    }
  }

  .Form__MainBody {
  }
  // for next or previous navigation between steps
  .Form__NavigationBar {
    .Form__NavigationBar__Action {
      display: inline-block;
      padding: inherit;
      vertical-align: middle;
      text-align: center;
      border: 1px solid $active-color;
      border-radius: $border-radius;
      font-size: inherit;
      height: 2em;
      width: 10em;
      max-width: 10rem;
      color: initial;
      background-image: linear-gradient(to top, #fff, #e6e6e6);
      background-image: -moz-linear-gradient(top, #fff, #e6e6e6);
      background-image: -ms-linear-gradient(top, #fff, #e6e6e6);
      background-image: -webkit-linear-gradient(top, #fff, #e6e6e6);

      &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }

    .Form__NavigationBar__ProgressBar {
      display: inline-block;
      border: 1px solid $active-color;
      vertical-align: middle;
      width: 40%;

      .Form__NavigationBar__ProgressBar--Progress {
        /*display: none;*/ /*turn on this style to hide visual progress bar*/
        background-color: $active-color;
        width: 0%; /* Will be adjusted by JavaScript */
        height: 1rem;
      }

      .Form__NavigationBar__ProgressBar--Text {
        display: none; /*turn off this style to show text "Page 1/4" of progress bar*/
        .Form__NavigationBar__ProgressBar__ProgressLabel {
        }

        .Form__NavigationBar__ProgressBar__CurrentStep {
        }

        .Form__NavigationBar__ProgressBar__StepsCount {
        }
      }
    }
  }

  .FormStep {
    .FormStep__Title {
    }

    .FormStep__Description {
      margin-bottom: 1rem;
    }
  }

  // All Form's Element need to be tag with this class at root DOM node. Form__Element wraps every-DOM-nodes of current form element.
  // For simple element like Textbox, it can be the input
  // For complex element like Selection, Captcha, it contains the Label and input controls for taking value from Visitor
  .Form__Element {
    //default
    // margin: 0 0 1.5rem 0;

    //new
    // box-shadow: 1px 1px 0 0 #e8ebf0;
    // margin: 0 0 25px 0;
    margin-block: 0 25px;
    margin-inline: 0;
    position: relative;
    border-bottom: 1px solid;
    border-color: #e8ebf0;

    &:focus .Form__Element__Caption,
    &:focus-within .Form__Element__Caption {
      top: 8px;
    }

    &:focus .IconCloseVisible,
    &:focus-within .IconCloseVisible {
      display: block;
      position: absolute;
      top: 50%;
    }

    // &:nth-child(10):not(button),
    // &:nth-child(11):not(button) {
    //   width: calc(100% / 2 - 10px);
    //   display: inline-block;
    // }
    // &:nth-child(10):not(button) {
    //   margin-inline-end: 10px;
    // }
    // &:nth-child(11):not(button) {
    //   margin-inline-start: 10px;
    // }

    &.FormSelection:nth-child(1),
    &.FormSelection:nth-child(2) {
      margin-block-end: 40px;
      @screen lg {
        margin-block-end: 54px;
      }
    }

    &:has(.selectList.open):not(:has(~ button)) {
      margin-block-end: 140px;
      @screen lg {
        margin-block-end: inherit;
      }
    }

    // change style of Form__Element in the Preview viewport in EditView (it affects both Editting mode and Preview mode)
    .preview & {
    }

    // label for element
    .Form__Element__Caption {
      //default
      // display: inline-block;
      // min-width: 15%;
      // vertical-align: top;

      //new
      font-size: 0.625rem;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: absolute;
      top: 19px;
      transition: all 0.25s ease-in-out;
      z-index: 10;

      &.filled {
        top: 0;
      }

      &.filled:has(~ .flatpickr-wrapper),
      &.filled:has(~ .select-display) {
        top: 6px;
      }
    }

    // validation error message for each Element
    .Form__Element__ValidationError {
      //default
      // color: $warning-foreground-color;
      // display: block;
      // word-wrap: break-word;

      //new
      position: absolute;
      top: 110%;
      font-weight: 700;
      font-size: 0.625rem;
      color: #ba1818;
      padding-inline-start: 2px;
      line-height: 1.5;
      letter-spacing: 2px;
    }
    // SEMANTIC
    // for marking non-data element (like FormStep, ParagraphText, ...)
    .Form__Element--NonData {
    }
    // Element has this class will not be bound with saved Data in LocalStorage
    .FormExcludeDataRebind {
    }
  }

  .ValidationRequired {
    .Form__Element__Caption:after {
      //default
      // content: ' (*)';
      //new
      content: ' * ';
    }
  }

  .TickIcon {
    fill: #d2d7e1;
    position: absolute;
    top: 50%;
  }

  .IconClose {
    display: none;
    cursor: pointer;
  }

  .Form__Element .Form__Element__Caption.filled.textInputLabel {
    top: 8px;
  }

  .Form__Element .Form__Element__Caption.textInputLabel {
    width: calc(100% - 40px);
  }

  /************* UTILITY CLASSES ***********/
  // Use serverside or clientside logic to add/remove this class to show/Hide element
  .hide {
    display: none;
  }

  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }

  /************* BUILDIN ELEMENTS ***********/
  /******************************************/
  .FormSubmitButton {
    //new
    font-family: KAFD, Arial, sans-serif;
    font-weight: 700;
    font-size: 0.625rem;
    color: #fff;
    display: inline-block;
    cursor: pointer;
    line-height: 22px;
    letter-spacing: 1.5px;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: #28334a;
    padding-block: 12px 10px;
    padding-inline: 24px;
    min-height: 32px;
    max-height: 40px;
    white-space: nowrap;
    transition: letter-spacing 0.75s cubic-bezier(0.16, 1, 0.3, 1);

    &:hover {
      letter-spacing: 3px !important;
    }

    @media (min-width: 1024px) {
      font-size: 1rem;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  .FormResetButton {
  }

  .FormTextbox {
    //new
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .FormTextbox__Input {
      //new
      width: 100%;
      border: none;
      background-color: #fff;
      height: 50px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      padding-block: 20px 0;
      padding-inline: 10px 75px;

      &:focus,
      &:focus-within {
        outline: none;
      }

      &[type='number'] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      &.textInput {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .FormTextbox--Textarea {
    .FormTextbox__Input {
      //new
      height: 150px;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      margin-block: 25px 0;
      padding-inline: 10px 75px;

      &.Whistleblower {
        width: calc(100% - 75px);
        height: 47px;
        resize: none;
        scrollbar-width: thin;
        padding-block: 0;
        margin-block-start: 40px;

        &__Others {
          margin-block-start: 25px;
          height: 75px;
        }
      }
    }

    .TickIcon,
    &:focus .IconCloseVisible,
    &:focus-within .IconCloseVisible {
      top: 20px;
    }

    .Form__Element__ValidationError {
      top: 102%;
    }
  }

  .FormTextbox--Number {
    .FormTextbox__Input {
    }
  }

  .FormHidden {
  }

  .FormFileUpload {
  }

  .FormCaptcha {
    .FormTextbox__Input {
      width: 8rem;
    }

    .FormCaptcha__Image {
    }

    .FormCaptcha__Refresh {
      font-size: 1em;
      width: auto;
      height: auto;
    }
  }

  .FormSelection {
    //new
    width: 100%;
    // border: none;
    background-color: #fff;
    height: 50px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;

    &:focus,
    &:focus-within {
      outline: none;
    }

    select {
      width: 100%;
      height: 50px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      border: none;
      padding-block: 10px;
      padding-inline: 10px 6px;

      &:hover,
      &:focus {
        outline: none;
      }

      option {
        font-size: 60px;
      }
    }
  }

  .FormChoice {
    padding-bottom: 50px;
    position: relative;
    min-height: 92px;
    width: 100% !important;

    .choiceValuePreview {
      padding-inline-start: 12px;
      margin-top: 4%;
      line-height: 1;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: #28334a;
      letter-spacing: normal;
      font-size: 1.125rem;
      font-weight: 700;
      z-index: 1000;
    }

    .select-display .selectList {
      top: 90px;
    }

    .select-display.open ul {
      padding: 40px 24px;
      overflow: hidden;

      li label span {
        color: white;
      }
    }

    .selectList li:last-child .custom-checkbox {
      margin-bottom: 0;
    }

    .custom-checkbox {
      display: block;
      position: relative;
      padding-inline-start: 35px;
      margin-bottom: 23px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 1.125rem;
      line-height: 1.25rem;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
          background-image: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
          background-color: #46526b;
          border-width: 0;

          &:after {
            content: '';
            position: absolute;
            display: block;
            inset-inline-start: 6px;
            left: 6px;
            top: 3px;
            width: 5px;
            height: 9px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 17px;
        inset-inline-start: 0;
        width: 17px;
        border: 1px solid hsla(0, 0%, 100%, 0.21);
      }
    }

    &-Reveal-Identity-Title {
      margin-bottom: 2rem;
      font-size: 1rem;
      margin-top: 40px;
      @media (min-width: 1024px) {
        margin-top: 56px;
      }
    }

    &-Reveal-Identity-Checkboxes {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .FormChoice__Title {
      padding-top: 10px;
      margin-bottom: 20px;
      font-size: 0.875rem;
    }

    .FormChoice__Item {
      position: relative;
      display: flex;
      margin-bottom: 15px;

      .FormChoice__Input {
      }

      input[type='checkbox'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      input[type='checkbox']:checked ~ .FormChoice__Checkmark:after {
        display: block;
      }

      input[type='radio'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      input[type='radio']:checked ~ .FormChoice__Dotmark:after {
        transform: scale(1);
      }

      .FormChoice__Dotmark {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 999%;
        height: 12px;
        width: 12px;
        border: 1px solid #28334a;

        &::after {
          content: '';
          position: absolute;
          transform: scale(0);
          top: 1px;
          left: 1px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #28334a;
          transition: all 0.3s ease 0s;
        }
      }

      .FormChoice__Checkmark {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        height: 16px;
        width: 16px;
        border: 1px solid #d2d7e1;
        border-width: 0 1px 1px 0;

        &::after {
          content: '';
          position: absolute;
          display: none;
          left: 6px;
          top: 2px;
          width: 5px;
          height: 9px;
          border: solid #28334a;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      .FormChoice__Checkmark-Text {
        font-size: 0.75rem;
        padding-inline-start: 26px;
      }
    }

    .FormChoice__Input--Radio {
    }

    .FormChoice__Label {
      display: inline-block;
      font-size: 0.75rem;
      padding-inline-start: 26px;
    }
  }

  .FormChoice--Image {
    .FormChoice--Image__Item {
      /* for image choice items, display as a flow (inline-block), we want max 2 items in one row, with a small margin */
      display: inline-block;
      max-width: 40%;
      text-align: center; /* image caption will be center */
      margin: 0.5rem;

      .FormChoice--Image__Item__Caption {
      }
    }
    /*img stand next to checked image will be bordered*/
    .FormChoice__Input:checked ~ img {
      border: 1px solid $active-color;
    }
  }

  // Range element
  .FormRange {
    .FormRange__Wrapper {
      display: inline-block;
      width: 80%;

      .FormRange__Slider__Wrapper {
        display: flex;
      }

      // range input type element
      .FormRange__Input {
        display: inline;
        flex: 1;
      }

      //increase decrease button
      .FormRange__Slider__Button {
        font-size: 14px;
        border: none;
        cursor: pointer;
        background-color: #0a95c9;
        color: white;
        margin: 10px;
        font-weight: bold;
        flex: 0.1;
      }

      .FormRange__Slider__Button__Disable {
        background-color: #a4a4a4;
        cursor: default;
      }

      // min, max label elements
      .FormRange__Min {
        flex: 0.1;
        line-height: 54px;
        text-align: center;
      }

      .FormRange__Max {
        flex: 0.1;
        line-height: 54px;
        text-align: center;
      }

      //output element
      .FormRange__Output__Wrapper {
        position: relative;
        display: block;
        text-align: center;

        .FormRange__Output {
        }
      }
    }
  }

  /*************** EDIT VIEW ****************/
  /******************************************/
  // Form__Element which is invisible in ViewMode will have this CSS class in EditView (E.g.: FormStep and FormHidden should have this class)
  .EditView__InvisibleElement {
    &.FormStep {
      display: block;
      border-radius: $border-radius;
      border-color: $invisibleelement-color;
      background-color: $invisibleelement-color;
      color: #000;
      padding: 0.2rem;
      font-family: Verdana;
      box-shadow: 3px 3px 5px #ccc;
      /*text-align: center;*/

      .FormStep__Warning {
        .Warning {
          color: $warning-foreground-color;
          background-color: $warning-background-color;
          display: block;
          word-wrap: break-word;
        }
      }
    }

    &.FormHidden {
      display: inline-block;
      border-radius: $border-radius;
      border: 1.5px outset $invisibleelement-color;
      padding: 0.2rem 1rem;
      min-width: 18rem;
      /*text-align: center;*/
    }
  }

  .Form__Warning {
    .Warning {
      color: $warning-foreground-color;
      background-color: $warning-background-color;
      display: block;
      word-wrap: break-word;
    }
  }

  .Form__Original__ParagraphText {
    display: none;
  }
}

[dir='ltr'] {
  .EPiServerForms {
    .Form__Element {
      // box-shadow: 1px 1px 0 0 #e8ebf0;
      border-right: 1px solid;
      border-color: #e8ebf0;

      // &:focus,
      // &:focus-within {
      //   // box-shadow: 1px 1px 0 0 #000000;
      //   &.FormSelection {
      //     box-shadow: 1px 1px 0 0 #e8ebf0;
      //   }
      // }

      &:focus .IconCloseVisible,
      &:focus-within .IconCloseVisible {
        transform: translate(-100%, -50%);
        right: 0;
      }

      .Form__Element__Caption {
        left: 10px;
      }

      .Form__Element__ValidationError {
        left: 10px;
      }

      input[type='tel'] {
        direction: ltr;
      }
    }

    .TickIcon {
      transform: translate(-100%, -50%);
      right: 0;
    }
  }

  .flatpickr-wrapper {
    .form-control {
      .Form__Element:focus &.active,
      .Form__Element:focus-within &.active {
        box-shadow: 1px 1px 0 0 #e8ebf0;
      }
    }

    & ~ .iconChevron {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-100%, -50%);
    }

    &:has(.open) ~ .iconChevron {
      fill: #fff;
      transform: translate(-100%, -50%) rotate(180deg);
    }
  }

  .PhoneElementBlock {
    .select-display {
      &.codes {
        box-shadow: 1px 1px 0 0 #e8ebf0;
      }
    }
  }

  .select-display {
    .iconChevron {
      right: 0;
      transform: translate(-100%, -50%);
    }

    &.open {
      .iconChevron {
        fill: #fff;
        transform: translate(-100%, -50%) rotate(180deg);
      }
    }

    .selectListSelectedValue {
      padding-inline-start: 10px;
    }
  }
}

[dir='rtl'] {
  .EPiServerForms {
    .Form__Element {
      border-left: 1px solid;
      border-color: #e8ebf0;

      // &:focus,
      // &:focus-within {
      //   box-shadow: -1px 1px 0 0 #000000;
      //   &.FormSelection {
      //     box-shadow: -1px 1px 0 0 #e8ebf0;
      //   }
      // }

      &:focus .IconCloseVisible,
      &:focus-within .IconCloseVisible {
        left: 0;
        transform: translate(100%, -50%);
      }

      .Form__Element__Caption {
        right: 10px;
      }
    }

    .Form__Element__ValidationError {
      right: 10px;
    }

    input[type='tel'] {
      direction: rtl;
    }

    .TickIcon {
      transform: translate(100%, -50%);
      left: 0;
    }
  }

  .flatpickr-wrapper {
    .form-control {
      .Form__Element:focus &.active,
      .Form__Element:focus-within &.active {
        box-shadow: -1px 1px 0 0 #e8ebf0;
      }
    }

    & ~ .iconChevron {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(100%, -50%);
    }

    &:has(.open) ~ .iconChevron {
      fill: #fff;
      transform: translate(100%, -50%) rotate(180deg);
    }
  }

  .PhoneElementBlock {
    .select-display {
      &.codes {
        box-shadow: -1px 1px 0 0 #e8ebf0;
      }
    }
  }

  .select-display {
    .iconChevron {
      left: 0;
      transform: translate(100%, -50%);
    }

    &.open {
      .iconChevron {
        fill: #fff;
        transform: translate(100%, -50%) rotate(180deg);
      }
    }

    .selectListSelectedValue {
      padding-inline-start: 0;
      padding-inline-end: 10px;
      direction: ltr;
      text-align: end;
    }
  }
}

.select-display {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.codes {
    position: relative;
    height: 50px;
    width: 100px;

    .selectListSelectedValue {
      width: 100px;
      padding: 0;
    }
  }

  &.open {
    background-color: #28334a;

    .selectListSelectedValue {
      color: #fff;
    }

    ul {
      display: flex;
    }
  }

  .iconChevron {
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  .selectListSelectedValue {
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    display: block;
    // padding-inline-start: 10px;
    padding-block: 12px 0;
  }

  .selectList {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    left: 0;
    background-color: #28334a;
    color: #fff;
    width: 100%;
    height: fit-content;
    display: none;
    z-index: 11;
    max-height: 250px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: hsla(0, 0%, 100%, 0.85) #28334a;
    padding-inline-start: 10px;

    .selectOption {
      display: inline-block;
      width: 100%;
      padding-block-start: 10px;
      padding-block-end: 6px;
      padding-inline-start: 10px;
      color: #fff;
      background-color: #28334a;

      &.selected {
        background-color: #fff3;
      }

      &:hover {
        background-color: #fff3;
        cursor: pointer;
      }
    }
  }
}

//TODO DatePicker

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: none;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: theme('colors.bluewood');
  // -webkit-box-shadow:
  //   1px 0 0 #20222c,
  //   -1px 0 0 #20222c,
  //   0 1px 0 #20222c,
  //   0 -1px 0 #20222c,
  //   0 3px 13px rgba(0, 0, 0, 0.08);
  // box-shadow:
  //   1px 0 0 #20222c,
  //   -1px 0 0 #20222c,
  //   0 1px 0 #20222c,
  //   0 -1px 0 #20222c,
  //   0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% - 1px);
  padding-block-start: 10px;
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 1) .flatpickr-day.inRange:nth-child(7n + 7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n + 2) .flatpickr-day.inRange:nth-child(7n + 1) {
  -webkit-box-shadow:
    -2px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
  box-shadow:
    -2px 0 0 #e6e6e6,
    5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #20222c;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
// .flatpickr-calendar.arrowTop:before,
// .flatpickr-calendar.arrowTop:after {
//   bottom: 100%;
// }
// .flatpickr-calendar.arrowTop:before {
//   border-bottom-color: #20222c;
// }
// .flatpickr-calendar.arrowTop:after {
//   border-bottom-color: #3f4458;
// }
// .flatpickr-calendar.arrowBottom:before,
// .flatpickr-calendar.arrowBottom:after {
//   top: 100%;
// }
// .flatpickr-calendar.arrowBottom:before {
//   border-top-color: #20222c;
// }
// .flatpickr-calendar.arrowBottom:after {
//   border-top-color: #3f4458;
// }
.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 100%;

  .form-control {
    position: relative;
    width: 100%;
    height: 100%;
    padding-block-start: 14px;
    padding-inline-start: 10px;
    border: none;
    outline: none;

    .Form__Element:focus &.active,
    .Form__Element:focus-within &.active {
      box-shadow: 1px 1px 0 0 #e8ebf0;
      color: #fff;
      background-color: #28334a;
    }
  }
}

.Form__Element:focus .Form__Element__Caption:has(~ .flatpickr-wrapper > .active),
.Form__Element:focus-within .Form__Element__Caption:has(~ .flatpickr-wrapper > .active) {
  top: 6px;
  color: #cbd5e0;
}

.Form__Element__Caption:has(~ .select-display.open),
.Form__Element:focus .Form__Element__Caption:has(~ .select-display.open),
.Form__Element:focus-within .Form__Element__Caption:has(~ .select-display.open) {
  top: 6px;
  color: #fff;
}

// Toggling `open` class in `ChoiceElementBlock`
.Form__Element__Caption.open,
.Form__Element:focus .Form__Element__Caption.open,
.Form__Element:focus-within .Form__Element__Caption.open {
  top: 6px;
  color: #fff;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  max-width: 300px;
  margin-inline: auto;
}

.flatpickr-months .flatpickr-month {
  background: theme('colors.bluewood');
  color: #cbd5e0;
  fill: #fff;
  // height: 34px;
  line-height: 1;
  text-align: center;
  // position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #fff;
  fill: #fff;
}

.flatpickr-months .flatpickr-prev-month {
}

.flatpickr-months .flatpickr-next-month {
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  opacity: 0.25;
  fill: currentColor;
  color: #cbd5e0;
  pointer-events: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  /*
        /*rtl:end:ignore*/
  /*
        */
}
/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  /*
        /*rtl:end:ignore*/
  /*
        */
}
/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #eee;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  // fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 12px;
  height: 11px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding-block: 0;
  padding-inline: 2px 4px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(192, 187, 167, 0.1);
}

.numInputWrapper span:active {
  background: rgba(192, 187, 167, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(255, 255, 255, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(255, 255, 255, 0.5);
}

.numInputWrapper:hover {
  background: rgba(192, 187, 167, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  // position: absolute;
  // width: 75%;
  // left: 12.5%;
  // padding: 0;
  // padding-block-start: 7.48px;
  line-height: 1;
  // height: 34px;
  display: flex;
  justify-content: center;
  // -webkit-transform: translate3d(0px, 0px, 0px);
  // transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-inline-end: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(192, 187, 167, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0;
  padding-inline-start: 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: theme('colors.bluewood');
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: 0;
  margin-block-start: -1px;
  outline: none;
  padding: 0;
  padding-inline-start: 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none;
  text-align: center;
}

div.numInputWrapper {
  pointer-events: none;

  .arrowUp,
  .arrowDown {
    display: none;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(192, 187, 167, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #3f4458;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  align-items: center;
}

[dir='rtl'] {
  .flatpickr-months {
    flex-direction: row-reverse;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    transform: rotate(180deg);
  }

  .flatpickr-current-month {
    flex-direction: row-reverse;
  }

  .dayContainer {
    flex-direction: row-reverse;
  }

  .flatpickr-weekdays .flatpickr-weekdaycontainer {
    flex-direction: row-reverse;
  }
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: theme('colors.bluewood');
  color: #fff;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: inline-block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
  padding-inline-start: 10px;
  visibility: hidden;

  &::first-letter {
    visibility: visible;
  }
}

.dayContainer,
.flatpickr-weeks {
  padding: 0;
  padding-block-start: 1px;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #20222c;
  box-shadow: -1px 0 0 #20222c;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #646c8c;
  border-color: #646c8c;
}
// .flatpickr-day.today {
//   border-color: #eee;
// }
// .flatpickr-day.today:hover,
// .flatpickr-day.today:focus {
//   border-color: #eee;
//   background: #eee;
//   color: #3f4458;
// }
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #80cbc4;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border: 2px solid #6b7c97;
  background-image: linear-gradient(41deg, #28334a -1%, #28334a 102%);
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #80cbc4;
  box-shadow: -10px 0 0 #80cbc4;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow:
    -5px 0 0 #646c8c,
    5px 0 0 #646c8c;
  box-shadow:
    -5px 0 0 #646c8c,
    5px 0 0 #646c8c;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(255, 255, 255, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(255, 255, 255, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow:
    -5px 0 0 #80cbc4,
    5px 0 0 #80cbc4;
  box-shadow:
    -5px 0 0 #80cbc4,
    5px 0 0 #80cbc4;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-block-start: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding-block: 0;
  padding-inline: 12px;
  -webkit-box-shadow: 1px 0 0 #20222c;
  box-shadow: 1px 0 0 #20222c;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(255, 255, 255, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  // display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 300px;
  margin-block: 20px 0;
  margin-inline: auto;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 56px;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: '';
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(255, 255, 255, 0.95);
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(255, 255, 255, 0.95);
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: rgba(255, 255, 255, 0.95);
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: rgba(255, 255, 255, 0.95);
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #6a7395;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.PhoneElementBlock {
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;

  .Form__Element {
    width: 100%;
  }

  .select-display {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-block-start: 24px;
    padding-block-end: 8px;

    // padding-inline-end: 75px;
    padding-inline-start: 10px;
    // padding-inline-end: 45px;

    &.codes {
      position: static;
      height: 50px;
      width: 125px;
    }

    .selectListWrapper {
      display: none;
    }

    .selectListSearch {
    }

    &.open {
      background-color: #28334a;

      .selectListSelectedValue {
        color: #fff;
      }

      .selectListWrapper {
        width: 100%;
        height: 50px;
        position: absolute;
        top: 50px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #28334a;
        z-index: 15;
      }

      .selectListSearch {
        display: block;
        color: #fff;
        background-color: #28334a;
        width: 97%;
        outline: none;
        border: 1px solid #555e70;
        padding: 0.5rem 1rem;
      }

      ul {
        display: flex;
      }

      .iconChevron {
        fill: #fff;
      }
    }

    .iconChevron {
      position: absolute;
      top: 50%;
    }

    .selectListSelectedValue {
      border: none;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      display: block;
    }

    .selectList {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 100px;
      left: 0;
      background-color: #28334a;
      color: #fff;
      width: 100%;
      height: fit-content;
      display: none;
      z-index: 15;

      .selectOption {
        display: inline-block;
        width: 100%;
        padding-block: 10px 6px;
        padding-inline-start: 10px;
        color: #fff;
        background-color: #28334a;

        &.selected {
          background-color: #fff3;
        }

        &:hover {
          background-color: #fff3;
          cursor: pointer;
        }
      }

      .noResults {
        display: inline-block;
        width: 100%;
        height: 50px;
        padding-block: 10px 6px;
        padding-inline-start: 10px;
        color: #fff;
        background-color: #28334a;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .Form__Element__Caption {
    font-size: 0.625rem;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: absolute;
    top: 19px;
    transition: all 0.25s ease-in-out;
    z-index: 10;

    &.filled {
      top: 8px;
    }
  }

  &:focus .Form__Element__Caption.filled,
  &:focus-within .Form__Element__Caption.filled {
    color: #fff;
  }

  &:has(.select-display.codes) {
    &:focus .Form__Element__Caption.filled,
    &:focus-within .Form__Element__Caption.filled {
      color: #28334a;
    }
  }
}

.EPiServerForms {
  .Form__Element {
    &:focus,
    &:focus-within {
      border-color: #000;

      &.FormSelection {
        border-color: #e8ebf0;
      }
    }
  }

  .Form__Element.ValidationFail {
    color: #e9322d;
    border-bottom: 1px solid;
    border-color: #e9322d;

    input,
    textarea,
    select {
      border-color: #e9322d;
    }

    label,
    input {
      color: #28334a;
    }

    legend {
      color: #e9322d;
    }
  }
}

.success {
  color: #008000;
}

.error {
  color: #e9322d;
}

.country-code {
  width: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select-display {
  &.codes.open {
    .country-code {
      color: #fff !important;
    }
  }
}
