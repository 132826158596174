@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate.scss';

.EventList {
  @apply w-full relative;

  opacity: 0;

  ul {
    list-style: none;
  }
}
