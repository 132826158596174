@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.listItem {
  line-height: inherit;
  font-size: inherit;
}

:global(.aboutPage) {
  .listItem {
    max-width: 920px;
  }
}
