@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.bgWhite {
  background: #fff;
}

.bgDark {
  background: #28334a;
}

.noResultsContainer {
  padding-top: 2.5rem !important;
  color: #28334a;

  & :global(.img-holder) {
    background: linear-gradient(40deg, #28334a -2%, #28334a 104%),
      linear-gradient(180deg, rgba(40, 51, 74, 0.75), rgba(40, 51, 74, 0.75));
  }
}

.noResults {
  margin-bottom: 6.25rem;
  min-height: 32px;
  letter-spacing: 1.5px;
  background-color: #f1f2f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.875rem;
  text-align: center;
  line-height: 22px;
  font-weight: 700;
  font-size: 1rem;
  width: 100%;
  color: #28334a;
}

.boxTop {
  .mailSection {
    .mailBlock {
      width: 127px;
      height: 127px;

      @media (min-width: 1024px) {
        width: 155px;
        height: 155px;
      }

      @media (min-width: 1280px) {
        width: 215px;
        height: 215px;
      }

      .patt-1 {
        width: 67px;
        height: 67px;
        position: absolute;
        bottom: -52px;
        left: -65px;

        @media (min-width: 1024px) {
          bottom: -52px;
          left: -30px;
          width: 89px;
          height: 89px;
        }

        @media (min-width: 1280px) {
          width: 120px;
          height: 120px;
        }
      }

      .patt-2 {
        position: absolute;
        background-color: rgba(127, 141, 171, 0.35);
        width: 45px;
        height: 45px;
        bottom: -26px;
        right: -6px;

        @media (min-width: 1024px) {
          bottom: -141px;
          right: 23px;
        }

        @media (min-width: 1280px) {
          bottom: -185px;
        }

        @media (max-width: 1023px) {
          background-color: transparent;
        }

        @media (min-width: 1280px) {
          width: 70px;
          height: 70px;
        }

        svg {
          position: absolute;
          width: 59px;
          height: 59px;
          @media (min-width: 1024px) {
            width: 45px;
            height: 45px;
            top: -16px;
            left: 13px;
          }

          @media (min-width: 1280px) {
            width: 70px;
            height: 70px;
            top: -28px;
            left: 23px;
          }
        }
      }

      .mailIcon {
        top: calc(50% - 62px / 2);
        left: calc(50% - 62px / 2);
        fill: #fff;
        width: 62px;
        height: 62px;

        @media (max-width: 1023px) {
          width: 37px;
          height: 37px;
          top: calc(50% - 37px / 2);
          left: calc(50% - 37px / 2);
        }
      }
    }

    .mailText {
      left: 147px;
      top: 0;

      @media (min-width: 1024px) {
        top: 10%;
        transform: translate(0px, 50%);
        left: 180px;
      }

      @media (min-width: 1280px) {
        left: 250px;
        width: 280px;
      }

      .boxText {
        font-size: 0.875rem;
        line-height: 16px;
        letter-spacing: 1px;
        @media (max-width: 1024px) {
          font-size: 12px;
        }

        @media (min-width: 1024px) {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.boxBottom {
  .phoneSection {
    margin-left: auto;

    .phoneBlock {
      margin-left: auto;

      @media (min-width: 1024px) {
        margin-top: 60px;
        width: 185px;
        height: 226px;
      }

      @media (min-width: 1280px) {
        margin-top: -10px;
        width: 245px;
        height: 300px;
      }

      .patt-1 {
        width: 67px;
        height: 67px;
        position: absolute;
        left: 47px;
        bottom: -36px;

        svg {
          width: 67px;
          height: 67px;
        }

        @media (min-width: 1024px) {
          width: 89px;
          height: 89px;
          left: -62px;
          top: -28px;

          svg {
            width: 89px;
            height: 89px;
          }
        }

        @media (min-width: 1280px) {
          width: 120px;
          height: 120px;
          left: -92px;
          top: -28px;

          svg {
            width: 120px;
            height: 120px;
          }
        }
      }

      .patt-2 {
        position: absolute;
        width: 70px;
        height: 70px;
        top: -120px;
        right: -45px;
        background: rgba(127, 141, 171, 0.35);

        @media (min-width: 1280px) {
          width: 95px;
          height: 95px;
          top: -140px;
          right: -75px;
        }

        svg {
          position: absolute;
          width: 50px;
          height: 50px;
          top: 40px;
          left: 40px;
          @media (min-width: 1280px) {
            width: 75px;
            height: 75px;
            top: 56px;
            left: 47px;
          }
        }

        @media (max-width: 1023px) {
          display: none;
        }
      }

      .phoneIcon {
        width: 37px;
        height: 77px;
        top: calc(50% - 77px / 2);
        left: calc(50% - 37px / 2);
        fill: #fff;

        svg {
          width: 37px;
          height: 77px;
        }

        @media (min-width: 1024px) {
          top: calc(50% - 62px / 2);
          left: calc(50% - 62px / 2);
          width: 62px;
          height: 77px;

          svg {
            width: 62px;
            height: 77px;
          }
        }
      }
    }

    .phoneText {
      right: 167px;
      z-index: 10;
      width: 163px;
      @media (min-width: 1024px) {
        right: 210px;
        width: 190px;
      }

      @media (min-width: 1280px) {
        right: 270px;
      }

      .boxText {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;

        @media (min-width: 1024px) {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

.link {
  position: relative;
  display: inline-block;
  letter-spacing: 1.5px;
  line-height: 26px;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  &:after {
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    height: 2px;
    background-color: #fff;
    width: 100%;
    backface-visibility: hidden;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform 0.5s ease;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: 0 50%;
  }
}

//ARABIC

[dir='rtl'] {
  //Phone Section
  .boxBottom {
    .phoneSection {
      margin-left: 0;
      margin-right: auto;

      .phoneBlock {
        margin-left: 0;
        margin-right: auto;

        .patt-1 {
          right: 47px;
          bottom: -36px;

          @media (min-width: 1024px) {
            right: -62px;
            top: -28px;
            bottom: auto;
          }

          @media (min-width: 1280px) {
            width: 119px;
            height: 119px;
            right: -92px;
            top: -28px;
          }
        }

        .patt-2 {
          left: -65px;

          svg {
            right: 47px;
          }

          @media (min-width: 1024px) {
            top: -110px;
            left: -40px;
            right: auto;
          }

          @media (min-width: 1280px) {
            top: -144px;
            left: -65px;
          }

          @media (max-width: 1023px) {
            display: none;
          }
        }
      }

      .phoneText {
        width: 200px;
        z-index: 10;
        text-align: left;
        right: auto;
        left: 167px;
        @media (min-width: 1024px) {
          left: 205px;
        }

        @media (min-width: 1280px) {
          left: 275px;
        }

        .boxText {
          letter-spacing: 0;
        }

        .link {
          letter-spacing: 0;
        }
      }
    }
  }

  .link {
    &:after {
      transform-origin: 0 50%;
    }

    &:hover:after {
      transform-origin: 100% 50%;
    }
  }

  //Mail Section
  .boxTop {
    .mailSection {
      .mailBlock {
        .patt-1 {
          bottom: -52px;
          right: -65px;
          overflow: hidden;
          width: 62px;
          height: 62px;

          @media (min-width: 1024px) {
            width: 92px;
            height: 92px;
            bottom: -52px;
            right: -30px;
          }

          @media (min-width: 1280px) {
            width: 122px;
            height: 122px;
          }
        }

        .patt-2 {
          bottom: -42px;
          left: -17px;
          right: auto;
          width: 59px;
          height: 59px;

          @media (min-width: 1024px) {
            width: 45px;
            height: 45px;
            bottom: -141px;
            left: 23px;

            svg {
              width: 45px;
              height: 45px;
              top: -16px;
              right: 13px;
            }
          }

          @media (min-width: 1280px) {
            width: 70px;
            height: 70px;
            bottom: -204px;
            left: 23px;

            svg {
              width: 70px;
              height: 70px;
              top: -28px;
              right: 23px;
            }
          }
        }
      }

      .mailText {
        right: 150px;
        width: 190px;

        @media (min-width: 1024px) {
          width: calc(100% - 275px);
          right: 175px;
        }

        @media (min-width: 1280px) {
          right: 245px;
        }

        .boxText {
          letter-spacing: 0;
        }

        .link {
          letter-spacing: 0;
        }
      }
    }
  }
}
