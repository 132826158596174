// columns
@media (max-width: 1023px) {
  .col-sm-12 {
    @apply w-full;
  }

  .col-sm-11 {
    @apply w-11/12;
  }

  .col-sm-10 {
    @apply w-10/12;
  }

  .col-sm-9 {
    @apply w-9/12;
  }

  .col-sm-8 {
    @apply w-8/12;
  }

  .col-sm-7 {
    @apply w-7/12;
  }

  .col-sm-6 {
    @apply w-6/12;
  }

  .col-sm-5 {
    @apply w-5/12;
  }

  .col-sm-4 {
    @apply w-4/12;
  }

  .col-sm-3 {
    @apply w-3/12;
  }

  .col-sm-2 {
    @apply w-2/12;
  }

  .col-sm-1 {
    @apply w-1/12;
  }

  .offset-sm-12 {
    margin-left: 100%;
  }

  .offset-sm-11 {
    margin-left: 91.666667%;
  }

  .offset-sm-10 {
    margin-left: 83.333333%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-8 {
    margin-left: 66.666667%;
  }

  .offset-sm-7 {
    margin-left: 58.333333%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-5 {
    margin-left: 41.666667%;
  }

  .offset-sm-4 {
    margin-left: 33.333333%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-2 {
    margin-left: 16.666667%;
  }

  .offset-sm-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .col-md-12 {
    @apply w-full;
  }

  .col-md-11 {
    @apply w-11/12;
  }

  .col-md-10 {
    @apply w-10/12;
  }

  .col-md-9 {
    @apply w-9/12;
  }

  .col-md-8 {
    @apply w-8/12;
  }

  .col-md-7 {
    @apply w-7/12;
  }

  .col-md-6 {
    @apply w-6/12;
  }

  .col-md-5 {
    @apply w-5/12;
  }

  .col-md-4 {
    @apply w-4/12;
  }

  .col-md-3 {
    @apply w-3/12;
  }

  .col-md-2 {
    @apply w-2/12;
  }

  .col-md-1 {
    @apply w-1/12;
  }

  .offset-md-12 {
    margin-left: 100%;
  }

  .offset-md-11 {
    margin-left: 91.666667%;
  }

  .offset-md-10 {
    margin-left: 83.333333%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-8 {
    margin-left: 66.666667%;
  }

  .offset-md-7 {
    margin-left: 58.333333%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-5 {
    margin-left: 41.666667%;
  }

  .offset-md-4 {
    margin-left: 33.333333%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-2 {
    margin-left: 16.666667%;
  }

  .offset-md-1 {
    margin-left: 8.333333%;
  }
}

@media (min-width: 1280px) {
  .col-12 {
    @apply w-full;
  }

  .col-11 {
    @apply w-11/12;
  }

  .col-10 {
    @apply w-10/12;
  }

  .col-9 {
    @apply w-9/12;
  }

  .col-8 {
    @apply w-8/12;
  }

  .col-7 {
    @apply w-7/12;
  }

  .col-6 {
    @apply w-6/12;
  }

  .col-5 {
    @apply w-5/12;
  }

  .col-4 {
    @apply w-4/12;
  }

  .col-3 {
    @apply w-3/12;
  }

  .col-2 {
    @apply w-2/12;
  }

  .col-1 {
    @apply w-1/12;
  }

  .offset-12 {
    margin-left: 100%;
  }

  .offset-11 {
    margin-left: 91.666667%;
  }

  .offset-10 {
    margin-left: 83.333333%;
  }

  .offset-9 {
    margin-left: 75%;
  }

  .offset-8 {
    margin-left: 66.666667%;
  }

  .offset-7 {
    margin-left: 58.333333%;
  }

  .offset-6 {
    margin-left: 50%;
  }

  .offset-5 {
    margin-left: 41.666667%;
  }

  .offset-4 {
    margin-left: 33.333333%;
  }

  .offset-3 {
    margin-left: 25%;
  }

  .offset-2 {
    margin-left: 16.666667%;
  }

  .offset-1 {
    margin-left: 8.333333%;
  }
}

.contenteditor {
  @extend .rich-text-editor;

  padding: 30px 0;
  margin-top: -30px;
}

[data-animation='on'] {
  .col-layout {
    .contenteditor {
      opacity: 0;
    }

    .animate-section {
      .contenteditor {
        animation: 0.75s motif-box-animation ease-in-out 1s both;
      }
    }
  }
}
