[data-anim='on'] {
  &.news__section {
    opacity: 1;

    .news__date {
      animation: motif-box-animation 0.75s ease-in-out 0.75s both;
    }

    .news__content {
      animation: motif-box-animation 0.75s ease-in-out 0.75s both;
    }

    .news__img:after {
      animation: carouselImg1Next 0.75s ease-in-out 0.75s both;
    }
  }
}
