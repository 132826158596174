// rich text editor style
.rich-text-editor {
  line-height: 1.77;
  @apply text-13;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    word-break: break-word;
    @apply font-kafd mb-4 font-light;
  }

  .heading-text-1,
  h1 {
    word-break: break-word;
    margin-bottom: 1rem;
    font-size: 18px;
    line-height: 38px#{'/*rtl:' 40px '*/'};
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      font-size: 56px;
      line-height: 64px#{'/*rtl:' 66px '*/'};
      letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
    }
  }

  .heading-text-2,
  h2 {
    @apply text-30;

    line-height: 36px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      font-size: 42px;
      line-height: 50px;
      letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
    }
  }

  .heading-text-3,
  h3 {
    word-break: normal;
    font-size: 1.875rem;
    line-height: 28px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};

    @screen lg {
      margin-top: 30px;
      font-size: 42px;
      line-height: 50px;
      letter-spacing: 4px;
    }
  }

  .heading-text-4,
  h4 {
    @apply text-18;

    line-height: 22px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      @apply text-24;

      line-height: 32px;
      letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
    }
  }

  .heading-text-5,
  h5 {
    @apply text-16;

    line-height: 18px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      @apply text-18;

      line-height: 22px;
      letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
    }
  }

  .heading-text-6,
  h6 {
    @apply text-14;

    line-height: 16px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      line-height: 20px;
      @apply text-16;
    }
  }

  p:not(:last-child) {
    @apply mb-4;
  }

  // BODY TEXT
  .body-text-3,
  p {
    @apply font-kafd font-bold text-16;

    line-height: 22px;
    letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};

    @screen lg {
      // margin-left: 8.333333%;
      font-size: 1.125rem;
      line-height: 26px;
    }

    @screen xl {
      line-height: 1.56;
      margin-left: 0;
    }
  }

  .body-text-1 {
    @apply font-kafd font-bold text-18;

    line-height: 26px;
    letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      @apply text-24;

      line-height: 36px;
      letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
    }
  }

  .body-text-2 {
    @apply font-kafd font-bold text-16;

    line-height: 22px;
    letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
    @screen lg {
      @apply text-18;

      line-height: 26px;
    }
  }

  .body-text-4 {
    @apply font-kafd font-bold text-14;

    line-height: 22px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
  }

  //caption text
  .caption-text {
    @apply font-kafd font-bold text-12;

    line-height: 16px;
    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
  }

  //caption text
  .overline-text {
    @apply font-kafd font-bold text-10;

    line-height: 12px;
    letter-spacing: 2px#{'/*rtl:' $rtl-space '*/'};
  }

  ol {
    margin-left: 40px;
    list-style-position: inside;
  }

  ul {
    margin-left: 40px;

    li {
      // @apply mb-5;
      // &:before {
      //   content: '';
      //   left: -23px;
      //   top: 10px;
      //   border-radius: 100px;
      //   width: 3px;
      //   height: 3px;
      //   @apply absolute bg-bluewood;
      // }
      @apply relative;
    }
  }
}

[dir='rtl'] {
  ol {
    margin-right: 40px;
  }

  ul {
    margin-right: 40px;
  }
}

//typography
.typography {
  margin-top: -6rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    white-space: nowrap;
  }

  p {
    margin-bottom: 0 !important;
  }
}

.faq {
  letter-spacing: 1px;

  h1 {
    font-size: 2.25rem;
    line-height: 38px;
    @screen lg {
      font-size: 56px;
      line-height: 64px;
      letter-spacing: 4px;
    }
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    @screen lg {
      font-size: 32px;
      line-height: 40px;
    }
  }

  div[class*='Accordion'] {
    p {
      font-size: 0.875rem;
    }

    button > span {
      font-size: 1rem;
      line-height: 1.375rem;
      letter-spacing: 1.5px;
      @screen lg {
        font-size: 1.125rem;
        line-height: 1.625rem;
      }
    }
  }
}

//app landing page
.kafdapp-page {
  p:has(a:nth-child(1), a:nth-child(2)) {
    /* Style p elements with exactly two a elements */
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}

.eventsDetailPage {
  p:has(.GenericButton_btn__primary-1__7Eqrf) {
    text-align: inherit;
  }
}
