[data-anim='on'] {
  .flist,
  .footerTopContactUs,
  .first-bottom > *,
  .last-bottom > * {
    &:after {
      transform: translate3d(101%, 0, 0);
    }
  }
}

[dir='rtl'] [data-anim='on'] .flist,
[dir='rtl'] [data-anim='on'] .footerTopContactUs,
[dir='rtl'] [data-anim='on'] .first-bottom > *,
[dir='rtl'] [data-anim='on'] .last-bottom > * {
  &:after {
    transform: translate3d(-101%, 0, 0);
  }
}
