@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.cookieWrapper {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  background: #28334a;
  width: 100%;
}

.cookieWrapperContent {
  display: flex;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 1px;
  padding: 1.5rem 35px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 58px 0 87px;
  color: #d2d7e1;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-width: initial;
    margin: 0;
    align-items: flex-start;
  }

  a {
    text-decoration: underline;
    transition: 0.2s ease-in;

    &:hover {
      color: #fff;
    }
  }
}

.cookieText {
  color: #d2d7e1;
}

.btnWrapper {
  display: flex;
  width: auto;
  gap: 1rem;

  @media (max-width: 1024px) {
    margin-top: 1rem;
    width: 100%;
  }
}

.btn {
  appearance: none;
  border: none;
  cursor: pointer;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 40px;
  width: 50%;
  transition: letter-spacing 0.75s cubic-bezier(0.16, 1, 0.3, 1);
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  font-size: 1rem;

  &:hover {
    letter-spacing: 3px;
  }
}

.btnWithBg {
  background-color: #d2d7e1;
  color: #28334a;
}

.btnWithBorders {
  background-color: #28334a;
  border: 1px solid #d2d7e1;
  color: #d2d7e1;
}
