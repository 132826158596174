@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate.scss';

// Overwrites for Media Centre page
:global(.mediaCenterPage) {
  .page__tabs {
    overflow: visible;
    @apply mt-8;

    @screen lg {
      margin-top: 8.5rem;
    }

    .tabs__header {
      .tab__anchor {
        text-transform: capitalize;
      }
    }
  }
}

.page__tabs {
  @apply mt-12 overflow-hidden;
  @screen lg {
    margin-top: 6rem;
  }

  .tabs__header {
    margin-bottom: 0.5rem;
    @apply flex overflow-y-scroll;

    column-gap: 1.5rem;

    @screen lg {
      margin-left: auto;
      overflow: visible;
      display: flex;
      flex-wrap: wrap;
      column-gap: 2.5rem;
    }

    /* Hide scrollbar */
    &::-webkit-scrollbar {
      display: none;
    } /* Chrome, Safari and Opera */

    scrollbar-width: none; /* Firefox */

    .tab__anchor {
      cursor: pointer;
      @apply relative font-kafd font-bold text-16 text-bluewood;

      white-space: nowrap;
      text-decoration: none;
      height: 32px;
      line-height: 22px;
      letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
      text-transform: uppercase;
      border: 0;

      &:active,
      &:focus {
        outline: none;
      }

      @screen lg {
        @apply mb-6;

        height: 32px;
      }

      &:last-child {
        @apply mr-0;
      }

      &:after {
        content: '';
        @apply absolute w-full bg-bluewood opacity-0;

        height: 4px;
        left: 0;
        bottom: 0;
        transition: all 0.265s ease-in-out;

        @screen lg {
          bottom: -3px;
        }
      }

      &.active {
        &:after {
          @apply opacity-100;
        }
      }

      @include on-hover {
        &:hover,
        &:focus {
          &:after {
            @apply opacity-100;
          }
        }
      }
    }
  }
}
