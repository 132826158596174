@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.Wrapper {
  @apply w-full h-full relative;
  @media screen and (max-width: 1024px) {
    height: 440px;
  }

  .VideoPlayer {
    @apply w-full h-full relative;

    box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);

    @screen xl {
      margin-top: 35px;
    }

    .video {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: theme('colors.darkblue');

      video {
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    .top {
      opacity: 1;
      z-index: 99;
    }

    .closeIcon {
      fill: white;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;

      @supports (-webkit-touch-callout: none) {
        @media screen and (max-width: 430px) {
          top: -5px;
          right: 20px;
        }
      }
    }

    .playerCoverWrapper {
      @apply w-full h-full absolute inset-0;

      background: theme('colors.darkblue');

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.25;
        width: 100%;
        height: 100%;
        z-index: 3;
        pointer-events: none;
        background-color: #28334a;
      }

      img {
        object-fit: cover;
        object-position: center top;
      }

      .playerButton {
        fill: white;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        background-color: #28334a;

        &.accentBgColor__purple {
          background: linear-gradient(39deg, #562e60 -2%, #e21368 103%);
        }

        &.accentBgColor__blue {
          background: linear-gradient(40deg, #00306b -1%, #00a99d 102%);
        }

        &.accentBgColor__red {
          background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
        }
      }
    }

    .hidden {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      border: 0;
      clip: rect(0 0 0 0);
      opacity: 0;
    }

    &:hover .playerButton {
      opacity: 1;
    }
  }

  .decoration {
    @media screen and (max-width: 1023px) {
      display: none;
    }

    display: block;
    width: 62px;
    height: 62px;
    fill: #28334a;

    @screen lg {
      width: 92px;
      height: 92px;
    }

    &:after {
      content: '';
      display: block;
      width: 62px;
      height: 62px;
      background-color: #cbd5e0;

      @screen lg {
        width: 92px;
        height: 92px;
      }
    }
  }
}

:global(.globalPage-default) .decoration {
  display: none;
}

:global(.globalPage-accelerate) .playerButton {
  background: linear-gradient(39deg, #00306b -2%, #00a99d 104%);
}

:global(.globalPage-live) .playerButton {
  background: linear-gradient(45deg, #d60b5b, #f79525);
}

:global(.globalPage-accelerate) .decoration {
  fill: #007dc0;

  &:after {
    background-color: #74c0b6;
  }
}

:global(.globalPage-accelerate) :global(.accelerate-office) .decoration {
  fill: #28334a;

  &:after {
    background-color: #cbd5e0;
  }
}

:global(.globalPage-live) .decoration {
  display: none;
}

[dir='ltr'] {
  .Wrapper {
    .decoration {
      position: relative;
      bottom: 0;
      left: 0;
      transform: translate(-50%, -50%);
      z-index: -1;

      &:after {
        position: relative;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform: translate(-50%, -50%);
      }
    }
  }
}

[dir='rtl'] {
  .Wrapper {
    .decoration {
      position: relative;
      bottom: 0;
      right: 0;
      transform: translate(50%, -50%);
      z-index: -1;

      &:after {
        position: relative;
        bottom: 0;
        right: 0;
        z-index: -1;
        transform: translate(50%, -50%);
      }
    }
  }
}
