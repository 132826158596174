@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.sidebarHolder {
  display: block;

  & > li {
    border-bottom: 1px solid rgba(127, 141, 171, 0.35);
    font-size: 0.8125rem;
    font-family: KAFD, Arial, sans-serif;
    color: #a0a8bb;
    justify-content: flex-start;
    height: auto;

    &:first-child {
      border-top: 1px solid rgba(127, 141, 171, 0.35);
    }

    button {
      appearance: none;
      border: none;
      padding: 1rem 0 1rem 1.5rem;

      &.activeCategory {
        color: #28334a;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.listOfFoundResults {
  @media (min-width: 1024px) {
    margin-inline-start: auto;
  }
}

.itemHolder {
  margin-top: 0.75rem;
  color: #28334a;
  transition: 0.2s ease-in-out;
  border-bottom: 1px solid rgba(127, 141, 171, 0.35);

  &:hover {
    border-bottom-color: transparent;
  }
}

.itemHolderLink {
  padding: 20px 30px 26px;
  margin: 0 -30px;
  transition: 0.2s ease-in-out;
  display: block;

  &:hover {
    box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.05);
  }
}

.segmentsList {
  ul > li {
    letter-spacing: 2px;
    display: inline-flex;
    font-size: 0.625rem;
    color: #a0a8bb;
    text-transform: uppercase;
  }
}

.statsHolder {
  font-size: 0.8125rem;
  font-weight: 700;
  color: #a0a8bb;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(127, 141, 171, 0.35);

  span {
    color: #28334a;
  }
}

.title {
  font-size: 1.25rem;
  margin-top: 0.25rem;
  font-weight: 700;
  line-height: 1.25;
}

.desc {
  line-height: 1.77;
  font-size: 0.8125rem;
  margin-top: 0.5rem;
}

.searchAccordion {
  margin-bottom: 0;

  button span:first-of-type {
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    display: none !important;
  }

  button {
    border: none;
    box-shadow: 1px 1px 0 0 #28334a;
  }
}

.searchCats {
  @media (max-width: 1023px) {
    display: none;
  }
}

.listing-load-more {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
  justify-content: left !important;
  @media (min-width: 1024px) {
    margin-left: 16.85%;
  }

  :global(.bwcta__btn__btn) {
    span {
      text-transform: initial;
      @media (max-width: 1023px) {
        font-weight: 700;
        font-size: 0.625rem;
      }
    }
  }
}

.highlightedTitle {
  background: #e8ebf0;
}
