@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animation';

.GalleryModal {
  @apply fixed left-0 top-0 w-full h-full flex items-center justify-center;

  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2.71828px);

  .modal-container {
    @apply m-auto;

    height: fit-content;
    width: fit-content;
  }

  .modal-close {
    @apply flex justify-end items-center text-14 bg-defaultsolidwhite;

    font-weight: 700;
    transition: 0.3s;
    filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.5));
    padding: 20px 15px;
    min-height: 61px;
  }

  .modal-img-block {
    @apply relative;

    width: 500px;
    height: 500px;
    max-width: 90vw;
    max-height: 60vh;

    img {
      object-fit: cover;
      object-position: center top;
    }
  }

  .modal-caption {
    @apply flex justify-end items-center text-14 text-defaultsolidwhite bg-bluewood;

    font-weight: 700;
    filter: drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.5));
    padding: 20px 15px;
  }

  .download-link {
    @apply flex justify-end items-center;

    fill: currentColor;
  }
}
