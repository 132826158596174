@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate';

.PersonCard {
  opacity: 0;
  position: relative;
  margin: 30px 0;
  background: #fff;
  padding: 0 20px 20px;
  box-shadow: 0 11px 35px 0 rgba(0, 0, 0, 0.15);
  max-height: 412px;

  @screen sm {
    min-height: 412px;
  }
  @screen md {
    min-height: 420px;
    max-height: 420px;
  }

  .member-image-holder {
    position: relative;
    top: -20px;
  }

  .img-holder {
    width: 82%;
    display: flex;
    max-height: 250px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
  }

  .member-details {
    margin-top: -5px;
    transition: height 1s;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0 20px 20px;
    background: #fff;
    display: block;

    .member-name {
      font-family: KAFD, Arial, sans-serif;
      font-weight: 300;
      font-size: 1.125rem;
      margin-bottom: 5px;
      line-height: 22px;
      letter-spacing: 1px;
      word-break: break-word;

      @screen lg {
        font-size: 1.5rem;
        margin-bottom: 0;
        line-height: 32px;
        letter-spacing: 2px;
      }
    }

    .member-title {
      font-weight: 500;
      font-size: 0.75rem;
      color: #a5b3ce;
      line-height: 16px;
      letter-spacing: 1px;
      word-break: break-word;

      @screen lg {
        font-size: 0.875rem;
        line-height: 20px;
      }
    }
  }
}
