@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.press-kit_box {
  .btn.btn__primary {
    background-color: #d60b5b;
  }

  width: 100%;
  max-width: 51.75rem;

  @apply flex;

  &:not(:last-child) {
    margin-bottom: 1rem;

    @screen lg {
      margin-bottom: 1.56rem;
    }
  }
}

.press-kit_col {
  &._col1 {
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
    padding: 0.75rem 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .press-kit_doc_heading {
      color: #657490;
      font-size: 0.625rem;
      line-height: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
      margin-bottom: 2px;
      @apply font-bold;
    }

    .press-kit_doc_desc {
      color: #28334a;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
      @apply font-bold;
    }
  }

  &._col2 {
    height: 3.75rem;
    width: 3.75rem;
    min-width: 3.75rem;
    padding: 0;

    .btn__download {
      width: 100%;
      height: 100%;
      border-color: transparent;
    }
  }
}

[dir='ltr'] {
  .press-kit_col {
    &._col1 {
      border-left: solid 5px;
      border-left-color: theme('colors.grey');
    }
  }
}

[dir='rtl'] {
  .press-kit_col {
    &._col1 {
      border-right: solid 5px;
      border-right-color: theme('colors.grey');
    }
  }
}

.kafd-press-kit-section {
  .btn {
    line-height: 1.3;
    letter-spacing: 2px;
    border: 1px solid rgba(40, 51, 74, 0.3);
    height: 40px;
    transition: 0.6s ease-in-out;
    background-color: #fff;
    font-size: 0.625rem;
    font-weight: 700;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    align-items: flex-end;
    padding: 12px 20px;
  }

  // &.brand-live {
  .btn.btn__primary {
    @apply bg-live;
    @include on-hover {
      &:hover,
      &:focus {
        @apply bg-yelloworange;
      }
    }
  }
  // }

  // &.brand-thrive {
  .btn.btn__primary {
    @apply bg-green;
    @include on-hover {
      &:hover,
      &:focus {
        @apply bg-curiousblue;
      }
    }
  }
  // }

  // &.brand-discover {
  .btn.btn__primary {
    @apply bg-violetflower;
    @include on-hover {
      &:hover,
      &:focus {
        @apply bg-pink;
      }
    }
  }
  // }

  // &.brand-lifestyle {
  .btn.btn__primary {
    @apply bg-red;
    @include on-hover {
      &:hover,
      &:focus {
        background-color: #ea9a42;
        border-color: #d2d7e1;
      }

      .icon__download._link {
        @apply text-vividviolet;
      }
    }
  }
  // }

  .btn__download {
    width: 93px;
    height: 93px;
    position: relative;
  }

  @media (hover: hover) and (pointer: fine) {
    .btn__download:focus .icon__download,
    .btn__download:hover .icon__download {
      opacity: 0;
    }

    .btn__download:focus .iconLink,
    .btn__download:hover .iconLink {
      opacity: 1;
    }
  }

  .icon__download {
    width: 25px;
    height: 24px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.65s ease-in-out 0.25s;
    color: #fff;
    fill: #fff;
    position: absolute;
    opacity: 1;
  }

  .icon__download._link {
    // opacity: 0;
    // fill: currentColor;
  }
}

.iconLink {
  opacity: 0;
  width: 25px;
  height: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.65s ease-in-out 0.25s;
  fill: #28334a;
  position: absolute;
}

[data-animation='on'] {
  .kafd-press-kit-section {
    .animate-section {
      &.press-kit_box {
        animation: text-ascend 0.65s ease forwards;
      }
    }
  }
}
