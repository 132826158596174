[data-animation='on'] {
  .events-listing-search {
    .news__img_holder {
      transition: 1s ease-in-out 1s;
    }

    .page__tabs {
      opacity: 0;
      transform: translate3d(0, 40px, 0);

      &.animate-section {
        animation: 0.75s motif-box-animation ease-in-out 0.5s both;
      }
    }
  }
}

.lozad,
.gallery__overlay {
  transform: scale(0.6);
  opacity: 0;
  transition:
    transform 0.65s ease-in-out 0.5s,
    opacity 0.65s ease-in-out 0.5s;

  &[data-loaded='true'] {
    transform: scale(1);
    animation: motif-box-animation 0.75s ease-in-out 1s both;
    opacity: 1;
  }
}

.lozad,
.gallery__overlay {
  transform: scale(0.6);
  opacity: 0;
  transition:
    transform 0.65s ease-in-out 0.5s,
    opacity 0.65s ease-in-out 0.5s;

  &[data-loaded='true'] {
    transform: scale(1);
    animation: motif-box-animation 0.75s ease-in-out 1s both;
    opacity: 1;
  }
}
