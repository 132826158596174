@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.carouselSectionlive {
  position: relative;

  @media (min-width: 768px) {
    min-height: 508px;
  }

  & :global(.slick-slider) {
    // overflow: hidden;
    position: relative;
    display: block;
  }

  & :global(.slick-track) {
    display: flex;
    flex-wrap: nowrap;
    padding-block-end: 10px;
  }

  & :global(.slick-list) {
    position: relative;
    display: block;
    overflow: hidden;

    @media (min-width: 768px) {
      padding-left: 0 !important;
    }
  }

  & :global(.slick-slide) div[class*='imageInnerBlock'] {
    visibility: hidden;
  }

  & :global(.slick-current) div[class*='imageInnerBlock'],
  & :global(.slick-current + .slick-slide) div[class*='imageInnerBlock'] {
    visibility: visible;
  }

  & :global(.slick-current) div[class*='imageInnerBlock'],
  & :global(.slick-current + .slick-slide) div[class*='imageInnerBlock'] {
    position: relative;
    overflow: hidden;
    transform: scale(1) translateX(0);
    transform-origin: left center;
    transition: all 0.5s ease-in-out;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: width 0.75s ease-in-out 0.5s;
      background-color: #fff;
    }
  }

  & :global(.slick-current) div[class*='imageCard'],
  & :global(.slick-current + .slick-slide) div[class*='imageCard'] {
    transition:
      width 1s ease-out 0.5s,
      opacity 0s ease 0.5s,
      margin 1s ease-in-out;
  }

  & :global(.slick-slide) {
    display: block;
    transition: all 0.3s ease-in-out;
  }

  & :global(.slick-slide.slick-active) {
    opacity: 1;
    transform: scale(1);
  }

  & :global(.slick-active + .slick-slide:not(.slick-active)) {
    opacity: 0.5;
    transform: scale(0.88);

    @media (min-width: 768px) {
      div[class*='imageInnerBlock'] {
        width: 385px !important;
        height: 190px !important;
        box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);
      }
    }

    div[class*='content'] {
      visibility: hidden;
    }
  }

  & :global(.slick-slide:not(.slick-active)) {
    opacity: 0;
  }

  & :global(.slick-disabled) {
    opacity: 0.5;
  }

  & :global(.slick-arrow) {
    display: none !important;
  }

  .decoration {
    // @media screen and (max-width: 1023px) {
    //   display: none;
    // }
    display: block;
    width: 62px;
    height: 62px;
    position: absolute;
    top: 485px;
    z-index: -1;
    fill: #28334a;

    @screen md {
      position: relative;
      top: 0;
    }

    @screen lg {
      width: 92px;
      height: 92px;
    }

    &:after {
      content: '';
      display: block;
      width: 62px;
      height: 62px;
      position: relative;
      bottom: 0;
      z-index: -1;
      background-color: #cbd5e0;

      @screen lg {
        width: 92px;
        height: 92px;
      }
    }
  }
}

:global(.livePage) {
  .carouselSectionlive {
    margin-bottom: 46px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.innerContainer {
  margin-left: -35px;
  margin-right: -35px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  top: 220px;
  transform: translateY(-50%);
  z-index: 9;
  right: 0;

  @media (min-width: 768px) {
    left: calc(74% - 160px);
    right: initial;
    top: 110px;
  }

  @media (min-width: 1024px) {
    left: calc(74% - 100px);
    right: initial;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(39.25deg, #d60b5b -2.37%, #f79525 103.75%);
    z-index: -1;
    opacity: 0.8;
  }

  &:hover:after {
    opacity: 1;
  }
}

:global(.globalPage-default) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(25deg, #28334a -5%, #28334a 106%);
  }
}

:global(.globalPage-envision) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(39deg, #562e60 -2%, #e21368 104%);
  }
}

:global(.globalPage-live) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(25deg, #d60b5b -5%, #f79525 106%);
  }
}

:global(.globalPage-accelerate) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(40deg, #00306b, #00a99d);
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  transition: all 0.1s ease-in-out;

  &:focus {
    outline: none;
  }

  svg path {
    fill: #fff;
  }
}

.iconTransformToLeft {
  transform: scale(-1);
}

.dotIcon {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
}

:global(.globalPage-default) .decoration {
  display: none;
}

:global(.globalPage-accelerate) .decoration {
  fill: #007dc0;

  &:after {
    background-color: #74c0b6;
  }
}

:global(.globalPage-accelerate) {
  :global(.kafd-section):has(:global(.explore-kafd)) ~ :global(.kafd-section) {
    .decoration {
      fill: #000;

      &:after {
        background-color: #cbd5e0;
      }
    }
  }
}

[dir='ltr'] {
  .innerContainer {
    @media (min-width: 768px) {
      margin-left: 0;
      margin-right: -93px;
    }
  }

  .carouselSectionlive {
    .decoration {
      left: 31px;

      @screen md {
        left: 0;
        transform: translate(-50%, -175%);
      }
      @screen lg {
        transform: translate(-50%, -125%);
      }

      &:after {
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}

[dir='rtl'] {
  .carouselSectionlive {
    & :global(.slick-list) {
      transform: rotateY(180deg);
    }

    .buttonsWrapper {
      @media (min-width: 768px) {
        right: calc(74% - 160px);
        left: initial;
      }

      @media (min-width: 1024px) {
        right: calc(74% - 100px);
        left: initial;
      }
    }

    .button {
      transform: rotate(180deg);
    }

    .decoration {
      right: 31px;

      @screen md {
        right: 0;
        transform: translate(50%, -175%);
      }
      @screen lg {
        transform: translate(50%, -125%);
      }

      &:after {
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  .innerContainer {
    @media (min-width: 768px) {
      margin-right: 0;
      margin-left: -93px;
    }
  }
}
