[data-animation='on'] {
  .GalleryModal {
    .modal-container {
      -webkit-animation-name: zoom;
      -webkit-animation-duration: 0.6s;
      animation-name: zoom;
      animation-duration: 0.6s;
    }
  }

  .modal-close:hover,
  .modal-close:focus {
    @apply no-underline cursor-pointer;
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-atransform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}
