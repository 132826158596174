/* keyframe animation functions */
// reveal-banner background
@keyframes reveal-banner {
  0% {
    transform: translate3d(-101%, 0, 0);
  }

  45% {
    transform: translate3d(0%, 0, 0);
  }

  50% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(101%, 0, 0);
  }
}

// reveal-banner reverse background
@keyframes reveal-banner-reverse {
  0% {
    transform: translate3d(101%, 0, 0);
  }

  45% {
    transform: translate3d(0%, 0, 0);
  }

  50% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-101%, 0, 0);
  }
}

// reveal-banner img
@keyframes reveal-banner-img {
  0% {
    transform: translate3d(-101%, 0, 0);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes reveal-banner-img-bw {
  0% {
    transform: translate3d(101%, 0, 0);
  }

  100% {
    transform: translate3d(0%, 0, 0);
  }
}

// Generic LTR animation rule for block-reveal (text, headings, images, videos etc.)
// Note: For RTL, use `reverse` property while adjusting the positioning for [dir='rtl']
@keyframes reveal-block-ltr {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(101%, 0, 0);
  }
}

// scale-banner img
@keyframes scale-banner-img {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

// banner-heading
@keyframes banner-heading {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

// motif-animation lower value
@keyframes motif-box-animation-low {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

// motif-animation
@keyframes motif-box-animation {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

// motif-animation higher value
@keyframes motif-box-animation-higher {
  0% {
    opacity: 0;
    transform: translate3d(0, 60px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

// motif-animation - reverse
@keyframes motif-box-animation-reverse {
  0% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -40px, 0);
  }
}

// motif-animation
@keyframes margin-top-animation {
  0% {
    opacity: 0;
    margin-top: 40px;
  }

  100% {
    opacity: 1;
    margin-top: 0;
  }
}

// motif slide up with opacity 100%
@keyframes motif-ascend {
  from {
    transform: translate3d(0, 65px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes motif-ascend-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes motif-ascend-right-opacity {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// motif slide down with opacity 100%
@keyframes motif-descend {
  0% {
    opacity: 0;
    transform: translate3d(0, -65px, 0);
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rotating-circle-180 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes motif-rotate-slideup {
  from {
    transform: rotate(0deg);
    margin-top: 100px;
    opacity: 0;
  }

  to {
    transform: rotate(-180deg);
    margin-top: 0;
    opacity: 1;
  }
}

@keyframes motif-rotate-slideup-svg {
  from {
    transform: rotate(0deg);
    bottom: -194px;
    opacity: 0;
  }

  to {
    transform: rotate(-180deg);
    bottom: -94px;
    opacity: 1;
  }
}

/* Two column list */
@keyframes gradient-ascend {
  from {
    transform: translate3d(0, 20px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes text-ascend {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(100px) rotate(-135deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
}

/* Carousel dots */
@keyframes progress-fill {
  from {
    background-position: right bottom;
  }

  to {
    background-position: left bottom;
  }
}

@keyframes color-change-to-white {
  to {
    color: theme('colors.defaultsolidwhite');
  }
}

/* Portrait carousel default animation 1 */
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}

/* Portrait carousel default animation 2 */
@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-100%);
  }
}

/* Portrait carousel - Slides intro */
@keyframes zoom-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// infographic slider animation
/* infoScaleDown */
@keyframes infoScaleDown {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0);
    opacity: 0;
  }
}
/* infoScaleUp */
@keyframes infoScaleUp {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}
/* infoTextAppear */
@keyframes infoTextAppear {
  from {
    transform: translate3d(60px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0px, 0, 0);
    opacity: 1;
  }
}
/* infoTextDisappear */
@keyframes infoTextDisappear {
  from {
    transform: translate3d(0px, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(60px, 0, 0);
    opacity: 0;
  }
}
// loader spinner
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes motif-ascend-opaque {
  0% {
    opacity: 0;
    transform: translate3d(0, 65px, 0);
  }

  10% {
    opacity: 1;
    transform: translate3d(0, 65px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes motif-ascend-opaque-long {
  0% {
    opacity: 0;
    transform: translate3d(0, 130px, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes motif-ascend-right-short {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes l-stats-expand {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes r-stats-expand {
  0% {
    opacity: 1;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes rotate-dash {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(-270deg) #{'/*rtl:scale(-0.5) rotate(-270deg)*/'};
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg) #{'/*rtl:scaleX(-1) scaleY(1) rotate(0deg)*/'};
  }
}

@keyframes reveal-banner-inview {
  0% {
    transform: translate3d(calc(0% - 1px), 0, 0);
  }

  100% {
    transform: translate3d(calc(100% + 1px), 0, 0);
  }
}

@keyframes reveal-banner-inview-rotate {
  0% {
    transform: translate3d(calc(0% - 1px), 0, 0);
  }

  100% {
    transform: translate3d(calc(-100% - 1px), 0, 0);
  }
}

@keyframes carousel-dots-ascend {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes carousel-arrows-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-400%);
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

// zoomIn with less scale (used in desktop navigation)
@keyframes zoom-in-light {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
// tiles-left
@keyframes tiles-left-medium {
  0% {
    transform: translate(0%, 0%);
  }

  100% {
    transform: translate(24.5%, 17.5%);
  }
}

// tiles-right
@keyframes tiles-right-medium {
  0% {
    transform: translate(0%, 0%);
  }

  100% {
    transform: translate(-24.5%, 17.5%);
  }
}

// tiles-left
@keyframes tiles-left-lg {
  0% {
    transform: translate(0%, 0%);
  }

  100% {
    transform: translate(-25%, 11.5%);
  }
}

// tiles-right
@keyframes tiles-right-lg {
  0% {
    transform: translate(0%, 0%);
  }

  100% {
    transform: translate(25%, 11.5%);
  }
}

// carouselImg1Next

@keyframes carouselImg1Next {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -101%, 0);
  }
}

// carouselImg1Prev

@keyframes carouselImg1Prev {
  0% {
    transform: translate3d(0, 101%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

// carouselImg2Next

@keyframes carouselImg2Next {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 101%, 0);
  }
}

// carouselImg2Prev

@keyframes carouselImg2Prev {
  0% {
    transform: translate3d(0, -101%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
// transformHorizontally
@keyframes transformHorizontally {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0%, 0, 0);
  }
}

@keyframes scale-card-banner-img {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scale-card-banner-shadow {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }

  100% {
    opacity: 0.2;
    transform: scale(1);
  }
}

@keyframes motif-cardbox-animation {
  0% {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0px, 0);
  }
}

// rotating-circle
@keyframes motif-cardbox-rotating-animation {
  0% {
    transform: rotate(-120deg);
    bottom: 0;
    opacity: 0;
  }

  100% {
    transform: rotate(00deg);
    bottom: 90px;
    opacity: 1;
  }
}

@keyframes motif-move-to-left {
  0% {
    right: 100%;
  }

  100% {
    right: 0%;
  }
}
@keyframes motif-move-to-left-2 {
  0% {
    right: 100%;
  }

  100% {
    right: 0;
  }
}

@keyframes reveal-image {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 101%, 0);
  }
}

@keyframes reveal-text {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(101%, 0, 0);
  }
}

@keyframes reveal-text-rtl {
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-101%, 0, 0);
  }
}
