@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.heading2 {
  font-size: 30px;
  line-height: 36px;

  @screen lg {
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
  }
}

[dir='ltr'] {
  :global(.accelerateSuccessFormPage) .heading2 {
    font-weight: 300;
    font-size: 1.875rem;
    letter-spacing: 0.063rem;
    line-height: 2.25rem;
    margin-bottom: 15px;
    @media (min-width: 1024px) {
      font-size: 40px;
    }
  }

  :global(.contactUsSuccessFormPage) .heading2 {
    font-weight: 300;
    font-size: 1.875rem;
    letter-spacing: 0.063rem;
    line-height: 2.25rem;
    margin-bottom: 15px;
    @media (min-width: 1024px) {
      font-size: 40px;
    }
  }

  :global(.bookEventSuccessFormPage) .heading2 {
    font-weight: 300;
    font-size: 1.875rem;
    letter-spacing: 0.063rem;
    line-height: 2.25rem;
    margin-bottom: 15px;
    @media (min-width: 1024px) {
      font-size: 40px;
    }
  }

  :global(.bookResUnitSuccessPage) .heading2 {
    font-weight: 300;
    font-size: 1.875rem;
    letter-spacing: 0.063rem;
    line-height: 2.25rem;
    margin-bottom: 15px;
    @media (min-width: 1024px) {
      font-size: 40px;
    }
  }

  :global(.bookLeasInquirySuccessFormPage) .heading2 {
    font-weight: 300;
    font-size: 1.875rem;
    letter-spacing: 0.063rem;
    line-height: 2.25rem;
    margin-bottom: 15px;
    @media (min-width: 1024px) {
      font-size: 40px;
    }
  }
}
