[data-animation='on'] {
  .VideoPlayer {
    .playerCoverWrapper {
      animation:
        carouselImg2Prev 1.25s ease-in-out 0.25s,
        fade-in 1.5s ease-in-out 0.25s;
    }

    &:hover .playerButton {
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }
  }
}
