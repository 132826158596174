@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animation';

.VideoPlayer {
  @apply w-full h-full relative;

  .video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: theme('colors.darkblue');
  }

  .top {
    opacity: 1;
    z-index: 99;
  }

  .closeIcon {
    fill: white;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;

    @supports (-webkit-touch-callout: none) {
      @media screen and (max-width: 430px) {
        top: -5px;
        right: 20px;
      }
    }
  }

  .playerCoverWrapper {
    @apply w-full h-full absolute inset-0;

    background: theme('colors.darkblue');

    .playerButton {
      fill: white;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
      z-index: 2;

      &.accentBgColor__purple {
        background: linear-gradient(39deg, #562e60 -2%, #e21368 103%);
      }

      &.accentBgColor__blue {
        background: linear-gradient(40deg, #00306b -1%, #00a99d 102%);
      }

      &.accentBgColor__red {
        transition: all ease-in-out 0.2s;
        background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
      }
    }
  }

  .hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
    opacity: 0;
  }
}

:global(.globalPage-default) .playerButton {
  background: #28334a;
}

:global(.globalPage-envision) .playerButton {
  background: linear-gradient(39deg, #562e60 -2%, #e21368 103%);
}

:global(.globalPage-accelerate) .playerButton {
  background: linear-gradient(40deg, #00306b -1%, #00a99d 102%);
}

:global(.globalPage-live) .playerButton {
  background: linear-gradient(39deg, #d60b5b -2%, #f79525 104%);
}
