@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.carouselWithControls {
  position: relative;
  margin-bottom: 6rem;

  & :global(.slick-track) {
    display: flex;
    flex-wrap: nowrap;
  }

  & :global(.slick-slide) {
    display: block;
    transition: all 0.3s ease-in-out;
  }

  & :global(.slick-disabled) {
    opacity: 0.5;
  }

  & :global(.slick-arrow) {
    display: none !important;
  }

  & :global(.slick-slide) {
    opacity: 0 !important;
  }

  & :global(.switched-slide) {
    opacity: 1 !important;
  }

  & :global(.slick-dots) {
    position: relative;
    display: flex !important;
    justify-content: space-between;
    max-width: 21.875rem;
    overflow: hidden;

    li {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: calc(33% - 6px);
        margin-left: 6px;
        top: 2px;
        height: 2.5px;
        z-index: -1;
        background: #f8cd56;
      }

      &:last-of-type:after {
        content: none;
      }
    }

    button {
      position: relative;
      text-indent: -9999px;
      width: 6px;
      min-width: 6px;
      height: 6px;
      outline: none;
      border-radius: 50%;
      border: none;
      background: #f8cd56;
    }

    & :global(.slick-active):after,
    & :global(.slick-active) ~ li:after {
      background: #d2d7e1;
    }

    & :global(.slick-active) ~ li {
      button {
        background: #657490;
      }
    }
  }
}

:global(.globalPage-live) {
  .carouselWithControls {
    & :global(.slick-dots) {
      max-width: 16.875rem;

      @media (min-width: 1024px) {
        max-width: 21.875rem;
      }
    }
  }
}

[dir='ltr'] {
  .carouselWithControls {
    :global .slick-dots {
      margin: 100px auto 0 0;

      @media (min-width: 1024px) {
        margin-top: 30px;
      }
    }
  }
}

[dir='rtl'] {
  .carouselWithControls {
    :global .slick-dots {
      margin: 100px 0 0 auto;

      @media (min-width: 1024px) {
        margin-top: 30px;
      }
    }
  }

  .carouselWithControls {
    :global .slick-dots {
      transform: rotate(-180deg);
    }
  }
}

.sectionItem {
  height: 440px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

[dir='ltr'] {
  .sectionItem {
    @media (min-width: 1024px) {
      padding-right: 7.75rem;
    }
  }
}

[dir='rtl'] {
  .sectionItem {
    @media (min-width: 1024px) {
      padding-right: 0;
      padding-left: 7.75rem;
    }
  }
}

.sectionItemDescWrapper {
  position: absolute;
  bottom: -4.875rem;
  z-index: 999;

  @media (min-width: 1024px) {
    bottom: 1.875rem;
  }

  @media (max-width: 420px) {
    width: 88%;
  }
}

[dir='ltr'] {
  .sectionItemDescWrapper {
    right: 0;
  }
}

[dir='rtl'] {
  .sectionItemDescWrapper {
    left: 0;
  }
}

.sectionItemDesc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(40, 51, 74, 0.9);
  color: #fff;
  padding: 1.25rem;
  min-height: 9.75rem;
  letter-spacing: 1px;
  width: 19.375rem;

  @media (min-width: 1024px) {
    min-height: 13.5rem;
    padding: 1.875rem;
    width: 21.65rem;
  }
}

[dir='ltr'] {
  .sectionItemDesc {
    align-items: start;
  }
}

[dir='rtl'] {
  .sectionItemDesc {
    align-items: end;
    @media (max-width: 420px) {
      width: 100%;
    }
  }
}

.contentLabel {
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: 0.75rem;
  }
}

[dir='rtl'] {
  .contentLabel {
    text-align: right;
  }
}

.contentTitle {
  font-size: 20px;
  line-height: 40px;
  font-weight: 300;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    font-size: 32px;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  top: calc(50% - 120px / 2);
  transform: translateY(-50%);
  z-index: 9999;

  @media (min-width: 1024px) {
    top: 20%;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(39.25deg, #d60b5b -2.37%, #f79525 103.75%);
    z-index: -1;
    opacity: 0.8;
  }

  &:hover:after {
    opacity: 1;
  }
}

[dir='ltr'] {
  .buttonsWrapper {
    right: -34px;

    @media (min-width: 1024px) {
      margin-right: 5.35rem;
      right: 0;
    }
  }
}

[dir='rtl'] {
  .buttonsWrapper {
    left: -34px;

    @media (min-width: 1024px) {
      margin-left: 5.35rem;
      left: 0;
    }
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  transition: all 0.1s ease-in-out;

  &:focus {
    outline: none;
  }

  svg path {
    fill: #fff;
  }
}

[dir='ltr'] {
  .iconTransformToLeft {
    transform: scale(-1);
  }

  .iconTransformToRight {
    transform: scale(1);
  }
}

[dir='rtl'] {
  .iconTransformToLeft {
    transform: scale(1);
  }

  .iconTransformToRight {
    transform: scale(-1);
  }
}

.dotIcon {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
}
