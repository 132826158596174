@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.NoCarousel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  opacity: 0;

  @screen xl {
    justify-content: center;
  }

  .listItem {
    flex-basis: 100%;
    margin: 16px;

    @screen sm {
      flex-basis: calc(100% / 2 - 32px);
    }

    @screen xl {
      flex-basis: calc(100% / 3 - 32px);
      max-width: 344px;
    }
  }

  .items {
    flex-basis: 100%;
    margin: 16px;

    @screen sm {
      flex-basis: calc(100% / 2 - 32px);
      max-width: none;
    }

    @media screen and (min-width: 1340px) {
      max-width: 344px;
    }
  }
}
