@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.kafd-hero-video {
  height: 100vh;
  position: relative;
  min-height: 660px;
  box-shadow: 0 20px 30px 0 rgba(8, 24, 43, 0.25);

  &:before {
    @apply absolute inset-0 w-full h-full;

    content: '';
    z-index: 2;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.5) 51.46%, rgba(0, 0, 0, 0.6) 100%);
  }

  &.kafd-hero-image {
    @media screen and (max-width: 425px) {
      min-height: 300px;
    }
  }

  .slide-item {
    @apply absolute inset-0 w-full h-full;
  }

  .videowrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }

  .primary-video {
    @apply h-full relative;

    .vjs-fluid {
      @apply h-full;
    }

    .vjs-poster {
      background-size: cover;
    }

    .vjs-tech {
      @apply h-full object-cover pointer-events-none;
    }

    .vjs-control-bar {
      @apply hidden;
    }

    .vjs-big-play-button {
      @apply invisible;
    }

    .vjs-loading-spinner {
      &:after,
      &:before {
        left: 0;
      }
    }
  }

  .secondary-video {
    .vjs-big-play-button {
      display: none !important;
    }

    @media screen and (min-width: 1920px) {
      .vjs-poster {
        background-size: cover;
      }
    }
  }

  .hero-center {
    @apply absolute w-full h-full top-0 left-0 flex justify-center items-center m-auto z-10;
  }

  .hero-intro {
    @apply text-defaultsolidwhite text-center px-8;

    font-weight: 300;
    max-width: 800px;
  }

  .intro-text {
    h1 {
      font-size: 36px;
      line-height: 38px;

      @screen lg {
        font-size: 56px;
        line-height: 64px;
        letter-spacing: 4px;
      }
    }

    h2 {
      font-size: 30px;
      line-height: 36px;

      @screen lg {
        font-size: 50px;
        line-height: 50px;
        letter-spacing: 4px;
      }
    }

    h3 {
      font-size: 24px;
      line-height: 28px;

      @screen lg {
        font-size: 40px;
        line-height: 40px;
      }
    }

    h4 {
      font-size: 18px;
      line-height: 22px;

      @screen lg {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 2px;
      }
    }

    h5 {
      font-size: 16px;
      line-height: 18px;

      @screen lg {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 1.5px;
      }
    }

    p,
    span {
      display: block;
      font-size: 18px;
      line-height: 22px;

      @screen lg {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 2px;
      }
    }
  }

  .awards {
    @apply absolute text-defaultsolidwhite bottom-0 w-full z-10;

    padding: 0 15px;
  }

  .awards-container {
    @apply w-full mx-auto flex flex-col justify-between;

    max-width: 1000px;
    padding: 15px 10px 25px;
    background-color: rgba(34, 44, 64, 0.5);
    backdrop-filter: blur(2px);

    @screen lg {
      @apply flex-row;

      padding: 20px;
    }

    @media (max-width: 1024px) {
      .brTitleAwards {
        display: none;
      }
    }
  }

  .awards-title {
    @apply text-16 font-light text-center mb-5;

    line-height: 18px;
    padding: 0 10px 0 5px;
    letter-spacing: 1px;
    white-space: nowrap;

    @screen lg {
      @apply text-18 text-left mb-0 pl-0 pr-3;

      letter-spacing: 1.5px;
      line-height: 22px;
    }
  }

  .awards-list {
    @apply flex w-full justify-around;
  }

  .awards-item {
    @apply flex flex-col font-bold items-center w-full text-8 text-center;

    max-width: 104px;
    line-height: 12px;
    letter-spacing: 0.8px;
    @screen lg {
      @apply flex-row text-10 text-left;

      letter-spacing: 1px;
      max-width: 100%;
      margin-right: 50px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .awards-logo {
    width: 35px !important;
    height: 35px !important;
    min-width: 35px;
    margin-bottom: 5px;

    @screen lg {
      width: 40px;
      height: 40px;
      min-width: 40px;
      margin-bottom: 0;
      margin-right: 15px;
    }
  }

  .banner-image-holder {
    @apply relative;
  }
}

:global(.globalPage-live) {
  .banner-image-holder {
    img {
      @media (max-width: 766px) {
        object-position: 0 !important;
      }
    }
  }
}

[dir='rtl'] {
  .kafd-hero-video {
    .intro-text {
      h1 {
        @screen lg {
          letter-spacing: 0;
        }
      }

      h2 {
        @screen lg {
          letter-spacing: 0;
        }
      }

      h4 {
        @screen lg {
          letter-spacing: 0;
        }
      }

      h5 {
        @screen lg {
          letter-spacing: 0;
        }
      }

      p,
      span {
        @screen lg {
          letter-spacing: 0;
        }
      }
    }

    .awards-item {
      @media (min-width: 1024px) {
        text-align: right;
      }

      letter-spacing: 0;
      @media (min-width: 1024px) {
        &:last-of-type {
          margin-right: 50px;
        }
      }
    }

    .awards-title {
      text-align: right;
      letter-spacing: 0;

      @media (max-width: 1024px) {
        text-align: center;
      }
    }

    .awards-logo {
      @media (min-width: 1024px) {
        margin-left: 15px;
        margin-right: 0px;
      }
    }
  }
}

[dir='rtl'] :global(.adminHero) .kafd-hero-video,
:global(.adminHero) .kafd-hero-video {
  height: auto;
}
