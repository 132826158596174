@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.kafd-file-popup {
  color: #fff;
  display: none;

  &.active {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050;

    .file-popup-content {
      position: relative;
      background-color: #28334a;
      width: 100%;
      display: flex;
      flex-direction: column;
      max-width: 700px;
      padding: 48px 40px 40px 34px;

      @media (min-width: 1024px) {
        max-width: 700px;
        padding: 34px 96px 42px 40px;
      }

      .close-btn {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        margin-inline-start: auto;
        padding: 0.25rem;
        top: 20px;
        right: 20px;
        background: none;
        border: none;
        cursor: pointer;

        @media (min-width: 1024px) {
          top: 33px;
          right: 30px;
        }

        .close-icon {
          fill: currentColor;
          width: 18px;
          height: 18px;
        }
      }

      .file-remove-msg,
      .popup-title {
        font-size: 1.25rem;
        margin-top: 0;
        margin-bottom: 90px;
        line-height: 1.25;
      }

      .file-btn-group {
        display: flex;
        justify-content: flex-end;

        .btn-primary {
          margin-inline-start: 1rem;

          span {
            background-color: #d2d7e1;
            color: #28334a;
          }
        }

        .btn-reject {
          span {
            background-color: #28334a;
            color: #d2d7e1;
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }
}

.btn__primary-1 {
  font-family: KAFD, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.625rem;
  color: #fff;
  display: inline-block;
  cursor: pointer;

  @media (min-width: 1024px) {
    font-size: 1rem !important;
  }

  .text {
    transform-origin: left center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #28334a;
    transition: letter-spacing 0.75s cubic-bezier(0.16, 1, 0.3, 1);
    align-items: flex-end;
    padding: 12px 24px 10px;
    min-height: 32px;
    white-space: nowrap;
    line-height: 1;

    @media (min-width: 1024px) {
      min-height: 40px;
    }

    @media (min-width: 1280px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .btn__primary-1:focus-visible span.text,
  .btn__primary-1:hover span.text {
    letter-spacing: 2px !important;

    @media (min-width: 1024px) {
      letter-spacing: 3px !important;
    }
  }
}

[dir='rtl'] {
  .kafd-file-popup {
    &.active {
      .file-popup-content {
        padding: 34px 40px 42px 96px;

        .close-btn {
          right: unset;
          left: 20px;

          @media (min-width: 1024px) {
            left: 30px;
          }
        }
      }
    }
  }
}
