@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import '@styles/core/_variables';
@import '@styles/core/_mixins';

.accordionWrapper {
  margin-bottom: 6.25rem;
  font-family: 'KAFD', Arial, sans-serif;
  color: #28334a;
  max-width: 51.75rem;
  position: relative;
}

.decor {
  width: 139.5px;
  height: 139.5px;
  top: -2.75rem;
  z-index: 3;
  position: absolute;

  @media (min-width: 1024px) {
    width: 162px;
    height: 162px;
  }

  @media (min-width: 1280px) {
    width: 184px;
    height: 184px;
    top: 0;
  }
}

[dir='ltr'] {
  .decor {
    left: calc(100% - 4.35rem);

    @media (min-width: 1280px) {
      left: auto;
      right: calc(100% + 2.625rem);
    }
  }
}

[dir='rtl'] {
  .decor {
    right: calc(100% - 4.35rem);
    transform: rotate(90deg);

    @media (min-width: 1280px) {
      right: auto;
      left: calc(100% + 2.625rem);
    }
  }
}

.accordionWrapperTitle {
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 1px;
  font-weight: 300;
  margin-bottom: 1.875rem;

  @media (min-width: 1024px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 2.5rem;
  }
}

.accordionInnerWrapper {
  margin-bottom: 2.5rem;
}

.buttonWrapper {
  position: relative;
  z-index: 1;
  text-transform: uppercase;

  button span {
    text-transform: uppercase;
  }

  @media (max-width: 1024px) {
    display: flex;
    justify-content: flex-end;
  }
}
