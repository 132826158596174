@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.HeroFormSuccessWrapper {
  @media (min-width: 1024px) {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.kafd-formpage-style--default {
  margin-top: calc(88px + 4rem);
  padding-right: $pageMargin-md;
  padding-left: $pageMargin-md;

  @screen lg {
    @apply mt-24 mx-auto;

    padding-left: $left-nav-offset-lg;
    padding-right: $right-nav-offset-lg;
  }

  .kafd-form-container {
    @apply flex flex-wrap;

    &.disable-forms {
      .form-nav-wrapper {
        .help-text {
          @apply block visible;
        }
      }

      .kafd-form-wrapper {
        pointer-events: none;
        @apply relative;

        &:after {
          @apply absolute inset-0;

          content: '';
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #fff 100%);
          pointer-events: none;
          z-index: 50;
        }
      }
    }
  }

  .kafd-form-footer,
  .thankyou-msg {
    @apply flex flex-wrap relative w-full;
    @screen lg {
      @apply relative;
    }

    .infog-box-3 {
      a {
        color: theme('colors.bluewood') !important;
      }
    }

    .help-right {
      a {
        color: theme('colors.bluewood') !important;
      }
    }
  }
}

[dir='rtl'] {
  .kafd-formpage-style--default {
    @media (max-width: 1024px) {
      padding-right: 0;
    }
  }
}

.helpine-wrap {
  padding-top: 31px;
}
@media (min-width: 1024px) {
  .helpine-wrap {
    padding-top: 46px;
  }
}

.helpine-wrap .help-patt-svg {
  width: 62px;
  height: 62px;
}
@media (min-width: 1024px) {
  .helpine-wrap .help-patt-svg {
    width: 92px;
    height: 92px;
  }
}

:global(.globalPage-default) .help-patt-svg {
  fill: #070707;
}

:global(.globalPage-envision) .help-patt-svg {
  fill: #83338c;
}

:global(.globalPage-accelerate) .help-patt-svg {
  fill: #00a79e;
}

:global(.globalPage-live) .help-patt-svg {
  fill: #c52d5c;
}

.helpine-wrap .help-left {
  padding: 40px 34px 40px 20px;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .helpine-wrap .help-left {
    max-width: 500px;
  }
}
@media (min-width: 1024px) {
  .helpine-wrap .help-left {
    padding: 55px 94px;
  }
}

.helpine-wrap .help-left .title {
  font-size: 1.25rem;
  font-weight: 700;
  font-family: KAFD, Arial, sans-serif;
  color: #fff;
  line-height: 1;
  z-index: 1;
  position: relative;
  letter-spacing: 0;
}
@media (min-width: 1024px) {
  .helpine-wrap .help-left .title {
    font-size: 2rem;
  }
}
@media (min-width: 1280px) {
  .helpine-wrap .help-left .title {
    font-size: 2.25rem;
  }
}

.helpine-wrap .help-left .thumb-image {
  transform: translate(-50%, -50%);
  position: absolute;
  height: 100%;
  opacity: 0.15;
  width: 100%;
  left: 50%;
  top: 50%;
}

.helpine-wrap .help-right {
  padding-top: 17px;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: KAFD, Arial, sans-serif;
  line-height: 1.33;
}

[dir='ltr'] {
  .help-right-wrap {
    margin-left: auto;
  }
}

[dir='rtl'] {
  .help-right-wrap {
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .helpine-wrap .help-right {
    font-size: 1.688rem;
    padding-top: 104px;
  }
}

.helpine-wrap .help-right a {
  display: inline-block;
  position: relative;
}

.helpine-wrap .help-right a:after {
  left: 0;
  bottom: 0;
  content: '';
  position: absolute;
  height: 2px;
  background: #28334a;
  width: 100%;
  backface-visibility: hidden;
  transform: scaleX(0);
  transform-origin: 100% 50%;
  transition: transform 0.5s ease;
}

[data-animation='on'] {
  .kafd-formpage-style--default {
    .kafd-section-title,
    .kafd-section-desc {
      &.reveal-animate:after {
        content: '';
        @apply absolute inset-0 w-full h-full bg-defaultsolidwhite;

        transition: transform 1s ease-in-out 0.5s;
      }
    }

    &.animate-section {
      .kafd-section-title,
      .kafd-section-desc {
        &.reveal-animate:after {
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }
}

[dir='ltr'] {
  .helpine-wrap .help-patt {
    top: 0;
    right: -5px;
  }

  @media (min-width: 1024px) {
    .helpine-wrap .help-patt {
      right: -61px;
    }
  }

  .helpine-wrap .help-patt {
    @screen lg {
      right: -41px;
    }
  }

  .helpine-wrap .help-left {
    margin-left: -35px;
  }

  @media (min-width: 1024px) {
    .helpine-wrap .help-left {
      margin-left: -52px;
      margin-right: 64px;
    }
  }

  :global(.globalPage-default) .help-left {
    background-image: linear-gradient(69deg, #28334a 3%, #28334a);
  }

  :global(.globalPage-live) .help-left {
    background-image: linear-gradient(69deg, #d60b5b 3%, #f79525);
  }

  :global(.globalPage-accelerate) .help-left {
    background-image: linear-gradient(70deg, #00306b 2%, #41bdb6 98%);
  }

  :global(.globalPage-envision) .help-left {
    background-image: linear-gradient(39deg, #562e60 -2%, #e21368 104%);
  }
}

[dir='rtl'] {
  .helpine-wrap .help-patt {
    top: 0;
    left: -5px;
  }

  @media (min-width: 1024px) {
    .helpine-wrap .help-patt {
      left: -61px;
    }
  }

  .helpine-wrap .help-patt {
    @screen lg {
      left: -41px;
    }
  }

  .helpine-wrap .help-left {
    margin-left: 0;
  }

  @media (min-width: 1024px) {
    .helpine-wrap .help-left {
      margin-left: 64px;
    }
  }

  :global(.globalPage-default) .help-left {
    background-image: linear-gradient(69deg, #28334a, #28334a 3%);
  }

  :global(.globalPage-live) .help-left {
    background-image: linear-gradient(-69deg, #d60b5b 3%, #f79525);
  }

  :global(.globalPage-accelerate) .help-left {
    background-image: linear-gradient(-70deg, #00306b 2%, #41bdb6 98%);
  }

  :global(.globalPage-envision) .help-left {
    background-image: linear-gradient(-39deg, #e21368 104%, #562e60 -2%);
  }
}
