@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.kafd-file-popup {
  color: #fff;
  display: none;

  &.active {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }

  .file-popup-content {
    position: relative;
    background-color: #28334a;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 700px; // calc(100% - 60px);
    padding: 48px 40px 40px 34px;

    .close-btn {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      margin-inline-start: auto;
      padding: 0.25rem;
      top: 20px;
      right: 20px;
      background: none;
      border: none;
      cursor: pointer;

      .close-icon {
        fill: currentColor;
        width: 18px;
        height: 18px;
      }
    }

    .popup-content-in {
      .popup-msg {
        font-family: KAFD, Arial, sans-serif;
        font-size: 1rem;
        margin-bottom: 2.5rem;
        line-height: 1.56;
        letter-spacing: 1px;
        max-width: unset;
      }

      .popup-title {
        font-weight: 700;
        margin-bottom: 1.25rem;
        font-size: 1.125rem;
        max-width: unset;
      }
    }
  }

  .hidden {
    display: none;
  }
}

[dir='rtl'] {
  .kafd-file-popup {
    .file-popup-content {
      padding: 48px 34px 40px 40px;

      .close-btn {
        right: unset;
        left: 20px;
      }
    }
  }
}
