@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.carouselSection {
  position: relative;
  margin-bottom: 2.5rem;

  & :global(.slick-slider) {
    // overflow: hidden;
  }

  & :global(.slick-track) {
    display: flex;
    flex-wrap: nowrap;
  }

  & :global(.slick-list) {
    margin: 0 -15px;
    // padding-left: 0 !important;
  }

  & :global(.slick-slide) {
    display: block;
    margin: 0 15px;
    transition: all 0.3s ease-in-out;
  }

  & :global(.slick-slide.slick-active) {
    opacity: 1;
    transform: scale(1);
  }

  & :global(.slick-active + .slick-slide:not(.slick-active)) {
    opacity: 0.5;
    transform: scale(0.88);

    @media (min-width: 1440px) {
      transform: translateX(0);
    }
  }

  & :global(.slick-slide:not(.slick-active)) {
    opacity: 0;
  }

  & :global(.slick-disabled) {
    opacity: 0.5;
  }

  & :global(.slick-arrow) {
    display: none !important;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(40.66deg, #00306b -0.97%, #00a99d 101.68%);
    z-index: -1;
    opacity: 0.8;
  }

  &:hover:after {
    opacity: 1;
  }
}

:global(.globalPage-default) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(25deg, #28334a -5%, #28334a 106%);
  }
}

:global(.globalPage-envision) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(39deg, #562e60 -2%, #e21368 104%);
  }
}

:global(.globalPage-live) .buttonsWrapper {
  &:after {
    background-image: linear-gradient(25deg, #d60b5b -5%, #f79525 106%);
  }
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  transition: all 0.1s ease-in-out;

  &:focus {
    outline: none;
  }

  path {
    fill: #fff;
  }
}

.iconTransformToLeft {
  transform: scale(-1);
}

.dotIcon {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #fff;
}

.MotifPiano {
  display: none;
  position: relative;
  width: 92px;
  height: 92px;
  z-index: -1;
  margin-bottom: -46px;
}

:global(.globalPage-default) .MotifPiano {
  @media screen and (min-width: 1024px) {
    display: block;
    fill: #070707;
  }
}

:global(.globalPage-envision) .MotifPiano {
  @media screen and (min-width: 1024px) {
    display: block;
    fill: #83338c;
  }
}

:global(.globalPage-accelerate) .MotifPiano {
  @media screen and (min-width: 1024px) {
    display: block;
    fill: #00a79e;
  }
}

:global(.globalPage-accelerate):has(:global(.accelerate-office)) .MotifPiano {
  display: none;
}

:global(.globalPage-accelerate):has(:global(.accelerate-retail)) .MotifPiano {
  fill: #28334a;
}

:global(.globalPage-live) .MotifPiano {
  @media screen and (min-width: 1024px) {
    display: block;
    fill: #c52d5c;
  }
}

[dir='ltr'] {
  .carouselSection {
    .buttonsWrapper {
      right: -34px;
    }

    .MotifPiano {
      transform: translate(-50%, -50%);
    }
  }

  :global(.aboutPage) {
    @screen md {
      .carouselSection:not(:has(div[class*='Carousel_buttonsWrapper'])) {
        & :global(.slick-list) {
          display: flex;
          justify-content: center;
        }
      }
    }

    .MotifPiano {
      display: none;
    }
  }
}

[dir='rtl'] {
  .carouselSection {
    & :global(.slick-list) {
      transform: rotate(180deg);
    }

    & :global(.slick-slide):global(.slick-active) {
      transform: scale(1) rotate(180deg) !important;
    }

    & :global(.slick-slide) {
      transform: scale(0.88) rotate(180deg) !important;
    }

    .buttonsWrapper {
      left: -34px;
    }

    .button {
      transform: rotate(180deg);
    }

    .MotifPiano {
      transform: translate(50%, -50%);
    }
  }

  :global(.aboutPage) {
    .carouselSection:not(:has(div[class*='Carousel_buttonsWrapper'])) {
      & :global(.slick-list) {
        // transform: rotate(0deg);
        display: flex;
        justify-content: flex-end;
      }

      // & :global(.slick-slide) {
      //   transform: rotate(0deg) !important;
      // }
    }

    .MotifPiano {
      display: none;
    }
  }
}

:global(.retailersPage) {
  .carouselSection {
    margin-bottom: 5rem;
  }
}
