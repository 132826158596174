@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.searchWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9999;
  color: #fff;
  height: 100%;
  overflow: scroll;
  background: #28334a;

  & :global(.kafd-container) {
    margin-top: 0;
    padding-top: calc(88px + 4rem);
    padding-bottom: 0;
  }

  .container {
    margin-bottom: 0;
  }

  & :global(.kafd-container + .kafd-container) {
    margin-top: 0;
    padding-top: 0;
  }

  button:focus,
  input:focus {
    outline: none;
  }
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.headerWrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 100%;
  z-index: 1;

  @media (max-width: 1023px) {
    border-bottom: 1px solid rgba(210, 215, 225, 0.6);
    backdrop-filter: blur(5px);
    height: 90px;
    align-items: flex-end;
    padding-bottom: 20px;
  }
}

.logoBig {
  width: 100px;
  height: 195px;
  margin-top: 26px;
  margin-left: 26px;
  margin-right: 26px;
  fill: #fff;

  @media (max-width: 1023px) {
    display: none;
    visibility: hidden;
  }
}

.logoSmall {
  width: 100px;
  height: 24px;
  fill: #fff;
  margin-left: 35px;
  margin-right: 35px;
  @media (min-width: 1024px) {
    display: none;
    visibility: hidden;
  }
}

.closeButton {
  letter-spacing: 2px;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  padding: 7px 10px 6px 15px;
  height: 30px;
  transition: 0.2s ease-in-out;
  font-size: 0.625rem;
  font-weight: 700;
  display: inline-flex;
  line-height: 1rem;
  margin-right: 35px;

  @media (min-width: 1024px) {
    margin-top: 22px;
    margin-inline-end: 20px;
    padding: 11px 15px 11px 20px;
    height: 40px;
  }

  &:hover {
    background: #fff;
    color: #28334a;

    svg {
      fill: #28334a;
    }
  }

  svg {
    margin-inline-start: 1.25rem;
    fill: #fff;
  }
}

[dir='rtl'] {
  .closeButton {
    margin-right: 0;
    margin-left: 35px;
  }
}

.headerTitleWrapper {
  width: 100%;
  max-width: 710px;
  margin: 0 auto 2.5rem;
  text-align: center;
  position: relative;
  z-index: 1;
}

.headerTitle {
  font-size: 2rem;
  line-height: 40px;
  font-weight: 300;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
    line-height: 28px;
    letter-spacing: 1px;
  }
}

.headerText {
  margin-top: 0.25rem;
  letter-spacing: 0.5px;
  font-weight: 300;
  color: #a0a8bb;
  line-height: 1.375;
}

.inputField {
  height: 93px;
  padding: 42px 100px 33px 15px;
  box-shadow: 0 1px 0 0 rgba(127, 141, 171, 0.35);
  width: 100%;
  font-size: 1.125rem;
  color: #28334a;
  outline-offset: -2px;
  border: 1px solid #fff;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -khtml-border-radius: 0;

  &:focus {
    &::placeholder {
      opacity: 0.5;
    }
  }

  &::placeholder {
    letter-spacing: 1px;
    transition: 0.2s ease-out;
    padding-left: 0.6875rem;
    height: 16px;
    color: #a1a9bb;
    font-weight: 500;
    font-style: italic;
    font-size: 0.625rem;
    position: relative;
  }
}

.form {
  position: relative;
  padding-bottom: 2.75rem;
  @media (min-width: 1024px) {
    padding-bottom: 6.25rem;
  }
}

.button {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #d2d7e1;
  width: 93px;
  height: 93px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d2d7e1;
  appearance: none;
  transition: all 0.3s ease;

  &[disabled] {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    background: #28334a;
    border: 1px solid #fff;

    svg path {
      fill: #fff;
    }
  }
}

.label {
  top: 0;
  left: 15px;
  cursor: text;
  transition: 0.2s ease-out;
  transform: translateY(14px);
  letter-spacing: 2px;
  color: #a0a8bb;
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 1.5;
  position: absolute;
}

.link {
  position: relative;
  display: inline-block;
  font-size: 1.125rem;
  line-height: 26px;
  letter-spacing: 1.5px;

  &:hover:after {
    transform: scale(1);
    transform-origin: 0 50%;
  }

  &:after {
    left: 0;
    bottom: 0;
    content: '';
    position: absolute;
    height: 2px;
    background: #fff;
    width: 100%;
    backface-visibility: hidden;
    transform: scaleX(0);
    transform-origin: 100% 50%;
    transition: transform 0.5s ease;
  }
}

.bgDark {
  background: #28334a;
}

.bgWhite {
  background-color: #fff;
}

.autocompleteItem {
  min-height: 93px;
  border-left: 7px solid #d2d7e1;
  box-shadow: 0 -1px 0 0 #e8ebf0;
  margin-top: 1px;
  color: #a0a8bb;
  font-weight: 700;
  line-height: 1.5;
  background: #fff;

  &:hover {
    border-left: 7px solid #28334a;
    color: #28334a;
  }

  > a {
    display: block;
    padding: 1rem;
  }
}

.highlightTitleMatch {
  color: #28334a;
}

.autocompleteTitle {
  text-overflow: ellipsis;
  font-size: 1.125rem;
  margin-top: 0.75rem;
  overflow: hidden;
  white-space: nowrap;
}

.autocompleteCategory {
  font-size: 0.625rem;
}

.bottomBox {
  min-height: 93px;
  padding: 1.125rem 1.375rem;
  background-color: #d2d7e1;
  display: flex;
  align-items: center;
  color: #28334a;

  a {
    display: inline-block;
    overflow: visible;
    border-bottom: 2px solid #28334a;
    position: relative;
    min-width: 215px;
    width: auto;
    margin-left: 0;

    span {
      font-size: 0.875rem;
      font-weight: 500;
      text-align: left;
      line-height: 1.215;
      padding: 8px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      display: block;
    }
  }
}

[dir='rtl'] {
  .inputField {
    padding: 42px 15px 33px 100px;
  }

  .button {
    left: 0;
    right: unset;
  }

  .label {
    left: unset;
    right: 15px;
  }

  .bottomBox {
    a {
      margin-right: 0;

      span {
        text-align: right;
      }
    }
  }
}
