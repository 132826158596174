@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animation';

.news__section {
  margin-top: 3.75rem;
  @apply flex items-start relative;
  @include medium-devices {
    @apply flex-wrap;
  }
}

.news__content {
  @apply flex-shrink pl-5 mt-8;
  @screen lg {
    width: 47%;
    @apply px-8 mt-24;
  }
  @include medium-devices {
    width: 80.25%;
  }
  @media (min-width: 375px) and (max-width: 767px) {
    width: 83.25%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 91.25%;
  }

  .envi__link {
    display: inline-block;
    width: auto;
    min-width: 160px;

    @screen lg {
      min-width: 215px;
    }
  }
}

.news__img {
  position: relative;
  overflow: hidden;

  @apply relative flex-shrink-0 w-full;
  @screen lg {
    width: 43.85%;
  }
  @include medium-devices {
    order: -1;
    @apply mb-2;
  }
  @media (min-width: 1024px) {
    min-height: 212px;
  }
  @media (min-width: 1280px) {
    min-height: 279px;
  }
  @media (min-width: 1366px) {
    min-height: 302px;
  }
  @media (min-width: 1440px) {
    min-height: 321px;
  }

  &.no-img {
    @include medium-devices {
      @apply hidden;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 32;
  }
}

.news__img_bg {
  transition: 0.5s ease-in-out 0.1s;
  width: 85.75%;
  height: 203px;
  min-height: 70%;
  max-height: 80%;
  z-index: -1;
  @apply absolute  top-0 bg-grey;
  @screen lg {
    width: 74.75%;
    height: 270px;
  }
  @include medium-devices {
    top: auto;
    bottom: 0;
  }
}

[dir='ltr'] {
  .news__img_bg {
    @apply right-0;
    @include medium-devices {
      right: -2.188rem;
    }
  }
}

[dir='rtl'] {
  .news__img_bg {
    @apply left-0;
    @include medium-devices {
      left: -2.188rem;
    }
  }
}

.news__img_holder {
  .img {
    @apply w-full;

    padding-block-start: 32px;
    padding-inline-end: 32px;
  }

  @include medium-devices {
    margin-block-end: 1.875rem;
  }
}

.news__keyword {
  font-size: 0;
  @apply text-greyfont font-medium;

  > li {
    display: inline;
    @apply text-12;
    @screen lg {
      @apply text-14;
    }

    + li {
      &:before {
        content: '.';
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        @apply inline-block;
        @screen lg {
          margin-left: 0.3rem;
          margin-right: 0.3rem;
        }
      }
    }
  }
}

.news__title {
  @apply text-21 leading-none font-bold font-kafd mt-2;
  @screen lg {
    margin-left: -1px;
    @apply text-27;
  }
}

.news__desc {
  line-height: 1.77;
  @apply text-13 mt-2;
}

.news__link {
  @apply mt-4;
  @media (min-width: 360px) and (max-width: 1023px) {
    min-width: 170px;
  }
}

.news__load_more_btn {
  @apply mt-10;
  @screen lg {
    margin-left: 9.35%;
  }
}

.news__date {
  z-index: 1000;
  width: 72px;
  height: 107px;

  @apply bg-bluewood text-center text-greyfont flex justify-center flex-col flex-shrink-0 leading-none;
  @screen lg {
    @apply mt-16;
  }
  @media (min-width: 1366px) {
    margin-left: 1.5rem;
  }
  @media (min-width: 1440px) {
    margin-left: 1.85rem;
  }

  @include medium-devices {
    margin-left: -1.438rem;
  }

  ._year {
    min-height: 10px;
    letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
    @apply font-bold leading-none text-10 mt-3;
  }

  ._month {
    @apply font-bold font-kafd text-18 text-defaultsolidwhite mt-1;
  }

  ._day {
    @apply font-bold font-kafd text-40 text-defaultsolidwhite;
  }
}
