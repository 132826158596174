@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
@import './animate.scss';

.kafd-container {
  padding-right: $pageMargin-md;
  min-height: auto;
  padding-left: $pageMargin-md;
  margin-top: calc(88px + 4rem);

  @screen lg {
    @apply mt-24 mx-auto;
    @apply pb-24;

    padding-left: $left-nav-offset-lg;
    padding-right: $right-nav-offset-lg;
    min-height: 75vh;
    max-width: $max-screen-width-lg;
  }
  /* .full-width:first-of-type {
		margin-top: calc((88px + 4rem) * -1);
		@screen lg {
			@apply -mt-24;
		}
	} */
}

.kafd-section {
  @apply mb-10;

  @screen lg {
    @apply mb-24;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.residence-listing {
  .residence-listing__list {
    display: flex;
    flex-direction: column;

    .residence-listing__item {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.75rem;
      @screen md {
        margin-bottom: 6.25rem;
        flex-direction: row;
      }

      //left-sided
      &-left {
        flex-direction: column-reverse;
        @screen md {
          flex-direction: row;
        }

        .residence-listing__media {
          order: 1;
          @screen md {
            margin-inline-start: 1.875rem;
          }
        }
      }

      //right-sided
      &-right {
        .residence-listing__media {
          order: -1;
          @screen md {
            margin-inline-end: 1.875rem;
          }
        }

        .residence-listing__main {
          .residence-listing__content {
            @screen md {
              align-items: flex-end;
            }
          }
        }
      }

      // residence-listing__media
      .residence-listing__media {
        width: 100%;
        z-index: 2;
        min-height: 13.75rem;
        height: 13.75rem;
        position: relative;
        overflow: hidden;
        margin-bottom: 1.875rem;
        @screen md {
          display: block;
          min-height: 440px;
          height: 440px;
          margin-bottom: 0;
        }

        .img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          pointer-events: none;
          background: theme('colors.darkblue');
          transform: translate3d(-101%, 0, 0);
        }
      }

      // residence-listing__main
      .residence-listing__main {
        width: 100%;
        @screen md {
          width: 21.25rem;
          min-width: 21.25rem;
        }

        .residence-listing__picture {
          min-height: 13.75rem;
          height: 13.75rem;
          display: none;
          position: relative;
          overflow: hidden;
          @screen md {
            min-height: 20rem;
            height: 20rem;
            display: block;
          }

          &:not(:last-child) {
            margin-bottom: 1.875rem;
          }

          .img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background: theme('colors.darkblue');
            transform: translate3d(-101%, 0, 0);
          }
        }

        .residence-listing__content {
          display: flex;
          flex-direction: column;
        }

        .residence-listing__title {
          &:not(:last-child) {
            margin-bottom: 0.25rem;
          }

          .title__text {
            font-size: 1.875rem;
            line-height: 2.25rem;
            text-transform: uppercase;
            letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
            @apply font-kafd font-light;
            @screen md {
              font-size: 42px;
              line-height: 50px;
              letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
            }
          }
        }

        .residence-listing__action {
          width: 13.43rem;
          max-width: 100%;
        }
      }
    }
  }
}

[dir='rtl'] {
  .residence-listing {
    .residence-listing__action {
      transform: rotateY(180deg);

      span {
        transform: rotateY(-180deg);
        text-align: start;
      }
    }
  }
}

// Button with line - Starts here
.button-style-2 {
  text-decoration: none;
  @apply text-bluewood;
  @include backface-hidden;
  @apply mx-auto relative block w-full overflow-visible;

  border-bottom: 2px solid theme('colors.bluewood');
  padding-right: 26px;

  &:focus,
  &:active {
    outline: none;
  }

  .cta-text {
    @apply text-14 font-medium text-left;

    line-height: 1.215;
    padding: 8px 0;
    @include textoverflow-clip;
  }

  .cta-icon {
    @include vertical-center;

    right: 0;
    width: 16px;
    height: 16px;
    @apply text-bluewood fill-current;
  }

  &:after {
    content: '';
    @apply absolute left-0 w-full opacity-0;

    top: 100%;
    height: 2px;
    background: theme('colors.bluewood');
  }

  &.dark-theme {
    border-bottom: 2px solid theme('colors.defaultsolidwhite');

    .cta-text,
    .cta-icon.icon {
      @apply text-defaultsolidwhite fill-current;
    }

    &:after {
      background: theme('colors.defaultsolidwhite');
    }
  }
}
