@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.kafd-banner {
  padding-top: 0;
  @screen xl {
    @apply px-30px;
  }

  &.js-dark-banner-center {
    &.negative-margin {
      margin-top: -9.5rem;
      @screen lg {
        margin-top: -6.5rem;
      }
    }
  }
}

.kafd-banner-carousel {
  position: relative;
  margin-inline-start: 0;
  margin-inline-end: 0;
  @screen lg {
    margin-inline-start: -122px;
    margin-inline-end: -93px;
  }

  @screen xl {
    width: 93.8%;
    max-width: 1093px;
    margin-inline-start: auto;
    margin-inline-end: 0;
  }

  .slide-item {
    &:before,
    &:after {
      content: '';
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      z-index: 1;
      transition-delay: 1s;
      margin-inline-start: -35px;
      margin-inline-end: -35px;
      transition: all ease 0.35s;
      @screen lg {
        margin-inline-start: 0;
        margin-inline-end: 0;
      }
    }

    &:after {
      background: linear-gradient(225deg, rgba(12, 17, 33, 0) 0%, #0c1121 100%);
      opacity: 0.3;
    }
  }

  .banner-image-holder {
    box-shadow: 0 15px 30px 0 rgba(8, 24, 43, 0.25);
    transition-duration: 1s;
    transition-delay: 1s;
    transition-property: box-shadow;
    margin-inline-end: -35px;
    margin-inline-start: -35px;
    @screen md {
      min-height: 594px;
    }
    @screen lg {
      margin-inline-end: 0;
      margin-inline-start: 0;
      min-height: 300px;
    }
    @screen xl {
      min-height: 384px;
    }
    @media (min-width: 1366px) {
      min-height: 443px;
    }
    @media (min-width: 1440px) {
      min-height: 443px;
    }

    &:before,
    &:after {
      content: '';
      background: linear-gradient(to right, theme('colors.landingdark') 0%, rgba(255, 255, 255, 0) 100%);
      opacity: 0.8;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }

    &:before {
      z-index: 1;
    }

    &:after {
      background: theme('colors.darkblue');
      opacity: 1;
      transform: translate3d(-100%, 0, 0);
    }

    .image-wrapper-relative {
      position: relative;
      height: 100%;

      @media (min-width: 768px) and (max-width: 1024px) {
        img {
          height: 594px;
        }
      }
    }

    div[class*='text-reveal-animation']:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
      transition: 1s ease-in-out 0.25s;
    }

    .img {
      width: 100%;
      min-height: 350px;
      @screen lg {
        min-height: 490px;
      }
    }

    video {
      object-fit: cover;
    }

    .vid {
      padding-top: 81.5%;
      max-height: 485px;
      @screen lg {
        padding-top: 45.5%;
      }
    }
  }

  [data-content]:before {
    content: attr(data-content);
  }

  .back-link {
    @apply inline-flex justify-center items-center text-defaultsolidwhite text-14 font-kafd font-bold mb-5;

    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    transition: all ease-in-out 0.3s;

    .icon {
      @apply mr-3;

      height: 15px;

      .svgicon {
        path {
          @apply fill-current text-defaultsolidwhite;
        }
      }
    }

    &:hover {
      letter-spacing: 3px#{'/*rtl:' $rtl-space '*/'};
    }
  }

  .banner-inner-content {
    @apply absolute z-10 bottom-0;

    left: 0;
    margin-inline-start: 34px;
    margin-bottom: 30px;
    @screen lg {
      margin-inline-start: 122px;
      margin-bottom: 65px;
    }
    @screen xl {
      margin-inline-start: 65px;
      margin-bottom: 55px;
    }
  }

  .banner-heading {
    @apply font-kafd font-light leading-none text-36 text-defaultsolidwhite pointer-events-none;

    letter-spacing: 1px#{'/*rtl:' $rtl-space '*/'};
    line-height: 38px;
    max-width: 341px;
    @screen lg {
      max-width: 587px;
      font-size: 56px;
      line-height: 64px#{'/*rtl:' 66px '*/'};
      letter-spacing: 4px#{'/*rtl:' $rtl-space '*/'};
    }
    @media (max-width: 360px) {
      font-size: 10vw;
    }

    span {
      &:before {
        position: absolute;
        max-width: 100px;
        height: 106%;
        overflow: hidden;
        color: theme('colors.bluewood');
        white-space: nowrap;
        top: 0;
        left: 0;
      }
    }
  }

  .banner-col-left {
    margin-top: 43px;
    display: none;
    @screen lg {
      display: block;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .banner-desc {
    margin-top: 43px;
    @screen lg {
      margin-top: 60px;
    }

    @screen xl {
      margin-inline-start: 8%;
    }

    [data-animation='on'] & {
      opacity: 0;
    }
  }
}

.motif {
  &-rect {
    width: 100%;
    max-width: 64px;
    height: 64px;
    @apply pointer-events-none bg-lightgray;

    [data-animation='on'] & {
      opacity: 0;
    }

    @screen lg {
      left: -64px;
    }
    @screen xl {
      left: -93px;
      max-width: 93px;
      height: 97px;
    }
  }

  &-img {
    left: -32px;
    top: -32px;
    max-width: 64px;
    height: 64px;
    @apply text-bluewood fill-current;
    @screen lg {
      left: auto;
      right: -32px;
    }
    @screen xl {
      left: auto;
      right: -47px;
      top: -49px;
      max-width: 92px;
      height: 92px;
    }
  }
}

.banner-placeholder {
  background-repeat: no-repeat;
  background-position: center;
  min-height: 280px;
  @apply bg-lightgray;

  @screen md {
    min-height: 450px;
  }
  @screen lg {
    min-height: 600px;
  }
}

[data-loaded='true'] ~ .banner-placeholder {
  display: none;
}

// brand styles
.brand-live {
  .kafd-banner-carousel {
    .motif-rect {
      @apply bg-yelloworange;
    }

    .motif-img {
      @apply fill-current text-red;
    }
  }
}

.brand-discover {
  .kafd-banner-carousel {
    .motif-rect {
      @apply bg-pink;
    }

    .motif-img {
      @apply fill-current text-violetflower;
    }
  }
}

.brand-thrive {
  .kafd-banner-carousel {
    .motif-rect {
      @apply bg-greenyblue;
    }

    .motif-img {
      @apply fill-current text-green;
    }
  }
}

.brand-lifestyle {
  .kafd-banner-carousel {
    .motif-rect {
      @apply bg-yellow;
    }

    .motif-img {
      @apply fill-current text-red;
    }
  }
}

[dir='rtl'] {
  @screen xl {
    .kafd-banner {
      padding-right: 0;
    }
  }

  .banner-inner-content {
    left: unset;
    right: 0;
  }

  // .kafd-banner-carousel .banner-image-holder {
  //   transform: translate3d(100%, 0, 0);
  // }
  .kafd-banner-carousel {
    @screen xl {
      margin-inline-start: 0;
    }

    .banner-image-holder {
      &:before,
      &:after {
        background: linear-gradient(to left, theme('colors.landingdark') 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}
