[data-anim='on'] {
  .SimpleCardsLink {
    .lozad {
      opacity: 0;
      transition:
        transform 0.65s ease-in-out 0.5s,
        opacity 0.65s ease-in-out 0.5s;

      &[data-loaded='true'] {
        animation: motif-box-animation 0.75s ease-in-out 1s both;
        opacity: 1;
      }
    }

    .tabbed-carousel-item-image {
      animation: 1s showDropShadow ease-in-out 2.8s both;

      &:after {
        animation: 1.5s reveal-banner ease-in-out 0.87s both;
      }

      .img {
        animation: 1s reveal-banner-img ease-in-out 1.3s both;
      }
    }

    .tabbed-carousel-item-row:hover {
      .tabbed-carousel-item-content-col > .accentColor__purple {
        transition: all ease-in-out 0.2s;
        color: #b13a8d;
      }

      .tabbed-carousel-item-content-col > .accentColor__blue {
        transition: all ease-in-out 0.2s;
        color: #00a79e;
      }

      .tabbed-carousel-item-content-col > .accentColor__red {
        transition: all ease-in-out 0.2s;
        color: #ea9a42;
      }
    }

    .tabbed-carousel-item-row {
      .tabbed-carousel-item-content-col ~ .tabbed-carousel-item-content-col {
        & .tabbed-carousel-item-content-arrow {
          transition: all ease-in-out 0.2s;
        }
      }
    }

    .decor:after {
      transition: all ease-in-out 0.2s;
    }

    div[class*='VideoPlayer'] + .decor:has(+ .tabbed-carousel-item-row:hover) {
      &:after {
        background-color: #28334a;
        opacity: 0.6;
      }
    }
  }
}

[dir='ltr'] {
  .SimpleCardsLink {
    .tabbed-carousel-item-row:hover {
      .tabbed-carousel-item-content-col ~ .tabbed-carousel-item-content-col {
        & .tabbed-carousel-item-content-arrow {
          left: 0.25rem;
        }
      }
    }
  }
}

[dir='rtl'] {
  .SimpleCardsLink {
    .tabbed-carousel-item-image {
      &:after {
        animation: 1.5s reveal-banner-reverse ease-in-out 0.87s both;
      }

      .img {
        animation: 1s reveal-banner-img-bw ease-in-out 1.3s both;
      }
    }

    .tabbed-carousel-item-row:hover {
      .tabbed-carousel-item-content-col ~ .tabbed-carousel-item-content-col {
        & .tabbed-carousel-item-content-arrow {
          right: 0.25rem;
        }
      }
    }
  }
}
