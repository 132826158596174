@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.heading5 {
  font-size: 16px;
  line-height: 18px;

  @screen lg {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 1.5px#{'/*rtl:' $rtl-space '*/'};
  }
}
