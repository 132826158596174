@import "src/styles/core/_variables.scss"; @import "src/styles/core/_mixins.scss";
        @import "src/styles/core/_keyframes-rule.scss"; @import "src/styles/core/_fontFamily.scss"; @import "src/episerver/forms-react/Form.scss";
.buttonSubmitWrapper {
  margin: 40px auto 83px;
}

.feedbackMessage {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
  font-size: 1.1em;
  
  &.success {
    background-color: #e8f5e9;
    color: #2e7d32;
    border: 1px solid #a5d6a7;
  }
  
  &.error {
    background-color: #fbe9e7;
    color: #c62828;
    border: 1px solid #ffab91;
  }
}
